import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[ngModel][ftRestrictInput]',
})
export class RestrictInputDirective implements OnChanges {

    value: any;

    constructor(private elem: ElementRef, private ngModel: NgModel) {
        ngModel.update.subscribe(val => {
            if (this.INPUT_REGEX.test(val)) {
                this.value = val;
            } else {
                ngModel.control.setValue(this.value);
            }
        });
    }

    INPUT_REGEX: RegExp;

    @Input()
    ftRestrictInput: string;
    ngOnChanges(changes: SimpleChanges): void {
        this.INPUT_REGEX = new RegExp(this.ftRestrictInput);
    }
}
