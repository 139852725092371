/**
 * Created by roman.kupin on 1/29/2018.
 */



import * as TouchpointContainerComponent from "./touchpointContainer.component";
import { TouchpointContainerService } from "./touchpointContainer.service";
import * as TouchpointTriggerDirective from "./touchpointTrigger.directive";

declare let angular;
export const TOUCHPOINT_CONTAINER_MODULE = angular.module("AccountApp.v2.Components.TouchpointContainer", []);

export class ComponentConfig {

    static $inject = ["$compileProvider", "$provide"];

    constructor($compileProvider, $provide) {

        $compileProvider.directive(TouchpointContainerComponent.NAME, TouchpointContainerComponent.DEFINITION);
        $compileProvider.directive(TouchpointTriggerDirective.NAME, TouchpointTriggerDirective.DEFINITION);

        $provide.service(TouchpointContainerService.Name, TouchpointContainerService);
    }
}

TOUCHPOINT_CONTAINER_MODULE.config(ComponentConfig);