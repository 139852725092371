import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { filter, take, tap } from 'rxjs/operators';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

export interface ClosedDateRange {
    uuid: string;
    startDate: NgbDate | string;
    endDate: NgbDate | string;
    isRecurring: boolean;
}


@Component({
    selector: 'ft-closed-date-ranges-editor',
    templateUrl: './closed-date-ranges-editor.component.html',
    styleUrls: ['./closed-date-ranges-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ClosedDateRangesEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ClosedDateRangesEditorComponent
        }
    ]
})
export class ClosedDateRangesEditorComponent extends ModalEditorBase implements OnInit {
    @Output() rangeRemoved = new EventEmitter<string>();
    @Output() save = new EventEmitter<{ [key: string]: ClosedDateRange[] }>();

    dateRangeDefaultValue: ClosedDateRange = {
        uuid: null,
        startDate: new NgbDate(null, null, null),
        endDate: new NgbDate(null, null, null),
        isRecurring: false
    };

    ngOnInit() {
        this.value$
            .pipe(
                filter(value => !!value || !value?.length),
                tap(value => this.updateInitialValue()),
                take(1)
            )
            .subscribe();
    }

    isDisabled(date: NgbDate) {
        return !!date;
    }

    // By request (FTK-1959) this is handled by "not saving invalid ranges" rather than marking as invalid
    // isInvalid(): boolean {
    //     return this.value?.some((range: ClosedDateRange) => !(range.startDate as NgbDate)?.year || !(range.endDate as NgbDate)?.year);
    // }

    removeRange(index: number) {
        this.rangeRemoved.emit(this.value.splice(index, 1)?.uuid);
    }

    addRange() {
        if (!Array.isArray(this.value)) {
            this.value = [];
        }
        this.value.push({
            ...this.dateRangeDefaultValue
        });
    }

    applyChanges() {
        if (this.isNgModelExist) {
            this.onChangeFn(this.mapOutputValue(this.value));
        }
        this.save.next(this.mapOutputValue(this.outputValue?.[this.name]) as any);
    }

    mapOutputValue(ranges: ClosedDateRange[]): ClosedDateRange[] {
        return ranges?.filter((range: ClosedDateRange) => (range.startDate as NgbDate)?.year && (range.endDate as NgbDate)?.year)
        .map((range: ClosedDateRange) => {
            const startDate = range.startDate as NgbDate;
            const endDate = range.endDate as NgbDate;
            return {
                ...range,
                startDate: new Date(startDate.year, startDate.month - 1, startDate.day).toDateString(),
                endDate: new Date(endDate.year, endDate.month - 1, endDate.day).toDateString()
            };
        });
    }

    mapValue(value: ClosedDateRange[]): any {
        return value?.map(v => {
            const startDate = dayjs(v.startDate as string);
            const endDate = dayjs(v.endDate as string);
            return {
                ...v,
                startDate: new NgbDate(startDate.year(), startDate.month() + 1, startDate.date()),
                endDate: new NgbDate(endDate.year(), endDate.month() + 1, endDate.date())
            };
        });
    }

    onCancelChanges() {
        this.value = this.mapOutputValue(this.initialValue);
        if (this.isNgModelExist) {
            this.onChangeFn(this.mapOutputValue(this.initialValue));
        }
    }


    onStartDateChange(change: NgbDate, i: number) {
        const endDate = this.value?.[i]?.endDate
        if (!endDate?.day && !endDate?.month && !endDate?.year) {
            this.value[i].endDate = change;
        }
    }
}


