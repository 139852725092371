import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
    ConfirmationExperienceIntegrationSettings,
    ConfirmationExperienceSettings,
    ConfirmationExperienceStylingSettings
} from '../../models/confirmation-experience';
import { JourneysApiService } from './../../services/journeys-api.service';
import {
    changeConfirmationExperience,
    changeConfirmationExperienceFail,
    changeConfirmationExperienceSuccess,
    commitConfirmationExperience,
    commitConfirmationExperienceFail,
    commitConfirmationExperienceSuccess,
    loadConfirmationExperience,
    loadConfirmationExperienceFail,
    loadConfirmationExperienceSuccess,
    loadIntegrationConfirmationExperience,
    loadIntegrationConfirmationExperienceFail,
    loadIntegrationConfirmationExperienceSuccess,
    loadStylingConfirmationExperience,
    loadStylingConfirmationExperienceFail,
    loadStylingConfirmationExperienceSuccess
} from './actions';

@Injectable()
export class ConfirmationExperienceEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadConfirmationExperience),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getTouchpointConfirmationData(componentGroupUuid, propertyId)
                    .pipe(
                        map((settings: ConfirmationExperienceSettings) => {
                            return loadConfirmationExperienceSuccess({data: settings});
                        }),
                        catchError(error => of(loadConfirmationExperienceFail({error})))
                    ))
        )
    );

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingConfirmationExperience),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getStylingConfirmationData(componentGroupUuid, propertyId)
                    .pipe(
                        map((stylingSettings: ConfirmationExperienceStylingSettings) => {
                            return loadStylingConfirmationExperienceSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingConfirmationExperienceFail({error})))
                    ))
        )
    );

    loadIntegration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadIntegrationConfirmationExperience),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getIntegrationConfirmationData(componentGroupUuid, propertyId)
                    .pipe(
                        map((integrationSettings: ConfirmationExperienceIntegrationSettings) => {
                            return loadIntegrationConfirmationExperienceSuccess({data: integrationSettings});
                        }),
                        catchError(error => of(loadIntegrationConfirmationExperienceFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitConfirmationExperience),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointConfirmationSettings(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: ConfirmationExperienceSettings) => {
                            return commitConfirmationExperienceSuccess({data: settings});
                        }),
                        catchError(error => of(commitConfirmationExperienceFail({error})))
                    ))
        )
    );

    changeConfirmationExperience$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeConfirmationExperience),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, changes, tab }]) => this.journeysApiService.changeTouchpointConfirmationData(
                    componentGroupUuid, propertyId, changes, tab
                ).pipe(map(() => {
                    return changeConfirmationExperienceSuccess({ changes });
                }),
                    catchError(error => {
                        return of(changeConfirmationExperienceFail(error));
                    })
                )
            )
        )
    );
}
