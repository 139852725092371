import { Component, Input } from '@angular/core';
import { AuthError } from "../../models/auth-error";

@Component({
    selector: 'ft-change-password-errors',
    templateUrl: './change-password-errors.component.html',
    styleUrls: ['./change-password-errors.component.scss']
})
export class ChangePasswordErrorsComponent {
    @Input() error: AuthError;

    constructor() {
    }
}
