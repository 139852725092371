import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export class PlatformState {
    propertyUUID: string;
    hasStories: number;
    hasAudience: number;
    hasDiscoveryIntegration: boolean;
}


@Injectable({ providedIn: 'root' })
export class PlatformStateService {

    state: PlatformState;

    constructor(
        private httpClient: HttpClient
    ) { }

    load(propertyUuid: string): Promise<PlatformState> {
        return this.httpClient.get<PlatformState>(`/api/v2/${propertyUuid}/properties/${propertyUuid}/platform-state`)
            .toPromise();
    }
}