import { Component, forwardRef, Input, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { Language } from 'account-hybrid/common/models/language.model';
import { EditorField, FieldValidationDefinition, sortDefaultAccountLanguages } from 'apps/account/src/account-shared';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';


@Component({
    selector: 'ft-multiple-input-editor',
    templateUrl: './multiple-input-editor.component.html',
    styleUrls: ['./multiple-input-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MultipleInputEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: MultipleInputEditorComponent
        }
    ]
})
export class MultipleInputEditorComponent extends ModalEditorBase implements OnInit {
    _validationDefinition: FieldValidationDefinition[];
    @ViewChildren('input', { read: NgModel }) inputs: QueryList<NgModel>;
    @Input() customContent: TemplateRef<any>;
    @Input() languageVariationsView = false;
    @Input() languages: Language[] = [];

    @Input() set validationDefinition(fieldsDefinition: FieldValidationDefinition[]) {
        this._validationDefinition = fieldsDefinition
            .map(definition => {
                if (definition.type === 'url') {
                    definition.patternValidationText = 'Value is not valid url.';
                    definition.pattern = 'https?://.+';
                }
                return definition;
            }) || [];
    };

    set value(value: any) {
        super.value = value;
    }

    get value() {
        return sortDefaultAccountLanguages(super.value);
    }

    get validationDefinition(): FieldValidationDefinition[] {
        return this._validationDefinition;
    }

    @Input() fields: EditorField[];


    constructor() {
        super();
    }

    ngOnInit() {
        this.updateInitialValue();
    }

    onModelChange(value: any, i?: number, fieldName?: string) {
        if (this.isNgModelExist && this.isArrayValue()) {
            this.value[i][fieldName] = value;
        } else {
            this.value = value;
        }
    }

    isInvalid(): boolean {
        return this.inputs.some(input => input.invalid) || false;
    }

    isArrayValue() {
        return Array.isArray(this.value);
    }

    trackByFn(item, index) {
        return index;
    }

    isValueRemovable(i: number) {
        return this.value?.find((_, index) => i === index)?.languageCode !== 'en';
    }
}


