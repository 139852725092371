import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'ft-touchpoint-header',
    templateUrl: './touchpoint-header.component.html'
})
export class TouchpointHeaderComponent {
    @Input() isActive = false;
    @Input() canActivate = true;
    @Input() activeToggleEnabled = true;
    @Input() name: string;
    @Output() activeToggled = new EventEmitter<boolean>();


    constructor() {
    }
}

