import { Action, createReducer, on } from "@ngrx/store";
import { loadPoststayProcess, loadPoststayProcessFail, loadPoststayProcessSuccess } from './actions';
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null
    } as State,
    on(loadPoststayProcess, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadPoststayProcessSuccess,  (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    on(loadPoststayProcessFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // commit
    // on(commitBannerEmail, (state) => {
    //     return {
    //         ...state,
    //         loading: true
    //     };
    // }),
    // on(commitBannerEmailSuccess, (state, {data}) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         settings: data
    //     };
    // }),
    // styling
    // on(loadStylingBannerEmail, (state) => {
    //     return {
    //         ...state,
    //         loading: true
    //     };
    // }),
    // on(loadStylingBannerEmailSuccess, (state, {data}) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         stylingSettings: data
    //     };
    // }),
    // on(loadStylingBannerEmailFail, (state, {error}) => {
    //     return {
    //         ...state,
    //         loading: false,
    //         error
    //     };
    // }),
);

export function poststayProcessReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
