import { Component, forwardRef, Input, QueryList, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as insane from 'insane';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { EditorField } from '../../../models/editor-field';
import { EditorToken } from '../../../models/editor-token';
import { SANITIZE_OPTIONS } from '../constants/sanitize-options.const';
import { TextHtmlEditorComponent } from '../text-html-editor/text-html-editor.component';


@Component({
    selector: 'ft-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => HtmlEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: HtmlEditorComponent
        }
    ]
})
export class HtmlEditorComponent extends ModalEditorBase {
    @ViewChildren('editor') editors: QueryList<TextHtmlEditorComponent>;
    @Input() fields: EditorField[];
    @Input() tokens: EditorToken[];
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() email: boolean;
    @Input() pattern: string;
    @Input() plain = false;
    @Input() trimClipboardFormat = false;
    isArray = false;

    constructor() {
        super();
    }

    isArrayValue() {
        return Array.isArray(this.value);
    }

    isInvalid(): boolean {
        return this.editors?.some(editor =>
            editor.inputs?.some(input => input?.invalid)
        ) || false;
    }

    applyChanges() {
        if (Array.isArray(this.value)) {
            this.value?.map(value => {
                if (this.fields?.length) {
                    this.fields.forEach(field => value[field.name] = insane(this.modifyLinksToNewWindow(value[field.name]), SANITIZE_OPTIONS));
                    return value;
                }
                return insane(value, SANITIZE_OPTIONS);
            });
        } else {
            this.value = insane(this.modifyLinksToNewWindow(this.value), SANITIZE_OPTIONS);
        }
        super.applyChanges();
    }

    modifyLinksToNewWindow(html: string) {
        if (html) {
            const dom = document.createElement('div');
            dom.innerHTML = html;
            const links = dom.querySelectorAll('a');
            links?.forEach(link => link.setAttribute('target', '_blank'));
            const modifiedHtml = dom.innerHTML;
            dom.remove();
            return modifiedHtml;
        }
        return '';
    }
}
