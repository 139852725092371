import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PhotoDtoModel } from 'account-hybrid/common/models/photo-dto.model';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShowcaseStoriesService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    getRandomPhotos(companyUuid: string, count = 5): Observable<PhotoDtoModel[]> {
        return this.http.get<PhotoDtoModel[]>(`${this.environment.apiBaseUrl}/${companyUuid}/photos/random?count=${count}`);
    }
}
