import { createAction, props } from '@ngrx/store';
import { CertificateEmailSettings } from './../../models/certificate-email';

export const loadCertificateEmail = createAction('[Certificate Email] load',
props<{
    componentGroupUuid: string
}>());

export const loadCertificateEmailSuccess = createAction('[Certificate Email] loadSuccess', props<{ data: CertificateEmailSettings[] }>());
export const loadCertificateEmailFail = createAction('[Certificate Email] loadFail', props<{ error: any }>());

// snapshot save changes
export const changeCertificateEmail = createAction('[Certificate Email] Change Certificate Email',
  props<{
    componentGroupUuid: string,
    changes: Partial<CertificateEmailSettings>,
}>());
export const changeCertificateEmailSuccess = createAction('[Certificate Email] changeSuccess',
props<{ changes: any }>());
export const changeCertificateEmailFail = createAction('[Certificate Email] changeFail',
props<{ error: any }>());
