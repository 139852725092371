import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';

import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { Language } from 'account-hybrid/common/models/language.model';
import { EditorField, FieldValidationDefinition } from 'apps/account/src/account-shared';
import { sortDefaultAccountLanguages } from '../../../../../utils/account-langauge.util';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { EditorToken } from '../../../models/editor-token';


@Component({
    selector: 'ft-multiple-textarea-editor',
    templateUrl: './multiple-textarea-editor.component.html',
    styleUrls: ['./multiple-textarea-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MultipleTextareaEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: MultipleTextareaEditorComponent
        }
    ]
})
export class MultipleTextareaEditorComponent extends ModalEditorBase implements OnInit {
    _validationDefinition: FieldValidationDefinition[];
    @ViewChildren('textareaElement', { read: ElementRef }) textareaElements: QueryList<ElementRef>;
    @ViewChildren('control', { read: NgModel }) inputs: QueryList<NgModel>;
    @Input() fields: EditorField[];
    @Input() languageVariationsView = false;
    @Input() languages: Language[] = [];

    @Input() set validationDefinition(fieldsDefinition: FieldValidationDefinition[]) {
        this._validationDefinition = fieldsDefinition
            .map(definition => {
                if (definition.type === 'url') {
                    definition.patternValidationText = 'Value is not valid url.';
                    definition.pattern = 'https?://.+';
                }
                return definition;
            }) || [];
    };

    set value(value: any) {
        super.value = value;
    }

    get value() {
        return sortDefaultAccountLanguages(super.value);
    }

    get validationDefinition(): FieldValidationDefinition[] {
        return this._validationDefinition;
    }

    @Input() tokens: EditorToken[] = [];
    @Input() secondButton: string = null;
    @Output() action = new EventEmitter<any>();
    selectedToken: EditorToken = null;


    constructor() {
        super();
    }

    ngOnInit() {
        this.updateInitialValue();
    }

    onTokenSelected(token, index, fieldName: string) {
        const selectionStart = this.textareaElements.get(index)?.nativeElement.selectionStart;
        this.value[index][fieldName] = this.value[index][fieldName] ? this.value[index][fieldName].slice(0, selectionStart) + `${token}` + this.value[index][fieldName].slice(selectionStart) : token;

        setTimeout(() => {
            this.selectedToken = null;
            this.textareaElements.get(index)?.nativeElement.focus();
        });
    }

    onModelChange(value: any, i?: number, fieldName?: string) {
        if (this.isNgModelExist && this.isArrayValue()) {
            this.value[i][fieldName] = value;
        } else {
            this.value = value;
        }
    }

    isArrayValue() {
        return Array.isArray(this.value);
    }

    isInvalid(): boolean {
        return this.inputs.some(input => input.invalid) || false;
    }

    trackByFn(item, index) {
        return index;
    }

    isValueRemovable(i: number) {
        return this.value?.find((_, index) => i === index)?.languageCode !== 'en';
    }
}


