import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromNotifications from "../../../notifications/store";
import * as fromAppMenu from "../../store";


@Component({
    selector: 'ft-app-menu-entry',
    templateUrl: './app-menu-entry.component.html',
    styleUrls: ['./app-menu-entry.component.scss']
})
export class AppMenuEntryComponent implements OnInit, OnDestroy {
    $latestBadge: Observable<string>;
    @Input() darkText = false;

    constructor(private notificationsStore: Store<fromNotifications.NotificationsState>,
                private appMenuStore: Store<fromAppMenu.AppMenuState>
    ) {
    }

    ngOnInit() {
        this.notificationsStore.dispatch(fromNotifications.loadLatestBadge());
        this.$latestBadge = this.notificationsStore.select(fromNotifications.getLatestBadge);
    }

    ngOnDestroy() {
        this.appMenuStore.dispatch(fromAppMenu.hideAppMenu());
    }

    showAppMenu(event) {
        this.appMenuStore.dispatch(fromAppMenu.showAppMenu());
        event.stopPropagation();
    }

}
