import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from "rxjs/operators";
import { hideAppMenu, showAppMenu } from "./app-menu.actions";


@Injectable()
export class AppMenuEffects {
    private renderer: Renderer2;

    showAppMenu$ = createEffect(() =>
            this.actions$.pipe(
                ofType(showAppMenu),
                tap(() => {
                    this.renderer.addClass(this.document.body, 'no-scroll');
                })
            ),
        {dispatch: false}
    );


    hideAppMenu$ = createEffect(() =>
            this.actions$.pipe(
                ofType(hideAppMenu),
                tap(() => {
                    this.renderer.removeClass(this.document.body, 'no-scroll');
                })
            ),
        {dispatch: false}
    );

    constructor(private actions$: Actions,
                @Inject(DOCUMENT) private document: Document,
                rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }
}




