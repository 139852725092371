import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from "../../../classes/modal-editor-base";

@Component({
    selector: 'ft-exclude-editor',
    templateUrl: './exclude-editor.component.html',
    styleUrls: ['./exclude-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ExcludeEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ExcludeEditorComponent
        }
    ]
})
export class ExcludeEditorComponent extends ModalEditorBase implements OnInit {
    @Input() placeholder = 'Search for keyword';
    @Input() bindFlag: string;
    @Input() bindLabel: string;
    @Input() bindSubLabel: string;
    @Input() bindCount: string;
    @Input() inverse = false;
    @Input() leftLabel: string;
    @Input() rightLabel: string;
    searchCriteria: string;

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    get leftSideItems(): any[] {
        return this.filteredItems?.filter((item) => {
            return item[this.bindFlag] === this.inverse;
        })
    }

    get rightSideItems(): any[] {
        return this.filteredItems?.filter((item) => {
            return item[this.bindFlag] !== this.inverse;
        })
    }

    toggleFlag(item: any) {
        item[this.bindFlag] = !item[this.bindFlag];
    }

    get filteredItems() {
        if (this.searchCriteria?.length > 1) {
            return this.value.filter((item) => {
                const label = item[this.bindLabel];
                return label.toLowerCase().includes(this.searchCriteria.toLowerCase());
            })
        }
        return this.value;
    }

    onEditorHidden() {
        super.onEditorHidden();
        this.searchCriteria = '';
    }

    setMinWidth(element: HTMLElement) {
        return `${element.offsetWidth}px`
    }
}
