import { createAction, props } from '@ngrx/store';
import { DiscoveryComponentType } from '../../enums';
import { IDiscoveryInjectableComponentMessaging } from '../../interfaces';
import {
    IDiscoveryInjectableComponentSettings
} from '../../interfaces/discovery-injectable-component-settings.interface';

export const loadDiscoveryInjectableComponentSettings = createAction(
    '[Discovery injectable component settings] load settings',
    props<{
        componentGroupUuid: string,
        componentType: DiscoveryComponentType
    }>()
);

export const selectDiscoveryInjectableComponentSettings = createAction(
    '[Discovery injectable component settings] selecting component',
    props<{
        componentUuid: string,
        componentType: DiscoveryComponentType
    }>()
);

export const loadDiscoveryInjectableComponentSettingsSuccess = createAction(
    '[Discovery injectable component settings] load successful',
    props<{
        injectableComponentSettings: Array<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>,
        componentType: DiscoveryComponentType
    }>()
);

export const loadDiscoveryInjectableComponentSettingsFailure = createAction(
    '[Discovery injectable component settings] load failed',
    props<{
        error: any
    }>()
);

export const commitDiscoveryInjectableComponentSettings = createAction(
    '[Discovery injectable component settings] Commit settings',
    props<{
        componentGroupUuid: string,
        settings: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>,
        componentType: DiscoveryComponentType
    }>()
);

export const commitDiscoveryInjectableComponentSettingsSuccess = createAction(
    '[Discovery injectable component settings] Settings commit successful',
    props<{
        settings: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>,
        componentType: DiscoveryComponentType
    }>()
);

export const commitDiscoveryInjectableComponentSettingsFailure = createAction(
    '[Discovery injectable component settings] Settings commit failed',
    props<{
        error: any
    }>()
);

export const createDiscoveryInjectableComponentSettings = createAction(
    '[Discovery injectable component settings] create new component settings',
    props<{
        componentGroupUuid: string,
        settings: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>,
        componentType: DiscoveryComponentType
    }>()
);

export const createDiscoveryInjectableComponentSettingsSuccess = createAction(
    '[Discovery injectable component settings] create new component settings successful',
    props<{
        settings: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>,
        componentType: DiscoveryComponentType
    }>()
);

export const createDiscoveryInjectableComponentSettingsFailure = createAction(
    '[Discovery injectable component settings] create new component settings failed',
    props<{
        error: any
    }>()
);
