/**
 * Created by roman.kupin on 9/26/2017.
 */

import { Context } from "../contextSelector.setup";
import ContextGroupActions from "./actions/index";


export const NAME = "ftContextGroup";

export class ContextGroupController {

    static $inject = ["$ngRedux"];

    contexts: Context[];

    constructor(public $ngRedux) {
    }

    setup(contextSelectorId: string, contextGroup: string) {
        this.$ngRedux.connect(state => {
            if (!!state.ui.contextSelector[contextSelectorId]) {
                return state.ui.contextSelector[contextSelectorId].contextGroups.filter(group => group.name === contextGroup)[0];
            }
            return {};
        }, ContextGroupActions)(this);
    }
}

export const DEFINITION = [
    function () {

        return {
            scope: true,
            bindToController: true,
            controllerAs: "$ftContextGroup",
            controller: ContextGroupController,
            require: "ftContextGroup",
            link: function (scope, elem, attrs, ctrl: ContextGroupController) {

                ctrl.setup(scope.$ftContextSelector._id, attrs.name);
                //ctrl.contexts = scope.$ftContextSelector.contextSelectorSetup.contextGroups.filter(group => group.name === name)[0].contexts;
            }
        };
    }
];