import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    loadDiscoveryPropertySettings,
    loadDiscoveryPropertySettingsFailure,
    loadDiscoveryPropertySettingsSuccess,
    saveDiscoveryPropertySettings,
    saveDiscoveryPropertySettingsFailure,
    saveDiscoveryPropertySettingsSuccess
} from 'account-hybrid/common/components/touchpoints/store/property-settings/property-settings.actions';
import { selectCurrentDisplayedDiscoveryVersion } from 'account-hybrid/features/discovery/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DiscoveryApiService } from '../../services/discovery-api.service';


@Injectable()
export class PropertySettingsEffects {
    loadDiscoveryPropertySettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDiscoveryPropertySettings),
            withLatestFrom(this.store.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
            switchMap(([action, version]) =>
                this.discoveryApiService.getDiscoveryPropertySettings(action.propertyUuid, action?.version || version)
                    .pipe(
                        map(settings => loadDiscoveryPropertySettingsSuccess({ settings })),
                        catchError(error => of(loadDiscoveryPropertySettingsFailure({ error })))
                    )
            )
        )
    );

    saveDiscoveryPropertySettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveDiscoveryPropertySettings),
            withLatestFrom(this.store.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
            switchMap(([action, version]) =>
                this.discoveryApiService.updateDiscoveryPropertySettings(action.settings, action?.version || version)
                    .pipe(
                        map(settings => saveDiscoveryPropertySettingsSuccess({ settings })),
                        catchError(error => of(saveDiscoveryPropertySettingsFailure({ error })))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store<any>,
        private discoveryApiService: DiscoveryApiService
    ) {
    }
}
