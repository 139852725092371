import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { SpacesService } from "account-hybrid/common/components/spaces";
import { Environment, ENVIRONMENT_TOKEN } from "account-hybrid/core/environment.service";
import { ExperienceTag } from "account-hybrid/features/experiences/models/experience-tag.model";
import { VendorSettings } from "account-hybrid/features/experiences/models/vendor-settings.model";
import { ExperienceVenue, ExperienceVenueDto } from "account-hybrid/features/experiences/models/venue.model";
import { CustomEntityCollectionServiceBase } from "account-hybrid/store/services/custom-entity-collection-service.base";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EntityCollectionRegistryService } from "../../../store/services/entity-collection-registry.service";
import { Vendor } from "../models/vendor.model";


@Injectable({ providedIn: 'root' })
export class VendorsDataService extends CustomEntityCollectionServiceBase<Vendor> {
    settings$ = new BehaviorSubject<VendorSettings>(null);

    // @ts-ignore
    constructor(@Inject(ENVIRONMENT_TOKEN) environment: Environment,
                spacesService: SpacesService,
                serviceElementaryFactory: EntityCollectionServiceElementsFactory,
                ecr: EntityCollectionRegistryService,
                http: HttpClient) {
        const propertyUuid = spacesService.getPropertyUuid();
        const url = `${environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/vendors`;
        ecr.register(url, 'Vendor', {
            selectId: (vendor: Vendor) => vendor.companyUuid
        })
        super('Vendor', serviceElementaryFactory, http, url);
    }


    loadSettings(): Observable<VendorSettings> {
        this.get(`${this.selectedId}/settings`).subscribe((data) => {
            this.settings$.next(data);
        });
        return this.settings$;
    }

    updateSettings(changes: Partial<VendorSettings>): Observable<VendorSettings> {
        this.put(`${this.selectedId}/settings`, changes).subscribe((data) => {
            this.settings$.next(data);
        });
        return this.settings$;
    }

    get venues$(): Observable<ExperienceVenue[]> {
        return this.get(`${this.selectedId}/venues`).pipe(
            map((venuesDto: ExperienceVenueDto[]) => {
                return venuesDto.map(ExperienceVenue.dtoToPlain);
            })
        );
    }

    get tags$(): Observable<ExperienceTag[]> {
        return this.get(`${this.selectedId}/tags`);
    }
}

