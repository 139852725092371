import { createAction, props } from '@ngrx/store';
import { BannerPrearrivalEmailSettings } from './../../models/banner-email';

//styling
export const loadStylingPrearrivalEmail = createAction('[Banner Pre-arrival Email] load styling',
props<{
    componentGroupUuid: string,
    propertyId: string
}>());

export const loadStylingPrearrivalEmailSuccess = createAction('[Banner Pre-arrival Email] styling loadSuccess', props<{ data: any }>());
export const loadStylingPrearrivalEmailFail = createAction('[Banner Pre-arrival Email] styling loadFail', props<{ error: any }>());

// save changes toogle
export const commitBannerPrearrivalEmail = createAction('[Banner Pre-arrival Email] commit',
props<{
  componentGroupUuid: string,
  propertyId: string,
  isActive: boolean
}>());
export const commitBannerPrearrivalEmailSuccess = createAction('[Banner Pre-arrival Email] commitSuccess', props<{ data: BannerPrearrivalEmailSettings }>());
export const commitBannerPrearrivalEmailFail = createAction('[Banner Pre-arrival Email] commitFail', props<{ error: any }>());

// save data campaigns
export const saveBannerPrearrivalEmail = createAction('[Banner Prearrival Email] save data',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    changes: BannerPrearrivalEmailSettings
}>());

export const saveBannerPrearrivalEmailSuccess = createAction('[Banner Prearrival Email] save loadSuccess');
export const saveBannerPrearrivalEmailFail = createAction('[Banner Prearrival Email] save loadFail', props<{ error: any }>());
