import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SpaceType } from 'account-hybrid/common/components/spaces';
import { SpacesActionTypes } from 'account-hybrid/common/components/spaces/store/actions/spaces.actions';
import { IdentityService, SessionService } from 'account-hybrid/core/authentication';
import { addNew, selectGroup } from 'account-hybrid/features/context-selector/store';
import { setMultipleContext, setSingleContext } from 'account-hybrid/store';
import {
    ContextSelectorGroup,
    ContextSelectorSetup,
    PropertyContext
} from 'account-hybrid/utils/context-selector-setup.util';
import { filter, tap } from 'rxjs/operators';
import { FT_CACHE_TOKEN, USER_API_TOKEN } from '../../app.module';
import { SpacesService } from '../../common/components/spaces/spaces.service';
import { signOut } from '../actions/authentication.actions';

@Injectable()
export class AppEffects {
    constructor(private actions$: Actions,
                private store: Store<any>,
                private injector: Injector,
                private identity: IdentityService,
                private spacesService: SpacesService,
                private sessionService: SessionService) {
    }

    signOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signOut),
            tap(() => {
                const injector = this.injector;
                const spacesService = this.spacesService;
                const userApi = injector.get(USER_API_TOKEN) as any;

                function clearSession() {
                    const ftCache = injector.get(FT_CACHE_TOKEN) as any;
                    localStorage.removeItem('flipto.session');
                    ftCache.removeAll();
                    spacesService.go(null);
                    location.href = '/sign-in';
                }

                userApi.logout()
                    .then(clearSession, clearSession);
            })
        ), { dispatch: false }
    );

    onStartup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SpacesActionTypes.Initialize),
            filter(() => !this.identity.isAnonymous() && !!this.spacesService.current && this.spacesService.current.type != SpaceType.Collaborative),
            tap(() => {
                const currentSpace = this.spacesService.current;
                if (!currentSpace) return;
                const propertyContexts = this.getPropertyContextsBasedOnSpace(currentSpace, this.sessionService);
                const singlePropertySelectorSetup = new ContextSelectorSetup('singlePropertySelector', [
                    new ContextSelectorGroup(
                        'properties',
                        (contextGroup) => `${contextGroup.contexts.length} properties`,
                        propertyContexts)
                ]);
                const multiplePropertySelectorSetup = new ContextSelectorSetup('multiplePropertySelector', [
                    new ContextSelectorGroup(
                        'properties',
                        (contextGroup) => `${contextGroup.contexts.length} properties`,
                        propertyContexts)
                ]);
                this.store.dispatch(addNew({ payload: singlePropertySelectorSetup }));
                this.store.dispatch(addNew({ payload: multiplePropertySelectorSetup }));
                this.store.dispatch(selectGroup({ payload: {contextSelectorId: 'singlePropertySelector', groupName: 'properties' }}));
                this.store.dispatch(selectGroup({ payload: {contextSelectorId: 'multiplePropertySelector', groupName: 'properties' }}));

                if (currentSpace.type == SpaceType.Property) {
                    this.store.dispatch(setSingleContext({ property: currentSpace.id }));
                    this.store.dispatch(setMultipleContext({ properties: [currentSpace.id] }));
                }
                if (currentSpace.type == SpaceType.Mixed) {
                    this.store.dispatch(setSingleContext({ property: (currentSpace as any).property.id }));
                    this.store.dispatch(setMultipleContext({ properties: [(currentSpace as any).property.id] }));
                }
            })
        ), { dispatch: false }
    );

    getPropertyContextsBasedOnSpace(currentSpace, sessionService) {
        if (!currentSpace) return [];
        switch (currentSpace.type) {
            case SpaceType.Company: {
                return sessionService.getCompanyProperties(currentSpace.id)
                    .map(property => new PropertyContext(property.DisplayName || property.Name, property.UUID, property.Slug, property.BackgroundImageUrl));
            }
            case SpaceType.Mixed: {
                return sessionService.getCompanyProperties(currentSpace.company.id)
                    .map(property => new PropertyContext(property.DisplayName || property.Name, property.UUID, property.Slug, property.BackgroundImageUrl));
            }
            case SpaceType.Property: {
                return [sessionService.getProperty(currentSpace.id)]
                    .map(property => new PropertyContext(property.DisplayName || property.Name, property.UUID, property.Slug, property.BackgroundImageUrl));
            }
        }
    }
}
