import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsService } from "./services/notifications.service";
import { notificationsReducer } from "./store";
import { NotificationsEffects } from "./store/notifications.effects";


@NgModule({
    declarations: [
        NotificationsComponent
    ],
    imports: [
        CommonModule,
        AccountSharedModule,
        StoreModule.forFeature('Notifications', notificationsReducer),
        EffectsModule.forFeature([NotificationsEffects]),
    ],
    exports: [
        NotificationsComponent
    ],
    providers: [
        NotificationsService
    ]
})
export class NotificationsModule {
}
