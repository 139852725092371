import { createReducer, on } from '@ngrx/store';
import { loadRandomShowcasePhotos, loadRandomShowcasePhotosFailure, loadRandomShowcasePhotosSuccess } from '../actions';
import { ShowcaseStoriesState } from '../showcase-stories.state';

function getLayout(num) {
    if (num >= 5) return 5;
    if (num >= 3) return 3;
    return 1;
}

const initialState: ShowcaseStoriesState = {
    photos: [],
    layout: 1,
    loading: false,
    loaded: false,
    error: null
};


export const showcaseReducer = createReducer(initialState,
    on(loadRandomShowcasePhotos, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),

    on(loadRandomShowcasePhotosSuccess, (state, action) => {

        let layout = 1;
        const guestPhotosCount = action.photos.filter(photo => photo.IsGuestPhoto === true)?.length;
        const propertyPhotosCount = action.photos.filter(photo => photo.IsGuestPhoto === false)?.length;

        if (guestPhotosCount >= 3) layout = getLayout(guestPhotosCount);
        else layout = getLayout(propertyPhotosCount);
        return {
            ...state,
            layout,
            photos: action.photos,
            loading: false,
            loaded: true,
            error: null
        };
    }),

    on(loadRandomShowcasePhotosFailure, (state, action) => ({
        ...state,
        photos: [],
        loading: false,
        loaded: false,
        error: action?.error
    }))
);
