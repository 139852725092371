import { createAction, props } from '@ngrx/store';
import { PropertyLanguage } from 'account-hybrid/common/models/property-language.model';
import { CompanyLanguage } from '../../../../models/company-language.model';

export const loadAccountLanguages = createAction('[AccountSettings] Load AccountLanguages');

export const loadAccountLanguagesSuccess = createAction(
    '[AccountSettings] Load AccountLanguages Success',
    props<{ languages: CompanyLanguage[] | PropertyLanguage[] }>()
);

export const loadAccountLanguagesFailure = createAction(
    '[AccountSettings] Load AccountLanguages Failure',
    props<{ error: any }>()
);

export const loadAvailableAccountLanguages = createAction('[AccountSettings] Load Available AccountLanguages');

export const loadAvailableAccountLanguagesSuccess = createAction(
    '[AccountSettings] Load Available AccountLanguages Success',
    props<{ languages: CompanyLanguage[] | PropertyLanguage[] }>()
);

export const loadAvailableAccountLanguagesFailure = createAction(
    '[AccountSettings] Load Available AccountLanguages Failure',
    props<{ error: any }>()
);

export const createAccountLanguage = createAction(
    '[AccountSettings] Create Language',
    props<{ language: CompanyLanguage | PropertyLanguage }>()
);
export const createAccountLanguageSuccess = createAction(
    '[AccountSettings] Create Language Success'
);
export const createAccountLanguageFailure = createAction(
    '[AccountSettings] Create Language Failure',
    props<{ error: any }>()
);

export const updateAccountLanguages = createAction(
    '[AccountSettings] Update Languages',
    props<{ languages: CompanyLanguage[] | PropertyLanguage[] }>()
);
export const updateAccountLanguagesSuccess = createAction(
    '[AccountSettings] Update Languages Success'
);
export const updateAccountLanguagesFailure = createAction(
    '[AccountSettings] Update Languages Failure',
    props<{ error: any }>()
);

export const removeAccountLanguage = createAction(
    '[AccountSettings] Remove Language',
    props<{ languageCode: string }>()
);
export const removeAccountLanguageSuccess = createAction(
    '[AccountSettings] Remove Language Success'
);
export const removeAccountLanguageFailure = createAction(
    '[AccountSettings] Remove Language Failure',
    props<{ error: any }>()
);
