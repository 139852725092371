import { Injectable } from '@angular/core';
import { QueryArrayFormat } from "@flipto/shared-discovery/enums/query-array-fomat.enum";
import { UrlUtil } from '@flipto/shared/src/lib/utils/common/url.util';
import { BookingEngineToken } from './booking-engine-token.enum';
import { TokenInjectionType } from './token-injection-type.enum';
import { TokenOption as TokenOptions } from './token-option.interface';
import { TokenReplacer } from './token-replacer.interface';
import { TokenValue } from './token-value.model';

@Injectable({
    providedIn: 'root'
})
export class TokenReplacerService implements TokenReplacer {

    private static supportedTokenKeys: string[] = Object.values(BookingEngineToken);

    constructor() {
    }

    replace(templateUrl: string, suppliedTokens: Array<TokenValue<string | number | Date>>): string {
        let resultUrl = templateUrl;

        // Replace/remove supported tokens first
        for (const tokenKey of TokenReplacerService.supportedTokenKeys) {
            const token = suppliedTokens.find(aToken => aToken.key == tokenKey);
            resultUrl = this.replaceToken(resultUrl, tokenKey, token);
        }

        // Get data for finding extra tokens
        const { queryParams, hashParams } = UrlUtil.parseUrl(resultUrl);
        const extraTokens = suppliedTokens.filter(aToken => !TokenReplacerService.supportedTokenKeys.includes(aToken.key));

        // Add all unknown extra tokens (i.e. extra parameters)
        for (const token of extraTokens) {
            if (token.injectionType == TokenInjectionType.query && !queryParams.has(token.key)) {
                resultUrl = UrlUtil.addToQueryString(resultUrl, token.key, token.value.toString());
            } else if (token.injectionType == TokenInjectionType.fragment && !hashParams.has(token.key)) {
                resultUrl = UrlUtil.addToFragment(resultUrl, token.key, token.value.toString());
            }
        }
        return resultUrl;
    }

    private replaceToken(templateUrl: string, tokenKey: string, token?: TokenValue<string | number | Date>) {
        // target sections that look like:
        //    First Query String Param  - ?key={value,options}
        //    Secondary Param           - &key={value,options}
        //    First Fragment Param      - #key={value,options}
        //    Path Param                - {value,options}
        // Regex explained (First delimiter) (Query key) = (query value is token in brackes with optional comma delimited formatters) (trailing delimiter)
        // const reQSToken = new RegExp(`(?:([?&#])([^=&#]+=))?({${tokenKey}(?=[,}])[^}]*})([?&#])?`, 'ig');
        const reQSToken = new RegExp(
            `(?:([?&#])([^=&#]+=))?(\\{${tokenKey}(?=[,}])(?:[^{}]+|\\{[^{}]*\\})*\\})([?&#])?`,
            'ig'
        );


        // If we find the token, regex replace it
        let didReplace = false;
        templateUrl = templateUrl.replace(reQSToken, (_fullstring: string, startChar: string, queryKey: string, tokenText: string, trailingChar: string) => {
            didReplace = true;
            const options = this.parseOptions(tokenText);
            const tokenValue = token?.value || options.fallback;
            startChar = startChar || '';
            trailingChar = trailingChar || '';
            queryKey = queryKey || '';

            // token value can be array
            const arrayFormat = options.arrayFormat?.toLowerCase();
            // if array format is JSON skip this step
            if (Array.isArray(tokenValue) && arrayFormat !== QueryArrayFormat.JSON) {
                const formattedValue = !!options.format && !!token?.formatter
                    ? token.formatter.format(tokenValue, options.format) as string[]
                    : tokenValue;

                // Encode any values
                if (formattedValue) {
                    switch (arrayFormat) {
                        // JSON array that has already been processed by its own formater above.
                        case QueryArrayFormat.JSON:
                            return startChar + this.buildParamStr(queryKey, formattedValue as string, options.encoding) + trailingChar;
                        // Delimited strings i.e. ?x=1,2,3
                        case QueryArrayFormat.Delimited:
                            return startChar + this.buildParamStr(queryKey, formattedValue.join(options.delimiter), options.encoding) + trailingChar;
                        // Array bracket format i.e. ?x[]=1&x[]=2
                        case QueryArrayFormat.Brackets:
                            return startChar + formattedValue
                                    .map((value: string | number | Date) => this.buildParamStr(queryKey, value, options.encoding, '[]'))
                                    .filter(x => x)
                                    .join('&')
                                + trailingChar;
                        // Standard array format like: ?x1=1&x2=2
                        case QueryArrayFormat.Incrementing:
                            return startChar + formattedValue
                                    .map((value: string | number | Date, i: number) => this.buildParamStr(queryKey, value, options.encoding, (i + options.startIndex).toString()))
                                    .filter(x => x)
                                    .join('&')
                                + trailingChar;
                        // Standard array format like: ?x=1&x=2
                        case QueryArrayFormat.RepeatedValue:
                        default:
                            if (Array.isArray(formattedValue)) {
                                return startChar + formattedValue.map((value: string | number | Date) => this.buildParamStr(queryKey, value, options.encoding))
                                        .filter(x => x)
                                        .join('&')
                                    + trailingChar;
                            }else{
                                // in case it's just a string
                                return startChar + this.buildParamStr(queryKey, formattedValue as string, options.encoding) + trailingChar;
                            }

                    }
                }
            } else {
                // Format if we have a formatter and a requested format
                const formattedValue = !!options.format && !!token?.formatter
                    ? token.formatter.format(tokenValue, options.format) as string
                    : tokenValue?.toString();

                // Encode any values
                if (formattedValue) {
                    return startChar + this.buildParamStr(queryKey, formattedValue, options.encoding) + trailingChar;
                }
            }


            // If we're a not the first parameter, drop the start (&), keep the trail (&|#)
            if (startChar == '&') {
                return trailingChar;
            }
            // If we are the only parameter before the hash, drop the start (?) keep the hash
            if (trailingChar == '#') {
                return trailingChar;
            }
            // If we're the first parameter of a query string/hash, keep the first char (?|#), drop the last (&|#)
            return startChar;
        });

        // If we didn't find the token, use injection type to determine where/if we should inject the token key/value
        if (!didReplace && token?.value != null) {
            switch (token.injectionType) {
                case TokenInjectionType.query:
                    return UrlUtil.addToQueryString(templateUrl, tokenKey, token.value.toString());
                case TokenInjectionType.fragment:
                    return UrlUtil.addToFragment(templateUrl, tokenKey, token.value.toString());
            }
        }
        return templateUrl;
    }

    private buildParamStr(queryKey: string, value: (string | number | Date), encoding: string, suffix: string = '') {
        // No value, no parameter so our URL is as short as possible (i.e. don't send &promo=&adults=1 when &adults=1 enough)
        if (value) {
            // Suffix the query key like array brackets (x[]=1&x[]=4) or incrementing numbers (x1=1&x2=4)
            if (suffix) {
                // Insert before the equals if there is any
                if (queryKey.endsWith('=')) {
                    queryKey = queryKey.substring(0, queryKey.length - 1) + suffix + '=';
                } else {
                    queryKey = queryKey + suffix;
                }
            }

            return queryKey + this.encodeValue(encoding, value.toString());
        }
    }

    private encodeValue(encoding: string, value: string) {
        switch (encoding) {
            case 'base64':
                return btoa(value);
            case 'uri':
                return encodeURIComponent(value);
            default:
                return value;
        }
    }

    private parseOptions(substring: string): TokenOptions {
        let optionMatch: RegExpExecArray;
        const options = new TokenOptions();

        const optionsRegex = new RegExp(`(?:,)?(${Object.keys(options).join('|')})=([^,{}]+|\\{[^{}]*\\})`, 'gi');

        while ((optionMatch = optionsRegex.exec(substring)) !== null) {
            options[optionMatch[1]] = optionMatch[2];
        }

        return options;
    }
}
