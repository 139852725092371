import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpacesService } from '../../../common/components/spaces';
import { Environment, ENVIRONMENT_TOKEN } from '../../../core/environment.service';
import { DiscoveryVersioning, MissionControlSettingsDetailsDto } from '../../mission-control/models';

@Injectable({
    providedIn: 'root'
})
export class DiscoveryVersioningService {
    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    getDiscoveryVersioning(propertyUuid: string = this.spacesService.getPropertyUuid()): Observable<DiscoveryVersioning> {
        if (!propertyUuid) {
            return EMPTY;
        }
        return this.http.get<MissionControlSettingsDetailsDto>(`${this.environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/settings/details`)
            .pipe(map(settings => settings?.discovery));
    }
}
