import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarTabComponent } from './components/sidebar-tab/sidebar-tab.component';
import { SidebarTabsComponent } from "./components/sidebar-tabs/sidebar-tabs.component";


@NgModule({
    declarations: [SidebarTabsComponent, SidebarTabComponent],
    imports: [
        CommonModule
    ],
    exports: [
        SidebarTabsComponent,
        SidebarTabComponent
    ]
})
export class SidebarTabsModule {
}
