import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { SidebarTabComponent } from "../sidebar-tab/sidebar-tab.component";

@Component({
    selector: 'ft-sidebar-tabs',
    templateUrl: './sidebar-tabs.component.html',
    styleUrls: ['./sidebar-tabs.component.scss']
})
export class SidebarTabsComponent {
    @ContentChildren(SidebarTabComponent) tabs: QueryList<SidebarTabComponent>;
    @Input() classes: string[];
    activeTabIndex = 0;

    constructor() {
    }
}
