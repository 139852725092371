import { createAction, props } from '@ngrx/store';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import { MissionControlSettingsDetailsDto } from 'account-hybrid/features/mission-control/models';
import { DiscoveryProperty } from '../../models';
import { DiscoveryComponentSettings, DiscoveryComponentSignatureUser } from '../../models/discovery-component';

export const loadDiscoveryComponentSettings = createAction(
    '[Discovery component settings] Load settings',
    props<{
        componentGroupUuid: string
    }>()
);

export const loadDiscoveryComponentSettingsSuccess = createAction(
    '[Discovery component settings] Load settings successful',
    props<{
        settings: DiscoveryComponentSettings
    }>()
);

export const loadDiscoveryComponentSettingsFailure = createAction(
    '[Discovery component settings] Load settings failed',
    props<{
        error: any
    }>()
);

export const commitDiscoveryComponentSettings = createAction(
    '[Discovery component settings] Commit settings',
    props<{
        componentGroupUuid: string,
        settings: Partial<DiscoveryComponentSettings>,
        version?: DiscoveryVersions
    }>()
);

export const commitDiscoveryComponentSettingsSuccess = createAction(
    '[Discovery component settings] Settings commit successful',
    props<{
        settings: Partial<DiscoveryComponentSettings>
    }>()
);

export const commitDiscoveryComponentSettingFailure = createAction(
    '[Discovery component settings] Settings commit failed',
    props<{
        error: any
    }>()
);

export const loadDiscoveryProperties = createAction(
    '[Discovery component settings] Load discovery properties.',
    props<{
        componentGroupUuid: string
    }>()
);

export const loadDiscoveryPropertiesSuccess = createAction(
    '[Discovery component settings] Discovery properties loaded successfully.',
    props<{
        discoveryProperties: Array<DiscoveryProperty>
    }>()
);

export const loadDiscoveryPropertiesFailure = createAction(
    '[Discovery component settings] Discovery properties failed to load.',
    props<{
        error: any
    }>()
);

export const loadDiscoveryComponentCompanyUsers = createAction(
    '[Discovery component settings] Load company users',
    props<{
        companyUuid: string,
        propertyUuid?: string
    }>()
);

export const loadDiscoveryComponentCompanyUsersSuccess = createAction(
    '[Discovery component settings] Load company users successful',
    props<{
        users: DiscoveryComponentSignatureUser[]
    }>()
);

export const loadDiscoveryComponentCompanyUsersFailure = createAction(
    '[Discovery component settings] Load company users failed',
    props<{
        error: any
    }>()
);

// load data from mission control
export const loadMissionControlDiscoverySettings = createAction(
    '[Discovery component settings] Load details settings from mission control'
);

export const loadMissionControlDiscoverySettingsSuccess = createAction(
    '[Discovery component settings] Load details settings from mission control successful',
    props<{
        missionControlDetailsSettings: MissionControlSettingsDetailsDto
    }>()
);

export const loadMissionControlDiscoverySettingsFailure = createAction(
    '[Discovery component settings] Load details settings from mission control failed',
    props<{
        error: any
    }>()
);
