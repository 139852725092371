import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { Parse } from 'shared';
import { getSpaceAttributesFromSpace, SpacesService } from '../spaces';
import { StoryModel } from './../../../features/stories/models/stories-library.model';
import { CURATION_FILTERS, CURATION_FILTERS_QUOTES } from './models/curation.const';
import { CurationModalData } from './models/curation.model';
import { CurationHandlerService } from './services/curation-handler.service';
import {
    advanceToCampaign,
    loadCurationModalData,
    selectCurationModalData,
    updateRate
} from './store';
import { loadStoriesIsCurationPhoto, loadStoriesIsCurationQuotes } from './store/actions/curation.actions';
import {
    selectAdvanceToCampaignLoading,
    selectStoriesIsCurationPhotos,
    selectStoriesIsCurationQuotes
} from './store/curation.selectors';
import { CurationState } from './store/curation.state';

@Component({
  selector: 'ft-curation',
  templateUrl: './curation.component.html',
  styleUrls: ['./curation.component.scss']
})
export class CurationComponent implements OnInit {
  photosCuration: StoryModel[];
  quotesCuration: StoryModel[];
  @Input() backgroundImageStoriesDetailPage: string = null;
  @Input() showTimeline = true;
  @Input() isStoriesDetailPage: boolean = false;
  @Input() curationModalId: string = null;
  @Input() specificStory: StoryModel = null;
  @Output() updateStories = new EventEmitter<any>();

  notCuratedStory: any;
  totalNotCurrated = {
    photos: 0,
    quotes: 0
  };
  data$: Observable<CurationModalData>;
  advanceToCampaignLoaded$: Observable<boolean>;
  dataCuration: CurationModalData;
  totalNumber;
  currentIndex: number = null;
  filters;
  filtersQuotes = CURATION_FILTERS_QUOTES;

  constructor(
    private stateService: StateService,
    private spacesService: SpacesService,
    public curationHandlerService: CurationHandlerService,
    private store: Store<CurationState>,
    public changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.filters = CURATION_FILTERS;
    this.filtersQuotes = CURATION_FILTERS_QUOTES;
    this.loadStoriesIsCurationPhotos(this.filters[0].query);
    this.loadStoriesIsCurationQuotes(this.filtersQuotes[0].query);
    this.isHiddenCuration();

    if (this.specificStory || this.curationModalId) {
      this.notCuratedStory = [this.notCuratedStory];
    }
  }

  changeDetect() {
    this.totalNotCurrated.photos = this.curationHandlerService.getTotalNotCurated(this.photosCuration);
    this.totalNotCurrated.quotes = this.curationHandlerService.getTotalNotCurated(this.quotesCuration);
    this.changeDetectorRef.detectChanges();
  }

  isHiddenCuration() {
    return this.curationHandlerService.getTotalNotCurated(this.photosCuration) == 0
      && this.curationHandlerService.getTotalNotCurated(this.quotesCuration) == 0;
  }

  navigateToStories() {
    this.stateService.go('stories.curation', {
      ...getSpaceAttributesFromSpace(this.spacesService.current)
    });
  }

  getGuestId(index?: number): string {
    const photoStories = Parse.arrayOrDefault(this.curationHandlerService.getNotCurated(this.photosCuration));
    const quoteStories = Parse.arrayOrDefault(this.curationHandlerService.getNotCurated(this.quotesCuration));
    let firstPhotoStory, firstQuoteStory;
    if (!photoStories.length && !quoteStories.length) return;

    const isDoingPhotosCuration = !!photoStories.length;
    if (!index) {
      firstPhotoStory = !!photoStories.length ? photoStories[0] : null;
      firstQuoteStory = !!quoteStories.length ? quoteStories[0] : null;

      let photosTotal = this.curationHandlerService.getTotalNotCurated(this.photosCuration);
      let quotesTotal = this.curationHandlerService.getTotal(this.quotesCuration);
      this.totalNumber = isDoingPhotosCuration ? photosTotal : quotesTotal;
      this.notCuratedStory = isDoingPhotosCuration ? photoStories : quoteStories;
    } else {
      this.currentIndex = index;
      firstPhotoStory = !!photoStories.length ? photoStories[index] : null;
      firstQuoteStory = !!quoteStories.length ? quoteStories[index] : null;
    }

    return (isDoingPhotosCuration ? firstPhotoStory : firstQuoteStory).promoter.guestUUID;
  }

  loadCurationModalData(index?: number) {
    this.store.dispatch(loadCurationModalData({
      propertyUUID: this.spacesService.getPropertyUuid() || this.spacesService.getCompanyUuid(),
      guestUUID: !this.specificStory ? this.curationModalId || this.getGuestId(index) : this.specificStory?.promoter?.guestUUID
    }));
    this.data$ = this.store.pipe(select(selectCurationModalData));
    this.data$.subscribe((res) => {
      this.dataCuration = res;
      return res;
    });
  }

  loadStoriesIsCurationPhotos(query) {
    this.store.dispatch(loadStoriesIsCurationPhoto({
      propertyUuid: this.spacesService.getPropertyUuid(),
      tab: 'photos',
      query: query
    }));
    this.store.pipe(select(selectStoriesIsCurationPhotos)).subscribe(val => {
      this.photosCuration = val?.stories || [];
      this.changeDetect();
    });
  }

  loadStoriesIsCurationQuotes(query) {
    this.store.dispatch(loadStoriesIsCurationQuotes({
      propertyUuid: this.spacesService.getPropertyUuid(),
      tab: 'nophotos',
      query: query
    }));
    this.store.pipe(select(selectStoriesIsCurationQuotes)).subscribe(val => {
      this.quotesCuration = val ? val.stories : [];
    });
  }

  updateRating({index, type}) {
    let id;

    if (this.specificStory) {
      id = type !== 'quote' ? this.specificStory.photo.photoUUID : this.specificStory.promoter.guestUUID;
    } else {
      id = type !== 'quote'
        ? Parse.arrayOrDefault(this.curationHandlerService.getNotCurated(this.photosCuration))[this.currentIndex ? this.currentIndex : 0]?.photo.photoUUID
        : this.getGuestId(this.currentIndex);
    }

    this.store.dispatch(updateRate({
      propertyUUID: this.spacesService.getPropertyUuid() || this.spacesService.getCompanyUuid(),
      guestUUID: id,
      index: index,
      typeRate: type
    }));
    this.loadCurationModalData(this.currentIndex);
  }

  updateData(index: number) {
    this.loadCurationModalData(index);
  }

  advanceToFinal(pass: boolean) {
    this.store.dispatch(advanceToCampaign({
      propertyUUID: this.spacesService.getPropertyUuid() || this.spacesService.getCompanyUuid(),
      photoId: this.dataCuration.guest.poststay.photo.uuid,
      data: pass
    }));
    this.advanceToCampaignLoaded$ = this.store.pipe(select(selectAdvanceToCampaignLoading));
    this.advanceToCampaignLoaded$.subscribe(result => {
      this.loadCurationModalData(this.currentIndex);
      return result;
    });
  }
}
