import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { DatePickerComponent } from './components/date-picker/date-picker.component';


@NgModule({
    declarations: [
        DatePickerComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ClickOutsideModule,
        FormsModule,
        NgbDatepickerModule
    ],
    exports: [
        DatePickerComponent
    ]
})
export class DatePickerModule {
}
