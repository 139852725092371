import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StateService } from '@uirouter/core';
import { IdentityService, SessionService } from 'account-hybrid/core/authentication';
import { ImpersonationApiService } from 'account-hybrid/features/admin-area/services/impersonation-api.service';
import * as fromComponents from 'account-hybrid/store/reducers/index';
import * as fromRoot from 'account-hybrid/store/reducers/index';
import moment from 'moment';
import { Observable } from 'rxjs';
import { UserData } from '../../../../../features/authentication/models/user-data';
import * as fromAuthentication from '../../../../../features/authentication/store/index';
import * as fromNotifications from '../../../notifications/store';
import { SpacesService } from '../../../spaces';
import { SetIsVisible } from '../../../spaces/store/actions/spaces.actions';
import * as fromSpaces from '../../../spaces/store/reducers/spaces.reducer';
import * as fromAppMenu from '../../store';

@Component({
  selector: 'ft-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppMenuComponent implements OnInit, OnDestroy {
  visibility$: Observable<boolean>;
  spaces$: Observable<fromSpaces.State>;
  spaceTitle: string;
  user$: Observable<UserData> = this.store.pipe(select(fromAuthentication.getUserData));
  isVisible: boolean;


    constructor(private notificationsStore: Store<fromNotifications.NotificationsState>,
        private stateService: StateService,
        private appMenuStore: Store<fromAppMenu.AppMenuState>,
        private authStore: Store<fromAuthentication.AuthenticationState>,
        public sessionService: SessionService,
        public spaceService: SpacesService,
        public identityService: IdentityService,
        public store: Store<fromRoot.State>,
        private impersonationApiService: ImpersonationApiService) {
        this.setSpaceTitle();
    }

  setSpaceTitle() {
    const space = this.spaceService.current;
    if (space) {
      this.spaceTitle = space.title;// + (space.type === SpaceType.Collaborative ? ' Collaborative' : '');
    }
  }

  goToProfile() {
    let backUrl = document.location.href;
    this.stateService.go(`profile`, { backUrl });
  }

  hideAppMenu() {
    if (this.isVisible) {
      this.appMenuStore.dispatch(fromAppMenu.hideAppMenu());
      this.updateSeenDateOfNotifications();
    }
  }

  updateSeenDateOfNotifications() {
    this.appMenuStore.dispatch(fromNotifications.updateSeenDateOfNotifications({ seenDate: moment().utc().format() }));
  }

  signOut() {
    this.sessionService.signOut();
  }

    unimpersonate() {
        this.impersonationApiService.unimpersonate()
            .then((userSession) => {
                localStorage.setItem('flipto.session', JSON.stringify(userSession));
                window.location.href = "/home";
            })
    }

    ngOnInit() {
        this.visibility$ = this.appMenuStore.pipe(select(fromAppMenu.selectVisibility));
        this.spaces$ = this.store.pipe(select(fromComponents.selectSpaces));
        this.visibility$.subscribe((isVisible) => {
            this.isVisible = isVisible;
        });
    }

  ngOnDestroy() {
    this.updateSeenDateOfNotifications();
  }

  showSpacesComponent() {
    this.store.dispatch(new SetIsVisible(true));
  }
}


