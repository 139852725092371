import { Action, createReducer, on } from '@ngrx/store';
import {
    createComponentGroup, createComponentGroupFailure, createComponentGroupSuccess,
    loadComponentGroupsFailure,
    loadComponentGroupsSuccess,
    loadComponentsGroups,
    selectComponentGroup
} from '../store/component-groups.actions';
import { ComponentGroupsState } from './component-groups.state';


export const componentGroupsInitialState: ComponentGroupsState = {
    componentGroups: [],
    selectedComponentGroup: null,
    loading: false,
    error: null
};

const reducer = createReducer(
    componentGroupsInitialState,
    on(loadComponentsGroups, state => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadComponentGroupsSuccess, (state, { componentGroups }) => {
        return {
            ...state,
            loading: false,
            componentGroups
        };
    }),
    on(loadComponentGroupsFailure, (state, { error }) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(selectComponentGroup, (state, { selectedComponentGroup }) => {
        return {
            ...state,
            selectedComponentGroup
        };
    }),
    on(createComponentGroup, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(createComponentGroupFailure, (state, { error }) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(createComponentGroupSuccess, (state, { componentGroup }) => {
        return {
            ...state,
            loading: false,
            error: null,
            componentGroups: [...state.componentGroups, componentGroup],
            selectedComponentGroup: componentGroup
        };
    })
);

export function componentGroupsReducer(state: ComponentGroupsState | undefined, action: Action) {
    return reducer(state, action);
}


