import { ActionReducerMap } from '@ngrx/store';
import {
    propertySettingsReducer
} from 'account-hybrid/common/components/touchpoints/store/property-settings/property-settings.reducer';
import { TouchpointsState } from 'account-hybrid/common/components/touchpoints/store/touchpoints.state';
import * as fromBannerEmail from './banner-email';
import * as fromBannerPrearrivalEmail from './banner-prearrival-email';
import * as fromBooknow from './book-now';
import * as fromBookingbar from './booking-bar';
import * as fromCampaignGuests from './campaign-guests';
import * as fromCertificateEmail from './certificate-email';
import * as fromConfirmationExperience from './confirmation-experience';
import * as fromDiscoveryComponent from './discovery-component';
import * as fromDiscoveryInjectableComponent from './discovery-injectable-component';
import * as fromFollowupEmail from './followup-email';
import * as fromFriendLanding from './friend-landing';
import * as fromInvitationEmail from './invitation-email';
import * as fromPlannerflow from './planner-flow';
import * as fromPoststayProcess from './poststay-process';
import * as fromStoryExplorer from './story-explorer';

export const reducers: ActionReducerMap<TouchpointsState> = {
    booknow: fromBooknow.booknowReducer,
    bookingbar: fromBookingbar.bookingbarReducer,
    plannerflow: fromPlannerflow.plannerflowReducer,
    discoveryComponent: fromDiscoveryComponent.discoveryComponentReducer,
    discoveryInjectableComponents: fromDiscoveryInjectableComponent.fromDiscoveryInjectableComponentReducer,
    confirmationExperience: fromConfirmationExperience.confirmationExperienceReducer,
    certificateEmail: fromCertificateEmail.certificateEmailReducer,
    followupEmail: fromFollowupEmail.followupEmailReducer,
    bannerEmail: fromBannerEmail.bannerEmailReducer,
    bannerPrearrivalEmail: fromBannerPrearrivalEmail.bannerPrearrivalEmailReducer,
    invitationEmail: fromInvitationEmail.invitationEmailReducer,
    poststayProcess: fromPoststayProcess.poststayProcessReducer,
    storyExplorer: fromStoryExplorer.storyExplorerReducer,
    friendLanding: fromFriendLanding.friendLandingReducer,
    campaignGuests: fromCampaignGuests.campaignGuestsReducer,
    discoveryPropertySettings: propertySettingsReducer
};
