import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { ClickOutsideModule } from "ng-click-outside";
import { AdvancedSearchComponent } from "./components/advanced-search/advanced-search.component";
import { AdvancedSearchFilterPipe } from "./pipe/advanced-search-filter.pipe";


@NgModule({
    declarations: [
        AdvancedSearchComponent,
        AdvancedSearchFilterPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        ClickOutsideModule
    ],
    exports: [
        AdvancedSearchComponent,
        AdvancedSearchFilterPipe
    ]
})
export class AdvancedSearchModule {
}
