import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'ft-user-image-renderer',
    templateUrl: './user-image-renderer.component.html',
    styleUrls: ['./user-image-renderer.component.scss']
})
export class UserImageRendererComponent implements ICellRendererAngularComp {
    public params: any;
    user: any;
    userInitials: string;

    agInit(params: any): void {
        this.params = params;
        this.user = params.value;
        this.setUserInitials();
    }

    setUserInitials() {
        this.userInitials = this.user ? this.user.name.split(' ').reduce((prev, string) => {
            return prev + string[0];
        }, '') : '';
    }


    refresh(): boolean {
        return false;
    }
}
