import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { selectCurrentDisplayedDiscoveryVersion } from 'account-hybrid/features/discovery/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Guid } from 'shared';
import { DiscoveryComponentSettings, DiscoveryComponentSignatureUser, DiscoveryProperty } from '../../models';
import { DiscoveryApiService } from '../../services/discovery-api.service';
import * as fromTouchpoints from '../touchpoints.state';
import * as fromActions from './discovery-component.actions';


const populateDestinationsNamedRanges = (response) => {
    for (const destinationGroup of (response?.mappingViewModel?.properties || [])) {
        for (const destination of (destinationGroup?.products || [])) {
            destination.namedRanges.push(...response?.mappingViewModel?.namedRanges?.map(nr => JSON.parse(JSON.stringify(nr))));
            for (const namedRange of (destination?.namedRanges || [])) {
                namedRange?.destinationNamedRangePhotos?.push(...destination?.destinationNamedRangePhotos?.filter(dnrp =>
                    dnrp?.namedRangeUuid === namedRange?.namedRangeUuid
                    && dnrp?.destinationUuid === destination?.destinationUuid)?.map(dnrp => JSON.parse(JSON.stringify(dnrp))));
            }
        }
    }
};

@Injectable()
export class DiscoveryComponentEffects {

    loadDiscoveryComponentSettings$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadDiscoveryComponentSettings),
        withLatestFrom(this.store$.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
        mergeMap(([{ componentGroupUuid }]) =>
            this.discoveryApiService.getDiscoveryComponentSettings(componentGroupUuid).pipe(
                map((response: DiscoveryComponentSettings) => {
                    // @todo check for what it was used?
                    // populateDestinationsNamedRanges(response);
                    // if (!response.integrationViewModel.signatureUserUuid) {
                    //     response.integrationViewModel.signatureUserUuid = Guid.Empty;
                    // }
                    return fromActions.loadDiscoveryComponentSettingsSuccess({ settings: response });
                }),
                catchError(error => of(fromActions.loadDiscoveryComponentSettingsFailure({ error })))
            ))
    ));

    commitDiscoveryComponentSettings$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.commitDiscoveryComponentSettings),
        withLatestFrom(this.store$, this.store$.pipe(select(selectCurrentDisplayedDiscoveryVersion))),
        mergeMap(([{ componentGroupUuid, settings }, storeState, version]) => this.discoveryApiService
            .commitDiscoveryComponentSettings(componentGroupUuid, {
                isActive: storeState.touchpoints.discoveryComponent.settings.isActive,
                ...settings
            }, version).pipe(
                map((response: Partial<DiscoveryComponentSettings>) => {
                    try {
                        populateDestinationsNamedRanges(response);
                    } catch (e) {
                        console.warn('Destinations named range population failed', e);
                    }
                    if (!response.integrationViewModel.signatureUserUuid) {
                        response.integrationViewModel.signatureUserUuid = Guid.Empty;
                    }
                    return fromActions.commitDiscoveryComponentSettingsSuccess({ settings: response });
                }),
                catchError(error => of(fromActions.commitDiscoveryComponentSettingFailure({ error: error?.error?.reason === 'settings.mappingViewModel.properties contains duplicate properties' ? 'Error. Duplicate properties' : 'Something went wrong' })))
            ))
    ));

    loadDiscoveryProperties$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadDiscoveryProperties),
        mergeMap(({ componentGroupUuid }) =>
            this.discoveryApiService.getPropertiesForDiscoveryComponentGroup(componentGroupUuid).pipe(
                map((response: Array<DiscoveryProperty>) => fromActions.loadDiscoveryPropertiesSuccess({ discoveryProperties: response })),
                catchError(error => of(fromActions.loadDiscoveryPropertiesFailure({ error })))
            ))
    ));

    loadDiscoveryComponentCompanyUsers$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.loadDiscoveryComponentCompanyUsers),
        mergeMap(({ companyUuid, propertyUuid }) =>
            this.discoveryApiService.getCompanyUsers(companyUuid).pipe(
                map((response: DiscoveryComponentSignatureUser[]) => {
                    const users = response
                        .filter(user => !propertyUuid || user.isGlobalAccess || !!user.properties.find(uuid => uuid === propertyUuid))
                        .map(user => {
                            return { ...user, fullName: `${user.firstName} ${user.lastName} <${user.email}>` };
                        });
                    return fromActions.loadDiscoveryComponentCompanyUsersSuccess({ users });
                }),
                catchError(error => of(fromActions.loadDiscoveryComponentSettingsFailure({ error })))
            ))
    ));

    getMissionControlDetailsSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.loadMissionControlDiscoverySettings),
            filter(() => !!this.spacesService.getPropertyUuid()),
            switchMap(() =>
                this.discoveryApiService.getDetailsSettings()
                    .pipe(
                        map(mappedSettings => fromActions.loadMissionControlDiscoverySettingsSuccess({
                            missionControlDetailsSettings: mappedSettings
                        })),
                        catchError(error => of(fromActions.loadMissionControlDiscoverySettingsFailure({ error })))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store$: Store<fromTouchpoints.State>,
        private spacesService: SpacesService,
        private discoveryApiService: DiscoveryApiService
    ) {
    }
}
