import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
    EntityDefinitionService,
    EntityMetadataMap,
    HttpUrlGenerator
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { SpacesService } from '../../../common/components/spaces';
import { Environment, ENVIRONMENT_TOKEN } from '../../../core/environment.service';
import { Admin, MissionControlSettingsDetailsDto, PropertyUserDto } from '../models';

const entityMetadata: EntityMetadataMap = {
    PropertyUser: {
        selectId: (user: PropertyUserDto) => user.Id,
    },
    Admin: {
        selectId: (admin: Admin) => admin.Id
    },
    MissionControlSettings: {
        selectId: (settings: MissionControlSettingsDetailsDto) => settings.id
    }
};

@Injectable({
    providedIn: 'root',
})
export class MissionControlSettingsDetailsService extends EntityCollectionServiceBase<MissionControlSettingsDetailsDto> {
    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                serviceElementaryFactory: EntityCollectionServiceElementsFactory,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
                eds: EntityDefinitionService,
                hug: HttpUrlGenerator) {
        eds.registerMetadataMap(entityMetadata);
        const propertyUuid = spacesService.getPropertyUuid()
        const propertyUserUrl = `${environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/users`;
        const adminUrl = `${environment.apiV1BaseUrl}api/admins`;
        hug.registerHttpResourceUrls({
            PropertyUser: {
                entityResourceUrl: propertyUserUrl,
                collectionResourceUrl: propertyUserUrl
            },
            Admin: {
                entityResourceUrl: adminUrl,
                collectionResourceUrl: adminUrl
            },
        });
        super('MissionControlSettings', serviceElementaryFactory);
    }

    getDetailsSettings(id?: string): Observable<MissionControlSettingsDetailsDto> {
        const propertyUuid = id || this.spacesService.getPropertyUuid();
        return this.http.get<MissionControlSettingsDetailsDto>(`${this.environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/settings/details`);
    }

    saveDetailsSettings(settings: Partial<MissionControlSettingsDetailsDto>) {
        const propertyUuid = this.spacesService.getPropertyUuid();
        return this.http.put<Observable<any>>(`${this.environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/settings/details`, settings);
    }

    upgradeDiscovery(): Observable<any> {
        const propertyUuid = this.spacesService.getPropertyUuid();
        return this.http.put<Observable<any>>(`${this.environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/settings/discovery/upgrade`, null);
    }

    loadDetailsSettings(id?: string): Observable<MissionControlSettingsDetailsDto> {
        const propertyUuid = id || this.spacesService.getPropertyUuid();
        this.selectDetailsSettings$(id).pipe(
            take(1),
            filter(settings => !settings),
            switchMap(() => this.http.get<MissionControlSettingsDetailsDto>(`${this.environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/settings/details`).pipe(
                take(1),
            ))
        ).subscribe(res => {
            this.addOneToCache(res);
        })
        return this.selectDetailsSettings$(id);
    }

    selectDetailsSettings$(id?: string) {
        const propertyUuid = id || this.spacesService.getPropertyUuid();
        return this.entityMap$.pipe(
            map((entityMap) => {
                return entityMap[propertyUuid.toUpperCase()];
            })
        );
    }
}
