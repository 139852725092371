import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { FriendLandingIntegrationSettings, FriendLandingSettings, FriendLandingStyleSettings } from './../../models/friend-landing';
import { JourneysApiService } from './../../services/journeys-api.service';
import {
    changeFriendLanding,
    changeFriendLandingFail,
    changeFriendLandingSuccess,
    commitFriendLanding,
    commitFriendLandingFail,
    commitFriendLandingSuccess,
    loadFriendLanding,
    loadFriendLandingFail,
    loadFriendLandingSuccess,
    loadIntegrationFriendLanding,
    loadIntegrationFriendLandingFail,
    loadIntegrationFriendLandingSuccess,
    loadStylingFriendLanding,
    loadStylingFriendLandingFail,
    loadStylingFriendLandingSuccess
} from './actions';

@Injectable()
export class FriendLandingEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadFriendLanding),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getTouchpointFriendLanding(componentGroupUuid, propertyId, typeRequest, state, 'data')
                    .pipe(
                        map((settings: FriendLandingSettings) => {
                            return loadFriendLandingSuccess({data: settings});
                        }),
                        catchError(error => of(loadFriendLandingFail({error})))
                    ))
        )
    );

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingFriendLanding),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getTouchpointFriendLanding(componentGroupUuid, propertyId, typeRequest, state, 'styling')
                    .pipe(
                        map((stylingSettings: FriendLandingStyleSettings) => {
                            return loadStylingFriendLandingSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingFriendLandingFail({error})))
                    ))
        )
    );

    loadIntegration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadIntegrationFriendLanding),
            mergeMap(({componentGroupUuid, propertyId, typeRequest, state}) =>
                this.journeysApiService.getTouchpointFriendLanding(componentGroupUuid, propertyId, typeRequest, state, 'integration')
                    .pipe(
                        map((integrationSettings: FriendLandingIntegrationSettings) => {
                            return loadIntegrationFriendLandingSuccess({data: integrationSettings});
                        }),
                        catchError(error => of(loadIntegrationFriendLandingFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitFriendLanding),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointConfirmationSettings(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: FriendLandingSettings) => {
                            return commitFriendLandingSuccess({data: settings});
                        }),
                        catchError(error => of(commitFriendLandingFail({error})))
                    ))
        )
    );

    changeConfirmationExperience$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeFriendLanding),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, typeRequest, state, changes, tab }]) => this.journeysApiService.saveTouchpointFriendLanding(
                    componentGroupUuid, propertyId, typeRequest, state, changes, tab
                ).pipe(map(() => {
                    return changeFriendLandingSuccess({ changes });
                }),
                    catchError(error => {
                        return of(changeFriendLandingFail(error));
                    })
                )
            )
        )
    );
}
