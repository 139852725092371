import { Injectable } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { dateToNgbDate } from './custom-ngb-adapter';

export const ngbDateToDateString = (date: NgbDate) => {
    if (!date) {
        return null;
    }

    const year = date.year;
    const month = ('0' + date.month).slice(-2);
    const day = ('0' + date.day).slice(-2);
    return `${year}-${month}-${day}`;
};


/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class NgbCustomFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct | null {
        return dateToNgbDate(new Date(value));
    }

    format(date: NgbDateStruct | null): string {
        return ngbDateToDateString(date as NgbDate);
    }
}
