/**
 * Created by roman.kupin on 1/29/2018.
 */


import * as CampaignContainerComponent from "./campaignContainer.component";
import { CampaignContainerService } from "./campaignContainer.service";


declare let angular;
export const CAMPAIGN_CONTAINER_MODULE = angular.module("AccountApp.v2.Components.CampaignContainer", []);

export class ComponentConfig {

    static $inject = ["$compileProvider", "$provide"];

    constructor($compileProvider, $provide) {
        $compileProvider.directive(CampaignContainerComponent.NAME, CampaignContainerComponent.DEFINITION);
        $provide.service(CampaignContainerService.Name, CampaignContainerService);
    }
}

CAMPAIGN_CONTAINER_MODULE.config(ComponentConfig);