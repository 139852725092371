import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ShowcaseStoriesService } from 'account-hybrid/common/components/showcase-stories/services/showcase-stories.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { loadRandomShowcasePhotos, loadRandomShowcasePhotosFailure, loadRandomShowcasePhotosSuccess } from '../actions';

@Injectable()
export class ShowcaseStoriesEffects {
    loadRandomShowcasePhotos$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadRandomShowcasePhotos),
            map(action => action?.companyUuid),
            switchMap(companyUuid =>
                this.showcaseStoriesService.getRandomPhotos(companyUuid)
                    .pipe(
                        map(photos => loadRandomShowcasePhotosSuccess({ photos })),
                        catchError(error => of(loadRandomShowcasePhotosFailure({ error })))
                    )
            )
        )
    );

    constructor(private actions$: Actions, private showcaseStoriesService: ShowcaseStoriesService) {
    }
}
