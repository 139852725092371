import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import {
    selectDiscoveryPropertySettingsCommitSuccess,
    selectDiscoveryPropertySettingsEmail,
    selectDiscoveryPropertySettingsError,
    selectDiscoveryPropertySettingsLoading
} from 'account-hybrid/common/components/touchpoints/store';
import {
    loadDiscoveryPropertySettings,
    saveDiscoveryPropertySettings
} from 'account-hybrid/common/components/touchpoints/store/property-settings/property-settings.actions';
import { DiscoveryEmailScheduleDto, DiscoveryPropertySettingsDto } from 'libs/api/data-contracts';
import { Observable } from 'rxjs';

@Component({
    selector: 'ft-re-engagement-email',
    templateUrl: './re-engagement-email.component.html'
})
export class ReEngagementEmailComponent implements OnInit {
    email: string;
    title = 'Re-engagement Email';
    emailSettings$: Observable<DiscoveryEmailScheduleDto>;
    commitSuccess$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    error$: Observable<any>;
    isReminderEmail = false;

    constructor(
      private store$: Store<any>,
      private spacesService: SpacesService,
      private transition: Transition
    ) {
    }

    ngOnInit(): void {
        this.email = this.transition.params().email;
        if (this.email === 'reengagementreminder') {
            this.title += ' Reminder';
            this.isReminderEmail = true;
        } else {
            this.title = 'Re-engagement Email';
            this.isReminderEmail = false;
        }

        this.store$.dispatch(loadDiscoveryPropertySettings({ propertyUuid: this.spacesService.getPropertyUuid() }));
        this.emailSettings$ = this.store$.pipe(select(selectDiscoveryPropertySettingsEmail));
        this.isLoading$ = this.store$.pipe(select(selectDiscoveryPropertySettingsLoading));
        this.commitSuccess$ = this.store$.pipe(select(selectDiscoveryPropertySettingsCommitSuccess));
        this.error$ = this.store$.pipe(select(selectDiscoveryPropertySettingsError));
    }

    commit(settings: Partial<DiscoveryPropertySettingsDto>) {
        this.store$.dispatch(saveDiscoveryPropertySettings({ settings }));
    }

    isEmailDisabled(value?: number): boolean {
        return value < 0;
    }
}
