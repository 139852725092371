import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LicenseType, SessionService } from 'account-hybrid/core/authentication';
import { CheckLicenseOperator, LicenseService } from 'account-hybrid/core/license.service';


@UntilDestroy({ checkProperties: true })
@Directive({
    selector: '[ftCheckLicense]'
})
export class CheckLicenseDirective implements OnInit {

    license: string;
    operator: CheckLicenseOperator;
    defaultOperator: CheckLicenseOperator = 'HAS';

    constructor(private licenseService: LicenseService,
        private sessionService: SessionService,
        private templateRef: TemplateRef<any>,
        private vc: ViewContainerRef) {
    }

    @Input() set ftCheckLicense(license: string) {
        this.license = license;
        this.updateView();
    }

    @Input() set ftCheckLicenseOperator(operator: CheckLicenseOperator) {
        this.operator = operator;
        this.updateView();
    }

    private updateView() {
        if (!this.license) return;
        this.vc.clear();
        if (this.licenseService.checkCurrentSpaceLicense(LicenseType[this.license], this.operator || this.defaultOperator)) {
            this.vc.createEmbeddedView(this.templateRef);
        } else {
            this.vc.clear();
        }
    }

    ngOnInit() {
        this.sessionService.licenseChanged$
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateView());
    }
}
