/**
 * Created by roman.kupin on 3/16/2018.
 */


import * as CreateContestModalComponent from "./createContestModal.component";
import { CreateContestModalService } from "./createContestModal.service";

declare let angular;
export const CREATE_CONTEST_MODAL_MODULE = angular.module("AccountApp.v2.Components.CreateContestModal", []);

export class ComponentConfig {

    static $inject = ["$compileProvider", "$provide"];

    constructor($compileProvider, $provide) {
        $compileProvider.component(CreateContestModalComponent.NAME, CreateContestModalComponent.OPTIONS);
        $provide.service(CreateContestModalService.Name, CreateContestModalService);
    }
}

CREATE_CONTEST_MODAL_MODULE.config(ComponentConfig);