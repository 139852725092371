import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { ChecklistRule } from "./models/checklist-rule";

@Component({
    selector: 'ft-validation-checklist',
    templateUrl: './validation-checklist.component.html',
    styleUrls: ['./validation-checklist.component.scss']
})
export class ValidationChecklistComponent implements DoCheck {
    @Input() rules: ChecklistRule[] = [];
    @Input() title: string;
    @Input() summary: string;
    @Input() isValid: boolean
    @Output() isValidChange = new EventEmitter<boolean>();

    ngDoCheck() {
        this.checkIsValid();
    }

    checkIsValid() {
        const isValid = this.rules?.every(rule => rule?.isValid) || false;
        if (this.isValid !== isValid) {
            this.isValidChange.emit(isValid);
        }
        this.isValid = isValid
    }
}
