import { createAction, props } from '@ngrx/store';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import { DiscoveryPropertySettingsDto } from 'libs/api/data-contracts';

export const loadDiscoveryPropertySettings = createAction(
    '[DiscoveryPropertySettings] Load DiscoveryPropertySettings',
    props<{
        propertyUuid: string,
        version?: DiscoveryVersions
    }>()
);
export const loadDiscoveryPropertySettingsSuccess = createAction(
    '[DiscoveryPropertySettings] Load DiscoveryPropertySettings Success',
    props<{
        settings: DiscoveryPropertySettingsDto;
    }>()
);
export const loadDiscoveryPropertySettingsFailure = createAction(
    '[DiscoveryPropertySettings] Load DiscoveryPropertySettings Failure',
    props<{
        error: any
    }>()
);

export const saveDiscoveryPropertySettings = createAction(
    '[DiscoveryPropertySettings] Save DiscoveryPropertySettings',
    props<{
        settings: Partial<DiscoveryPropertySettingsDto>,
        version?: DiscoveryVersions
    }>()
);
export const saveDiscoveryPropertySettingsSuccess = createAction(
    '[DiscoveryPropertySettings] Save DiscoveryPropertySettings Success',
    props<{
        settings: DiscoveryPropertySettingsDto;
    }>()
);
export const saveDiscoveryPropertySettingsFailure = createAction(
    '[DiscoveryPropertySettings] Save DiscoveryPropertySettings Failure',
    props<{
        error: any
    }>()
);
