import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { Language } from 'account-hybrid/common/models/language.model';
import { selectActiveLanguages } from 'account-hybrid/common/modules/account-management/store';

interface LanguageItem {
    [key: string]: any;
    languageCode: string;
    languageName?: string;
}

type LanguageDefaultFunc = (languageCode: string, item?: LanguageItem) => LanguageItem;

interface LanguageDataPipeOptions {
    defaults?: object;
    defaultsFunc?: LanguageDefaultFunc;
    copy?: boolean;
}

/**
 * Pipe mutates original data, be careful using together with language
 * variations approach
 */
@UntilDestroy()
@Pipe({
    name: 'languageData'
})
export class LanguageDataPipe implements PipeTransform {
    languages: Language[];

    constructor(private store: Store, private cdr: ChangeDetectorRef) {
        this.setLanguages();
    }

    setLanguages() {
        this.store.pipe(select(selectActiveLanguages))
            .pipe(untilDestroyed(this))
            .subscribe((languages) => {
                this.languages = languages;
                this.cdr.markForCheck();
            });
    }

    transform(item: any | any[], languageDataPath: string | string[] = 'languageData', options: LanguageDataPipeOptions = {}): any | any[] {
        if (!item) {
            return item;
        }
        if (typeof languageDataPath === 'string') {
            if (Array.isArray(item)) {
                item = item.map((dataItem) => {
                    dataItem[languageDataPath] = this.updateLanguageData(dataItem[languageDataPath], options);
                    return dataItem;
                });
            } else {
                item[languageDataPath] = this.updateLanguageData(item[languageDataPath], options);
            }
        } else if (Array.isArray(languageDataPath)) {
            if (Array.isArray(item)) {
                item = item.map((dataItem) => this.deepUpdateLanguageData(dataItem, languageDataPath, 0, options));
            } else {
                item = this.deepUpdateLanguageData(item, languageDataPath, 0, options);
            }
        }
        return item;
    }

    private deepUpdateLanguageData(data: any, fullPath: string[], index: number, options: LanguageDataPipeOptions = {}): any {
        if (fullPath.length === index + 1) {
            if (Array.isArray(data)) {
                return data.map((dataItem) => {
                    dataItem[fullPath[index]] = this.updateLanguageData(dataItem[fullPath[index]], options);
                    return dataItem;
                });
            } else {
                data[fullPath[index]] = this.updateLanguageData(data[fullPath[index]], options);
                return data;
            }
        } else {
            if (Array.isArray(data[fullPath[index]])) {
                data[fullPath[index]] = data[fullPath[index]].map((dataItem) => this.deepUpdateLanguageData(dataItem, fullPath, index + 1, options))
                return data;
            } else {
                data[fullPath[index]] = this.deepUpdateLanguageData(data, fullPath, index + 1, options);
                return data;
            }
        }
    }

    private updateLanguageData(languageData: LanguageItem[], options: LanguageDataPipeOptions = {}): LanguageItem[] {
        if (languageData?.length) {
            languageData = this.languages.map(({ languageCode, languageName }) => {
                const languageDataItem = languageData.find((item) => item?.languageCode === languageCode) || {};
                const languageDataDefaults = this.createDefaultLanguageData(languageCode, languageData[0], options);
                return {
                    languageCode,
                    languageName,
                    ...languageDataDefaults,
                    ...languageDataItem
                };
            });
        } else {
            languageData = this.languages.map(language => {
                const languageDataDefaults = this.createDefaultLanguageData(language.languageCode, null, options);
                return {
                    ...languageDataDefaults,
                    languageCode: language.languageCode,
                    languageName: language.languageName
                };
            });
        }
        return languageData;
    }

    private createDefaultLanguageData(languageCode: string, item: LanguageItem, options: LanguageDataPipeOptions = {}): LanguageItem {
        const defaults = options.defaultsFunc ? options.defaultsFunc(languageCode, item) : (options.defaults || {});
        Object.keys(item || {}).forEach((key) => {
            if (!['languageName', 'languageCode'].includes(key) && !defaults.hasOwnProperty(key)) {
                defaults[key] = options.copy ? item[key] : null;
            }
        });
        return defaults as LanguageItem;
    }

}
