import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AreasRegistryContext } from './areas-registry-context';
import { AreasDirective } from './areas.directive';
import {
    AREAS_REGISTRY,
    AreasRegistry,
    HandlerRegistryItem,
    MultipleOptionsRegistryItem,
    RegistryItem,
    StateRegistryItem
} from "./areas.service";

@NgModule({
    declarations: [AreasDirective],
    imports: [
        CommonModule
    ],
    exports: [AreasDirective]
})
class AreasModule { }

export {
    AREAS_REGISTRY, AreasDirective, AreasModule, AreasRegistry, AreasRegistryContext, HandlerRegistryItem, MultipleOptionsRegistryItem, RegistryItem, StateRegistryItem
};

