

import { environment } from 'account-hybrid-environments/environment';
import { cacheInterceptor } from "../interceptors/cache.interceptor";
import { forbiddenInterceptor } from "../interceptors/forbidden.interceptor";
import { tokenInjectorInterceptor } from "../interceptors/tokenInjector.interceptor";
import { unAuthorizedInterceptor } from "../interceptors/unAuthorized.interceptor";
import { v1ApiUrlInterceptor } from "../interceptors/v1ApiUrl.interceptor";
import { delayedResultMiddleware } from "../middleware/delayedResult.middleware";

export class ServiceConfig {

    static $inject = ["$provide"];

    constructor($provide) {
        $provide.constant("env", environment);
        $provide.factory("delayedResultMiddleware", delayedResultMiddleware);
        $provide.factory("unAuthorizedInterceptor", unAuthorizedInterceptor);
        $provide.factory("forbiddenInterceptor", forbiddenInterceptor);
        $provide.factory("tokenInjectorInterceptor", tokenInjectorInterceptor);
        $provide.factory("v1ApiUrlInterceptor", v1ApiUrlInterceptor);
        $provide.factory("cacheInterceptor", cacheInterceptor);
    }
}