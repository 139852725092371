import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { Observable } from 'rxjs';
import { StoryExplorerStylingSettings } from '../../models/story-explorer';
import * as fromTouchpoints from '../../store';
import { commitStoryExplorer, loadStoryExplorer, loadStylingStoryExplorer, saveStoryExplorer } from '../../store/story-explorer';
import { StoryExplorerIntegrationSettings } from './../../models/story-explorer';

@Component({
  selector: 'app-story-explorer',
  templateUrl: './story-explorer.component.html',
  styleUrls: ['./story-explorer.component.scss']
})
export class StoryExplorerComponent implements OnInit {
  propertyId: string;
  componentGroupUuid: string;
  selectedIndex = 0;
  slug: string;

  selectedStoryExplorerComponent$: Observable<StoryExplorerStylingSettings>;
  stylingStoryExplorerComponent$: Observable<StoryExplorerStylingSettings>;
  integrationStoryExplorerComponent$: Observable<StoryExplorerIntegrationSettings>;
  selectedStoryExplorerLoading$: Observable<boolean>;
  rateCodes$: Observable<string[]>;
  commitSuccess$: Observable<any>;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.slug = this.transition.params().slug;

    this.store$.dispatch(loadStoryExplorer({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));
    this.store$.dispatch(loadStylingStoryExplorer({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));

    this.stylingStoryExplorerComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingStoryExplorerData));
    this.integrationStoryExplorerComponent$ = this.store$.pipe(select(fromTouchpoints.getStoryExplorerIntegrationData));
    this.selectedStoryExplorerLoading$ = this.store$.pipe(select(fromTouchpoints.getStoryExplorerLoading));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getStoryExplorerCommitSuccess));
  }

  commit(isActive) {
    this.store$.dispatch(commitStoryExplorer({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params, tab) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    this.store$.dispatch(saveStoryExplorer({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      changes: changes,
      tab: tab
    }));
  }
}
