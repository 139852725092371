import { createAction, props } from '@ngrx/store';
import { PoststayProcessSettings } from '../../models/poststay-process';

export const loadPoststayProcess = createAction('[Post-stay process] load',
props<{
  componentGroupUuid: string,
  propertyId: string
}>());

export const loadPoststayProcessSuccess = createAction('[Post-stay process] loadSuccess', props<{ data: PoststayProcessSettings }>());
export const loadPoststayProcessFail = createAction('[Post-stay process] loadFail', props<{ error: any }>());

// save changes toogle
// export const commitBannerEmail = createAction('[Banner Email] commit',
// props<{
//   componentGroupUuid: string,
//   propertyId: string,
//   isActive: boolean
// }>());
// export const commitBannerEmailSuccess = createAction('[Banner Email] commitSuccess', props<{ data: BannerEmailSettings }>());
// export const commitBannerEmailFail = createAction('[Banner Email] commitFail', props<{ error: any }>());

//styling
// export const loadStylingBannerEmail = createAction('[Banner Email] load styling',
// props<{
//     componentGroupUuid: string,
//     propertyId: string,
//     typeRequest: any,
//     state: any
// }>());

// export const loadStylingBannerEmailSuccess = createAction('[Banner Email] styling loadSuccess', props<{ data: BannerEmailStylingSettings }>());
// export const loadStylingBannerEmailFail = createAction('[Banner Email] styling loadFail', props<{ error: any }>());
