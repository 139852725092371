import { BooleanUtil } from '@flipto/shared/src/lib/utils/common/types/boolean.util';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    sortDefaultAccountLanguages
} from '../../../../../../account-shared/utils/account-langauge.util';
import { AccountManagementState } from '../../store';
import { ACCOUNT_MANAGEMENT_FEATURE_NAME } from '../../store/feature-name.constant';

export const selectAccountManagementMapState = createFeatureSelector<AccountManagementState>(ACCOUNT_MANAGEMENT_FEATURE_NAME);

export const selectAccountLanguagesState = createSelector(
    selectAccountManagementMapState,
    state => state.languages
);

export const selectInstalledAccountLanguages = createSelector(
    selectAccountLanguagesState,
    state => state.languages?.map(language => {
        const foundAvailableLanguage = (state?.availableLanguages as any[])?.find(item => ((item.hasOwnProperty('languageCode') && language.hasOwnProperty('languageCode')) && item?.languageCode === language?.languageCode) || (item.hasOwnProperty('code') && language.hasOwnProperty('code') && item?.code === language?.code));
        return {
            ...language,
            languageName: foundAvailableLanguage?.languageName || foundAvailableLanguage?.name || language?.languageName || language?.name
        };
    })
);

export const selectActiveLanguages = createSelector(
    selectInstalledAccountLanguages,
    (languages) => {
        return sortDefaultAccountLanguages(languages?.filter(language => BooleanUtil.toBoolean(language.isActive)) || []);
    }
);

export const selectAllAvailableAccountLanguages = createSelector(
    selectAccountLanguagesState,
    state => state.availableLanguages
);

export const selectAvailableAccountLanguages = createSelector(
    selectAccountLanguagesState,
    state => (state.availableLanguages as any[])?.filter(language => !(state?.languages as any[])?.find(item => item.languageCode === language.languageCode))
);

export const selectAccountLanguagesIsLoading = createSelector(
    selectAccountLanguagesState,
    state => state.loading
);
