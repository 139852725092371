/**
 * Created by roman.kupin on 1/30/2018.
 */

import { HomepageTouchpointsNames, PhotoAdvocacyTouchpointsNames, PoststayTouchpointsNames, PrestayTouchpointsNames, QuoteListTouchpointsNames } from "./touchpoints";

export function resolveLayer(touchpointName) {
    if (Object.values(PrestayTouchpointsNames).map(v => v.toLowerCase()).includes(touchpointName.toLowerCase()))
        return "pre-stay";

    if (Object.values(PoststayTouchpointsNames).map(v => v.toLowerCase()).includes(touchpointName.toLowerCase()))
        return "post-stay";

    if (Object.values(PhotoAdvocacyTouchpointsNames).map(v => v.toLowerCase()).includes(touchpointName.toLowerCase()))
        return "photo-advocacy";

    if (Object.values(HomepageTouchpointsNames).map(v => v.toLowerCase()).includes(touchpointName.toLowerCase()))
        return "homepage";

    if (Object.values(QuoteListTouchpointsNames).map(v => v.toLowerCase()).includes(touchpointName.toLowerCase()))
        return "quote-list";

    throw "Not supported touchpoint";
}

const SUFFIXES = {
    "messaging": "messages",
    "styling": "styles",
    "integration": "integration"
};

export default function resolveTempalteUrl(touchpointName, dataType) {
    if (touchpointName === PoststayTouchpointsNames.PoststayProcess && dataType !== "integration") {
        return `/app/sections/platform/layer/touchpoint/${dataType}/${dataType}-version.html`
    }
    if (touchpointName === "certificate-email") {
        return "/app/sections/platform/layer/warm-lead-nurturing/certificate-email/certificateEmail.html";
    }
    if (touchpointName === "certificate-reminder-email") {
        return "/app/sections/platform/layer/warm-lead-nurturing/reminder-email/reminderEmail.html";
    }
    return `/app/sections/platform/layer/${resolveLayer(touchpointName)}/touchpoint/${dataType}/edit-${touchpointName}-${SUFFIXES[dataType]}.html`
}