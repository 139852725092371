import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { SharedComponentsModule } from '@flipto/shared/src/lib/components/shared-components.module';
import { SharedDirectivesModule } from '@flipto/shared/src/lib/directives/shared-directives.module';
import { TokenReplacerService } from '@flipto/shared/src/lib/services/token-replacer/token-replacer.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { SwiperModule } from 'swiper/angular';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ListElementComponent } from './components/list-element/list-element.component';
import { LogoGridComponent } from './components/logo-grid/logo-grid.component';
import { RatingComponent } from './components/rating/rating.component';
import { SeasonComponent } from './components/season/season.component';
import { UnsubscribeComponent } from './components/unsubscribe/unsubscribe.component';
import { ConfirmModalComponent } from './dialogs/confirm-modal/confirm-modal.component';
import { ModalModule } from './dialogs/modal/modal.module';
import { TooltipComponent } from './dialogs/tooltip/tooltip.component';
import { TrapFocusDirective } from './dialogs/trap-focus.directive';
import { FliptoStaffOnlyDirective } from './directives/flipto-staff-only.directive';
import { ImageFallbackDirective } from './directives/image-fallback.directive';
import { MediaQueryDirective } from './directives/media-query.directive';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { ScrollToTopDirective } from './directives/scroll-to-top.directive';
import { UiViewWrapperDirective } from './directives/ui-view-wrapper.directive';
import { ClickClassDirective } from './dom/click-class.directive';
import { DisabledDirective } from './dom/disabled.directive';
import { IntersectionObservableComponent } from './dom/intersection-observer/intersection-observable.component';
import { IntersectionObserverComponent } from './dom/intersection-observer/intersection-observer.component';
import { IsVisibleDirective } from './dom/is-visible.directive';
import { ColorSwatchComponent } from './forms/color-swatch/color-swatch.component';
import { ControlPanelSearchComponent } from './forms/control-panel-search/control-panel-search.component';
import { DatePickerModule } from './forms/date-picker/date-picker.module';
import { DateRangePickerModule } from './forms/date-range-picker';
import { EditorsModule } from './forms/editors';
import { FileDownloadComponent } from './forms/file-download/file-download.component';
import { LoadingModule } from './forms/loading';
import { PasswordToggleComponent } from './forms/password-toggle/password-toggle.component';
import { PrependInputDirective } from './forms/prepend-input.directive';
import { ProgressComponent } from './forms/progress/progress.component';
import { RestrictInputDirective } from './forms/restrict-input.directive';
import { SnapshotModule } from './forms/snapshot/snapshot.module';
import { UploadersModule } from './forms/uploaders/uploaders.module';
import { ValidationChecklistModule } from './forms/validation-checklist/validation-checklist.module';
import { BackgroundImageComponent, ImagesModule } from './images';
import { AdvancedSearchModule } from './modules/advanced-search/advanced-search.module';
import { CarouselModule } from './modules/carousel';
import { StickyModule } from './modules/sticky/sticky.module';
import { TreeModule } from './modules/tree/tree.module';
import { AreasModule } from './navigation/areas/areas.module';
import { PanelsModule } from './panels/panels.module';
import { PipesModule } from './pipes/pipes.module';
import { DownloadService } from './services/download.service';
import { FileSaverService } from './services/fileSaver.service';
import { LocalStorageService } from './services/local-storage.service';


@NgModule({
    declarations: [
        IntersectionObserverComponent,
        IntersectionObservableComponent,
        ProgressComponent,
        PrependInputDirective,
        ClickClassDirective,
        RestrictInputDirective,
        UiViewWrapperDirective,
        ImageFallbackDirective,
        DisabledDirective,
        IsVisibleDirective,
        ScrollIntoViewDirective,
        TooltipComponent,
        TrapFocusDirective,
        SeasonComponent,
        FileDownloadComponent,
        PasswordToggleComponent,
        ColorSwatchComponent,
        UnsubscribeComponent,
        CarouselComponent,
        ListElementComponent,
        RatingComponent,
        MediaQueryDirective,
        LogoGridComponent,
        ControlPanelSearchComponent,
        ConfirmModalComponent,
        ScrollToTopDirective,
        FliptoStaffOnlyDirective,
    ],
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        AreasModule,
        ModalModule,
        LoadingModule,
        DateRangePickerModule,
        DatePickerModule,
        ValidationChecklistModule,
        MatIconModule,
        EditorsModule,
        ImagesModule,
        SnapshotModule,
        UploadersModule,
        TreeModule,
        PipesModule,
        StickyModule,
        PanelsModule,
        SwiperModule,
        ClickOutsideModule,
        AdvancedSearchModule,
        MatLegacyDialogModule,
        CarouselModule,
        SharedComponentsModule,
        SharedDirectivesModule
    ],
    providers: [
        DownloadService,
        FileSaverService,
        LocalStorageService,
        TokenReplacerService
    ],
    exports: [
        ModalModule,
        AreasModule,
        IntersectionObserverComponent,
        IntersectionObservableComponent,
        BackgroundImageComponent,
        ProgressComponent,
        PrependInputDirective,
        ClickClassDirective,
        RestrictInputDirective,
        UiViewWrapperDirective,
        ScrollIntoViewDirective,
        ImageFallbackDirective,
        DisabledDirective,
        IsVisibleDirective,
        TooltipComponent,
        TrapFocusDirective,
        LoadingModule,
        DateRangePickerModule,
        DatePickerModule,
        FileDownloadComponent,
        SeasonComponent,
        PasswordToggleComponent,
        ValidationChecklistModule,
        EditorsModule,
        SnapshotModule,
        UploadersModule,
        ImagesModule,
        TreeModule,
        PipesModule,
        ModalModule,
        ColorSwatchComponent,
        StickyModule,
        PanelsModule,
        UnsubscribeComponent,
        CarouselComponent,
        ListElementComponent,
        RatingComponent,
        MediaQueryDirective,
        LogoGridComponent,
        ControlPanelSearchComponent,
        ConfirmModalComponent,
        AdvancedSearchModule,
        ScrollToTopDirective,
        FliptoStaffOnlyDirective,
        CarouselModule,
        SharedComponentsModule,
        SharedDirectivesModule
    ]
})
export class AccountSharedModule {
    constructor() {
    }
}
