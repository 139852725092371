/**
 * Created by roman.kupin on 9/21/2017.
 */

import { combineReducers } from 'redux';
import { router } from 'redux-ui-router';
import apiData from "./api-data/index";
import ui from "./ui";
import { createViewsReducer } from "./views";


export function createRootReducer(features) {
    if(!!features.length) {
        return combineReducers({
            router,
            ui,
            apiData,
            views: createViewsReducer(features)
        });
    }

    return combineReducers({
        router,
        ui,
        apiData
    });
}

