import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from "@angular/material/icon";
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from "ngx-swiper-wrapper";
import { AudienceCancellationsComponent } from './components/audience-cancellations/audience-cancellations.component';
import { AudienceOnboardComponent } from './components/audience-onboard/audience-onboard.component';
import { AudienceOnboardService } from "./services/audience-onboard.service";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    simulateTouch: false,
    navigation: {
        nextEl: '.swiper-button-next',
    },
    pagination: {
        el: '.swiper-pagination',
        bulletClass: 'swiper-pagination-rectangle',
        bulletActiveClass: 'swiper-pagination-rectangle-active',
        clickable: true
    },
    speed: 800
};

@NgModule({
    declarations: [
        AudienceOnboardComponent,
        AudienceCancellationsComponent,
    ],
    imports: [
        CommonModule,
        SwiperModule,
        MatIconModule,
        AccountSharedModule,
    ],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        AudienceOnboardService
    ],
    exports: [
        AudienceOnboardComponent,
        AudienceCancellationsComponent
    ]
})
export class AudienceOnboardModule {
}
