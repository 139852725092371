import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { SessionService } from 'account-hybrid/core/authentication';
import { Observable } from 'rxjs';
import * as fromTouchpoints from '../../store';
import { commitBannerPrearrivalEmail, loadStylingPrearrivalEmail, saveBannerPrearrivalEmail } from '../../store/banner-prearrival-email';

@Component({
  selector: 'app-banner-prearrival-email',
  templateUrl: './banner-prearrival-email.component.html',
  styleUrls: ['./banner-prearrival-email.component.scss']
})
export class BannerPrearrivalEmailComponent implements OnInit {
  propertyId:string;
  componentGroupUuid: string;
  selectedIndex = 0;
  selectedStylingBannerPrearrivalEmailComponent$: Observable<any>;
  commitSuccess$: Observable<any>;
  selectedBannerPrearrivalEmailLoading$: Observable<boolean>;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private sessionService: SessionService,
    private spacesService: SpacesService,
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;

    this.store$.dispatch(loadStylingPrearrivalEmail({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));

    this.selectedStylingBannerPrearrivalEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingBannerPrearrivalEmailData));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getBannerPrearrivalEmailCommitSuccess));
    this.selectedBannerPrearrivalEmailLoading$ = this.store$.pipe(select(fromTouchpoints.getBannerPrearrivalEmailLoading));
  }

  commit(isActive) {
    this.store$.dispatch(commitBannerPrearrivalEmail({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    this.store$.dispatch(saveBannerPrearrivalEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: 'pre-arrival-email',
      changes: changes
    }));
  }

}
