import { Component, forwardRef, Inject, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ModalService } from 'apps/account/src/account-shared';
import { map, take, tap } from 'rxjs/operators';
import { CNAME_URL_TOKEN, INJECTOR_TOKEN, URL_FORMATTER_TOKEN } from '../../../app.module';
import { PhotoContestThemes, PhotoContestThemesToInviteSuffix } from '../../../common/models/photo-contest-themes';
import { ContestInviteSetupOptions } from '../short-url-wizard/setup-options';
import { ShortUrlService } from '../short-urls.service';

declare const moment;

const enureSimpleChangeArgument = (argument: SimpleChange) => !!argument && !!argument.currentValue;

@Component({
  selector: 'ft-short-url-list',
  templateUrl: './short-url-list.component.html',
  styleUrls: ['./short-url-list.component.css'],
  inputs: [
    'themeName',
    'companyCode',
    'propertyUuid',
    'propertySlug',
    'contestUuid',
    'subdomains',
  ]
})
export class ShortUrlListComponent implements OnInit, OnChanges {

  themeName: string;
  companyCode: string;
  propertyUuid: string;
  propertySlug: string;
  contestUuid: string;
  subdomains: any;

  links: object[];
  setupOptions: ContestInviteSetupOptions;
  links$: any;

  constructor(
    private shortUrls: ShortUrlService,
    private modalService: ModalService,
    @Inject(forwardRef(() => INJECTOR_TOKEN)) private $injector,
    @Inject(forwardRef(() => CNAME_URL_TOKEN)) private cnameUrl,
    @Inject(forwardRef(() => URL_FORMATTER_TOKEN)) private urlFormatter
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: { [key in keyof ShortUrlListComponent]: SimpleChange }): void {
    if (enureSimpleChangeArgument(changes.themeName) &&
      enureSimpleChangeArgument(changes.propertyUuid) &&
      // enureSimpleChangeArgument(changes.contestUuid) &&
      enureSimpleChangeArgument(changes.subdomains)) {
      this.links$ = this.getShorUrlsForThemedContestInvite();
    }
  }

  getShorUrlsForThemedContestInvite() {
    const contestInviteUrl = this.cnameUrl(this.subdomains, 3, this.propertySlug);
    this.setupOptions = {
      companyCode: this.companyCode,
      propertyUuid: this.propertyUuid,
      contestUuid: this.contestUuid,
      theme: PhotoContestThemes[this.themeName],
      baseUrl: this.urlFormatter.combineUrlPath(contestInviteUrl, PhotoContestThemesToInviteSuffix.get(PhotoContestThemes[this.themeName]))
    } as ContestInviteSetupOptions;
    return this.shortUrls
      .getAllShortUrls(
        this.propertyUuid,
        PhotoContestThemes[this.themeName],
        this.contestUuid
      )
      .pipe(
        take(1),
        map(shortUrls => shortUrls.sort((a: any, b: any) => moment(b.createDate).unix() - moment(a.createDate).unix())),
        tap(shortUrls => shortUrls.forEach(url => url.displayName = url.displayName || '<Existing link>')),
      );
  }

  onShortUrlWizardComplete() {
    this.links$ = this.getShorUrlsForThemedContestInvite()
      .pipe(
        tap(() => {
          this.modalService.hide('photoContest.newShortUrl');
        })
      );
  }
}
