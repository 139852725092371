import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { NgbTooltipConfig, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { DiscoveryPlannerStatsComponent } from './discovery-planner-stats/discovery-planner-stats.component';
import { DiscoveryTrafficStatsComponent } from './discovery-traffic-stats/discovery-traffic-stats.component';
import { ExperimentTimelineComponent } from './experiment-timeline/experiment-timeline.component';

@NgModule({
    declarations: [
        ExperimentTimelineComponent,
        DiscoveryTrafficStatsComponent,
        DiscoveryPlannerStatsComponent],
    imports: [
        CommonModule,
        AccountSharedModule,
        FormsModule,
        MatIconModule,
        MatDialogModule,
        NgbTooltipModule,
    ],
    exports: [
        ExperimentTimelineComponent,
        DiscoveryTrafficStatsComponent,
        DiscoveryPlannerStatsComponent
    ],
})
export class CommonDiscoveryModule {
    constructor(config: NgbTooltipConfig) {
        config.triggers = 'click';
        config.autoClose = 'outside';
        config.placement = ['auto'];
    }
}
