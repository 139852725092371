import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { ClickOutsideModule } from 'ng-click-outside';
import { OrderModule } from 'ngx-order-pipe';
import { CommonDeclarationsModule } from '../../modules/common-declarations/common-declarations.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { AppMenuEntryComponent } from './components/app-menu-entry/app-menu-entry.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { AppMenuEffects } from './store/app-menu.effects';
import { appMenuReducer } from './store/app-menu.reducer';


@NgModule({
    declarations: [
        AppMenuComponent,
        AppMenuEntryComponent
    ],
	imports: [
		CommonModule,
		AccountSharedModule,
		ClickOutsideModule,
		NotificationsModule,
		CommonDeclarationsModule,
		UIRouterUpgradeModule.forChild({}),
		StoreModule.forFeature('AppMenu', appMenuReducer),
		EffectsModule.forFeature([AppMenuEffects]),
		OrderModule
	],
    exports: [
        AppMenuComponent,
        AppMenuEntryComponent
    ]
})
export class AppMenuModule {
    constructor() {
    }
}


