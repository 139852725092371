import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoadingCallbackDirective } from '../../directives/loading-callback.directive';

@UntilDestroy()
@Component({
    selector: 'ft-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    encapsulation: ViewEncapsulation.None,
    hostDirectives: [
        {
            directive: LoadingCallbackDirective
        }
    ]
})
export class LoadingComponent implements OnInit {
    @Input() loading$: Observable<boolean> | Observable<boolean>[];
    @Input() error$: Observable<any> | Observable<any>[];
    @Input() data$: Observable<any>;
    @Input() loadingText = 'Loading data...';
    @Input() errorText = 'Error processing request';
    @Input() error404Text = 'There is no data';
    @Input() inlineMode = false;
    @Output() loaded = new EventEmitter<boolean>();
    loadingSubscription$: Observable<boolean>;
    errorSubscription$: Observable<any>;

    constructor() {
    }

    ngOnInit() {
        this.handleLoading();
        this.handleError();

        if (this.loadingSubscription$) {
            this.loadingSubscription$
                .pipe(
                    filter(loading => !loading),
                    untilDestroyed(this)
                )
                .subscribe(loading => {
                    this.loaded.emit(true);
                });
        }
    }

    handleError() {
        if (Array.isArray(this.error$)) {
            this.errorSubscription$ = combineLatest(this.error$).pipe(
                map((errors: any[]) => {
                    return errors.find(error => !!error);
                })
            );
        } else {
            this.errorSubscription$ = this.error$;
        }
    }

    handleLoading() {
        if (Array.isArray(this.loading$)) {
            this.loadingSubscription$ = combineLatest(this.loading$).pipe(
                map((isLoadings: boolean[]) => {
                    if (isLoadings.some((isLoading) => isLoading === true)) {
                        return true;
                    }
                    if (isLoadings.every((isLoading) => isLoading === false)) {
                        return false;
                    }
                })
            );
        } else {
            this.loadingSubscription$ = this.loading$;
        }
    }

    isArray(object: any): boolean {
        return Array.isArray(object);
    }
}

