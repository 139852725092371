import { IComponentFlowBuilder } from "../flows";
import { ShowStoryComponentFlowBuilder } from "../flows/builders/showStoryBuilder";
import { StoryModalFlowController } from "../flows/controllers/storyModalFlowController";
import { IRenderer, PluggableElementRenderer } from "../renderer";

declare const angular;

export class StoryModalService {

    static Name = "StoryModal";

    static $inject = ["$rootScope", "$compile", "ftModal", "$injector"];

    constructor(public $rootScope,
        public $compile,
        public ftModal,
        public $injector) {
    }

    showStory(propertyUuid: string, storyUuid: string, recommendation?: any, isRecommendationExpanded?: boolean, hasOtherRecommendations?: boolean): IRenderer {
        const componentFlowBuilder: IComponentFlowBuilder = new ShowStoryComponentFlowBuilder(propertyUuid, storyUuid);
        const steps = componentFlowBuilder.createSteps();
        const template = componentFlowBuilder.createTemplate(steps);
        const scope = this.$rootScope.$new(true);

        const renderer = this.$injector.instantiate(PluggableElementRenderer, { scope });

        const contoller = this.$injector.instantiate(StoryModalFlowController, { propertyUuid, storyUuid, steps, recommendation, renderer, isRecommendationExpanded, hasOtherRecommendations });
        angular.extend(scope, { $ftFlow: contoller });

        renderer.add(template);

        return renderer;
    }


}