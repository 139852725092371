import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { PlannerflowSettings } from 'account-hybrid/common/components/touchpoints/models/plannerflow-settings';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import { MissionControlSettingsDetailsDto } from 'account-hybrid/features/mission-control/models';
import { Observable } from 'rxjs';
import { DiscoveryPropertySettingsDto } from '../../../../../../../../libs/api/data-contracts';
import { DiscoveryComponentType } from '../enums';
import { IDiscoveryInjectableComponentMessaging, IDiscoveryInjectableComponentSettings } from '../interfaces';
import { DiscoveryComponentSettings, DiscoveryComponentSignatureUser, DiscoveryProperty } from '../models';
import { ComponentSettingsBase } from '../models/component-settings-base';

@Injectable()
export class DiscoveryApiService {
    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    getDiscoveryPropertySettings(propertyUuid: string, version: DiscoveryVersions) {
        return this.http.get(`${this.environment.apiBaseUrl}/${propertyUuid}/discovery/settings/${version}`);
    }

    updateDiscoveryPropertySettings(settings: Partial<DiscoveryPropertySettingsDto>, version: DiscoveryVersions) {
        return this.http.put(`${this.environment.apiBaseUrl}/${this.spacesService.getPropertyUuid()}/discovery/settings/${version}`, settings);
    }

    getDiscoverySettings(componentGroupUuid: string, version: DiscoveryVersions) {
        const params = new HttpParams()
            .set('requestedVersion', version);
        return this.http.get<PlannerflowSettings>(`${this.environment.apiBaseUrl}/touchpoints/discovery/${componentGroupUuid}`, { params });
    }

    updateDiscoverySettings(componentGroupUuid: string, updates: any) {
        return this.http.patch<PlannerflowSettings>(`${this.environment.apiBaseUrl}/touchpoints/discovery/${componentGroupUuid}`, updates);
    }

    getComponentSettings(componentGroupUuid: string, componentName: string): Observable<ComponentSettingsBase[]> {
        return this.http.get<ComponentSettingsBase[]>(`${this.environment.apiBaseUrl}/touchpoints/discovery/${componentGroupUuid}/${componentName}`);
    }

    updateComponentSettings(componentGroupUuid: string, componentName: string, componentUuid: any, updates: any): Observable<ComponentSettingsBase> {
        return this.http.patch<ComponentSettingsBase>(`${this.environment.apiBaseUrl}/touchpoints/discovery/${componentGroupUuid}/${componentName}/${componentUuid}`, updates);
    }

    createComponent(componentGroupUuid: string, componentName: string, updates: any): Observable<ComponentSettingsBase> {
        return this.http.put<ComponentSettingsBase>(`${this.environment.apiBaseUrl}/touchpoints/discovery/${componentGroupUuid}/${componentName}`, updates);
    }

    getDiscoveryComponentSettings(componentGroupUuid: string): Observable<DiscoveryComponentSettings> {
        if (componentGroupUuid === undefined) {
            throw new Error('invalid componentGroupUuid argument passed to service call.');
        }
        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/settings`;
        return this.http.get<DiscoveryComponentSettings>(url);
    }

    getDetailsSettings(): Observable<MissionControlSettingsDetailsDto> {
        const propertyUuid = this.spacesService.getPropertyUuid();
        return this.http.get<MissionControlSettingsDetailsDto>(`${this.environment.apiV1BaseUrl}api/${propertyUuid}/properties/${propertyUuid}/settings/details`);
    }

    getCompanyUsers(companyUuid: string): Observable<DiscoveryComponentSignatureUser[]> {
        return this.http.get<DiscoveryComponentSignatureUser[]>(`${this.environment.apiBaseUrl}/${companyUuid}/users/company`);
    }

    commitDiscoveryComponentSettings(componentGroupUuid: string, settings: Partial<DiscoveryComponentSettings>, version: DiscoveryVersions): Observable<Partial<DiscoveryComponentSettings>> {
        if (componentGroupUuid === undefined) {
            throw new Error('invalid componentGroupUuid argument passed to service call.');
        }
        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/settings`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const params = new HttpParams()
            .set('requestedVersion', version);
        return this.http.put<Partial<DiscoveryComponentSettings>>(url, JSON.stringify(settings), { headers, params });
    }

    getPropertiesForDiscoveryComponentGroup(componentGroupUuid: string): Observable<Array<DiscoveryProperty>> {
        if (componentGroupUuid === undefined) {
            throw new Error('invalid componentGroupUuid argument passed to service call.');
        }
        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/properties`;
        return this.http.get<Array<DiscoveryProperty>>(url);
    }

    getDiscoveryInjectableComponentSettings(componentGroupUuid: string, type: DiscoveryComponentType, version: DiscoveryVersions): Observable<Array<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>> {
        const params = new HttpParams()
            .set('requestedVersion', version);
        if (componentGroupUuid === undefined) {
            throw new Error('invalid componentGroupUuid argument passed to service call.');
        }
        if (type === undefined) {
            throw new Error('invalid type argument passed to service call.');
        }
        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/components/${type}/settings`;
        return this.http.get<Array<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>>(url, { params });
    }

    createDiscoveryInjectableComponentSettings(componentGroupUuid: string, type: DiscoveryComponentType, settings: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>): Observable<Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>> {
        if (componentGroupUuid === undefined) {
            throw new Error('invalid componentGroupUuid argument passed to service call.');
        }
        if (type === undefined) {
            throw new Error('invalid type argument passed to service call.');
        }

        let envelope = {};
        if (type === DiscoveryComponentType.BookingBar) {
            envelope = {
                bookingBarSettings: settings
            };
        } else if (type === DiscoveryComponentType.BookingButton) {
            envelope = {
                bookingButtonSettings: settings
            };
        } else if (type === DiscoveryComponentType.DiscoveryBar) {
            envelope = {
                discoveryBarSettings: settings
            };
        }

        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/component/${type}/settings`;
        return this.http.post<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>(url, envelope);
    }

    commitDiscoveryInjectableComponentSettings(componentGroupUuid: string, type: DiscoveryComponentType, settings: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>, version: DiscoveryVersions): Observable<Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>> {
        if (componentGroupUuid === undefined) {
            throw new Error('invalid componentGroupUuid argument passed to service call.');
        }
        if (!!settings && !settings.componentUuid) {
            throw new Error('invalid settings argument passed to service call.');
        }
        if (type === undefined) {
            throw new Error('invalid type argument passed to service call.');
        }
        const params = new HttpParams()
            .set('requestedVersion', version);

        let envelope = {};
        if (type === DiscoveryComponentType.BookingBar) {
            envelope = {
                bookingBarSettings: settings
            };
        } else if (type === DiscoveryComponentType.BookingButton) {
            envelope = {
                bookingButtonSettings: settings
            };
        } else if (type === DiscoveryComponentType.DiscoveryBar) {
            envelope = {
                discoveryBarSettings: settings
            };
        }

        const url = `${this.environment.apiBaseUrl}/discovery/${componentGroupUuid}/component/${type}/settings`;
        return this.http.put<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>(url, envelope, { params });
    }
}
