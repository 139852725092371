import { Injectable } from '@angular/core';
import { MixedSpace, SpacesService, SpaceType } from 'account-hybrid/common/components/spaces';
import { Permissions, SessionService, UserType } from '..';
import { hasPermissionInCompany, hasPermissionInProperty } from './session.service';


@Injectable({
    providedIn: 'root'
})
export class IdentityService {

    constructor(private sessionService: SessionService,
        private spacesService: SpacesService) {
    }

    isAnonymous(): boolean {
        return !this.sessionService.get();
    }

    isFliptoStaff() {
        const session = this.sessionService.get();
        return !!session && session.UserType === UserType.Admin;
    }

    isAdmin() {
        const session = this.sessionService.get();
        return !!session && session.UserType === UserType.Admin;
    }

    get user() {
        const userSession = this.sessionService.get();

        return {
            id: userSession.Id,
            firstName: userSession.FirstName,
            lastName: userSession.LastName,
            email: userSession.Email,
            profileImageUrl: userSession.ProfileImageUrl,
            isForceChangePassword: userSession.IsForceChangePassword
        };
    }

    getUser() {
        return this.user;
    }


    hasPermission(requiredPermissions): boolean {
        if (this.isAnonymous() || !this.spacesService.current) {
            return false;
        }
        const space = this.spacesService.current;
        switch (space.type) {
            case SpaceType.Company:
            case SpaceType.Collaborative: {
                return hasPermissionInCompany(space.id, requiredPermissions);
            }
            case SpaceType.Property: {
                return hasPermissionInProperty(space.id, requiredPermissions);
            }
            case SpaceType.Mixed: {
                return hasPermissionInCompany((space as MixedSpace).company.id, requiredPermissions) ||
                    hasPermissionInProperty((space as MixedSpace).property.id, requiredPermissions);
            }
            default:
                return false;
        }

    }

    hasPiiPermissions() {
        return this.hasPermission(Permissions.PersonallyIdentifiableInformation);
    }

    hasPeoplePermissions() {
        return this.hasPermission(Permissions.PeopleRead);
    }

}
