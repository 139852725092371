import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { JourneysApiService } from '../../services/journeys-api.service';
import { BannerPrearrivalEmailSettings } from './../../models/banner-email';
import {
    commitBannerPrearrivalEmail,
    commitBannerPrearrivalEmailFail,
    commitBannerPrearrivalEmailSuccess,
    loadStylingPrearrivalEmail,
    loadStylingPrearrivalEmailFail,
    loadStylingPrearrivalEmailSuccess,
    saveBannerPrearrivalEmail,
    saveBannerPrearrivalEmailFail,
    saveBannerPrearrivalEmailSuccess,
} from './actions';

@Injectable()
export class BannerPrearrivalEmailEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingPrearrivalEmail),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getTouchpointStylingBannerPrearrivalEmailData(componentGroupUuid, propertyId)
                    .pipe(
                        map((stylingSettings: any) => {
                            return loadStylingPrearrivalEmailSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingPrearrivalEmailFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitBannerPrearrivalEmail),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointBannerEmail(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: BannerPrearrivalEmailSettings) => {
                            return commitBannerPrearrivalEmailSuccess({data: settings});
                        }),
                        catchError(error => of(commitBannerPrearrivalEmailFail({error})))
                    ))
        )
    );

    saveChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveBannerPrearrivalEmail),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, typeRequest, changes }]) => this.journeysApiService.saveTouchpointBannerEmail(
                    componentGroupUuid, propertyId, typeRequest, changes
                ).pipe(map(() => {
                    return saveBannerPrearrivalEmailSuccess();
                }),
                    catchError(error => {
                        return of(saveBannerPrearrivalEmailFail(error));
                    })
                )
            )
        )
    );
}
