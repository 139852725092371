import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthError } from "../../models/auth-error";
import { ForgotPasswordStates } from '../../models/forgot-password-states.enum';
import * as fromAuthentication from '../../store';

@Component({
    selector: 'ft-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
    loading$: Observable<boolean>;
    state$: Observable<ForgotPasswordStates>;
    error$: Observable<AuthError>;
    sentNew$: Observable<boolean>;
    forgotPasswordStates = ForgotPasswordStates;
    userEmail: string;

    constructor(private store: Store<fromAuthentication.AuthenticationState>) {
        this.loading$ = this.store.pipe(select(fromAuthentication.isForgotPasswordLoading));
        this.state$ = this.store.pipe(select(fromAuthentication.getForgotPasswordState));
        this.error$ = this.store.pipe(select(fromAuthentication.getForgotPasswordError));
        this.sentNew$ = this.store.pipe(select(fromAuthentication.getForgotPasswordSentNew));
    }

    sendForgotPasswordEmail() {
        this.store.dispatch(fromAuthentication.sendForgotPasswordEmail({data: {email: this.userEmail}}));
    }

    changeState(state: ForgotPasswordStates) {
        this.store.dispatch(fromAuthentication.setForgotPasswordState({forgotPasswordState: state}));
    }
}
