import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ft-intersection-observer,[ft-intersection-observer]',
  template: "<ng-content></ng-content>",
  exportAs: "IntersectionObserver"
})
export class IntersectionObserverComponent implements OnInit, OnDestroy {

  readonly isApiSupported = typeof IntersectionObserver !== "undefined";

  i = 0;
  observer;
  callbacksMap = {};

  @Output() onLastObservableIntersecting = new EventEmitter();

  constructor(private elem: ElementRef) {
   }

  ngOnInit() {
    this.createObserver(
      {
        root: this.elem.nativeElement,
        rootMargin: "0px",
        threshold: [0.1]
      }
    )
  }

  ngOnDestroy(): void {
    if (!!this.observer) this.observer.disconnect();
  }

  addObservable(observableElement, callback) {
    if (!this.isApiSupported) {
      callback(true);
      return;
    }
    observableElement.setAttribute("ft-iobs-idx", this.i);
    this.observer.observe(observableElement);
    this.callbacksMap[this.i] = callback;
    this.i++;
  };

  createObserver(options) {
    if (!this.isApiSupported) return;
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        var idx = parseInt(entry.target.getAttribute("ft-iobs-idx"), 10);
        var callback = this.callbacksMap[idx];
        callback(entry.isIntersecting);
        if (entry.isIntersecting && idx === this.i - 1) this.onLastObservableIntersecting.emit()
      });
    }, options);
  }

}
