import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'ft-sidebar-tab',
    template: `
        <ng-template>
            <ng-content></ng-content>
        </ng-template>
    `,
    styleUrls: ['./sidebar-tab.component.scss']
})
export class SidebarTabComponent implements AfterViewInit {
    @Input() name: string;
    @Input() sectionName: string;
    @ViewChild(TemplateRef, {static: false}) template: TemplateRef<any>;
    @Output() loadingEvent = new EventEmitter<boolean>();

    constructor() {
    }

    ngAfterViewInit() {
        this.loadingEvent.emit(true);
    }

}
