 import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
    DiscoveryEmailStats,
    DiscoveryEmailStatsResponse,
    DiscoveryTrafficStats,
    DiscoveryTrafficStatsResponse,
    PlannerStats
} from 'account-hybrid/common/components/discovery/interfaces';
import {
    DiscoveryConversionReport
} from 'account-hybrid/common/components/discovery/models';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromComponentGroups from '../components/component-groups/store/index';
import { experimentsFactory } from '../models/experiment-factory.model';
import { experimentStatsFactory } from '../models/experiment-stats-factory.model';
import { ExperimentStatsResponse } from '../models/experiment-stats.model';
import { ExperimentResponse } from '../models/experiment.model';
import { DiscoveryVersioningService } from '../services/discovery-versioning.service';
import { ExperimentService } from '../services/experiment.service';
import * as fromDiscovery from '../store/index';
import {
    combineTrafficAndPlannerStats,
    loadDiscoveryConversionReport,
    loadDiscoveryConversionReportFailuire,
    loadDiscoveryConversionReportSuccess,
    loadDiscoveryVersioning,
    loadDiscoveryVersioningFailure,
    loadDiscoveryVersioningSuccess,
    loadEmailStats,
    loadEmailStatsFailure,
    loadEmailStatsSuccess,
    loadExperiments,
    loadExperimentsFailure,
    loadExperimentsSuccess,
    loadExperimentStats,
    loadExperimentStatsFailure,
    loadExperimentStatsSuccess,
    loadPerformanceStatsFailure,
    loadPerformanceStatsSuccess,
    loadPlannerStats,
    loadPlannerStatsFailure,
    loadPlannerStatsSuccess,
    loadPlannerStatsYTD,
    loadPlannerStatsYTDFailure,
    loadPlannerStatsYTDSuccess,
    loadTimelineStats,
    loadTimelineStatsFailure,
    loadTimelineStatsSuccess,
    loadTrafficStats,
    loadTrafficStatsFailure,
    loadTrafficStatsSuccess, setPerformanceSectionDateRange,
} from './discovery.actions';


function calcMonthlyProjectedValue(value: number, duration: number, percentage: number): number {
    return percentage === 0 ? 0 : Math.round(30 * value / (duration * percentage / 100));
}


function calcPercent(share: number, total: number): number {
    return total === 0 ? 0 : (100 * share / total);
}

@Injectable()
export class DiscoveryEffects {
    loadExperiments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadExperiments),
            withLatestFrom(
                this.store.pipe(select(fromComponentGroups.getSelectedComponentGroup))
            ),
            filter(([, componentGroup]) => {
                return !!componentGroup;
            }),
            switchMap(([action, componentGroup]) => this.experimentService.getExperiments(componentGroup.uuid).pipe(
                    map((experiments: ExperimentResponse[]) => {
                        return loadExperimentsSuccess({ experiments: experimentsFactory.createExperiments(experiments) });
                    }),
                    catchError(error => {
                        return of(loadExperimentsFailure({ error }));
                    })
                )
            )
        )
    );

    loadDiscoveryVersioning$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDiscoveryVersioning),
            map(action => action.propertyUuid),
            switchMap(propertyUuid =>
                this.discoveryVersioningService.getDiscoveryVersioning(propertyUuid)
                    .pipe(
                        map(versioning => loadDiscoveryVersioningSuccess({ versioning })),
                        catchError(error => of(loadDiscoveryVersioningFailure({ error })))
                    )
            )
        )
    );

    loadExperimentStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadExperimentStats),
            withLatestFrom(
                this.store.pipe(select(fromComponentGroups.getSelectedComponentGroup))
            ),
            switchMap(
                ([{ dateRange }, componentGroup]) => this.experimentService.getStats(componentGroup.uuid, dateRange)
                    .pipe(
                        filter((stats: ExperimentStatsResponse[]) => {
                            return !!stats.length;
                        }),
                        map((stats: ExperimentStatsResponse[]) => {
                            return loadExperimentStatsSuccess({ stats: experimentStatsFactory.createStatsArray(stats) });
                        }),
                        catchError(error => {
                            return of(loadExperimentStatsFailure({ error }));
                        })
                    )
            )
        )
    );

    onSetPerformanceSectionDateRange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setPerformanceSectionDateRange),
            map(action => action.performanceDateRange),
            switchMap(dateRange =>
                this.experimentService.getPropertiesPerformanceStats(dateRange)
                    .pipe(
                        map(response => loadPerformanceStatsSuccess({ performanceStats: response })),
                        catchError(error => of(loadPerformanceStatsFailure({ error })))
                    )
            )
        )
    );

    loadDiscoveryConversionReport$ = createEffect(() => this.actions$.pipe(
        ofType(loadDiscoveryConversionReport),
        withLatestFrom(this.store.pipe(select(fromDiscovery.getDateRange))),
        switchMap(([{ componentGroupUuid }, dateRange]) =>
            this.experimentService.getDiscoveryConversionReport(componentGroupUuid, dateRange).pipe(
                map((conversionReport: DiscoveryConversionReport) => loadDiscoveryConversionReportSuccess({ conversionReport })),
                catchError(error => of(loadDiscoveryConversionReportFailuire({ error })))
            )
        )
    ));

    // new discovery

    loadTimelineStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTimelineStats),
            withLatestFrom(
                this.store.pipe(select(fromDiscovery.getYTDDateRange))
            ),
            filter(([, dateRange]) => !!dateRange),
            switchMap(
                ([, dateRange]) => this.experimentService.getTimelineStats(dateRange)
                    .pipe(
                        map((stats) => loadTimelineStatsSuccess({ timelineStats: stats })),
                        catchError(error => {
                            return of(loadTimelineStatsFailure(error));
                        })
                    )
            )
        )
    );

    loadPlannerStatsYTD$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPlannerStatsYTD),
            withLatestFrom(
                this.store.pipe(select(fromDiscovery.getYTDDateRange))
            ),
            filter(([, dateRange]) => !!dateRange),
            switchMap(
                ([, dateRange]) => this.experimentService.getPlannerStats(dateRange)
                    .pipe(
                        map((plannerStatsYTD) => loadPlannerStatsYTDSuccess({ plannerStatsYTD: plannerStatsYTD as PlannerStats })),
                        catchError(error => {
                            return of(loadPlannerStatsYTDFailure(error));
                        })
                    )
            )
        )
    );

    loadPlannerStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPlannerStats),
            map(action => action.componentGroupUuid),
            withLatestFrom(
                this.store.pipe(select(fromDiscovery.getDateRange))
            ),
            filter(([, dateRange]) => !!dateRange),
            switchMap(
                ([componentGroupUuid, dateRange]) => this.experimentService.getPlannerStatsByComponentGroup(componentGroupUuid, dateRange)
                    .pipe(
                        map(plannerStatsResponse => {
                            return loadPlannerStatsSuccess({ plannerStats: plannerStatsResponse as PlannerStats });
                        }),
                        catchError(error => {
                            return of(loadPlannerStatsFailure(error));
                        })
                    )
            )
        )
    );

    loadTrafficStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTrafficStats),
            withLatestFrom(
                this.store.pipe(select(fromDiscovery.getDateRange))
            ),
            filter(([, dateRange]) => !!dateRange),
            switchMap(
                ([{ uuid }, dateRange]) => this.experimentService.getTrafficStats(uuid, dateRange)
                    .pipe(
                        map((stats: DiscoveryTrafficStatsResponse) => {
                            const totalDiscoveryVisitors = stats.totalFliptoWebsiteVisitors + stats.totalControlWebsiteVisitors;
                            const trafficStats: DiscoveryTrafficStats = {
                                ...stats,
                                totalDiscoveryVisitors,
                                percentDiscoveryTraffic: calcPercent(stats.totalFliptoWebsiteVisitors, totalDiscoveryVisitors)
                            };
                            return loadTrafficStatsSuccess({ trafficStats });
                        }),
                        catchError(error => {
                            return of(loadTrafficStatsFailure(error));
                        })
                    )
            )
        )
    );

    combineTrafficAndPlannerStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadTrafficStatsSuccess, loadPlannerStatsSuccess),
            withLatestFrom(
                this.store.pipe(select(fromDiscovery.getDateRange)),
                this.store.pipe(select(fromDiscovery.getPlannerStats)),
                this.store.pipe(select(fromDiscovery.getTrafficStats)),
            ),
            filter(([_, dateRange, plannerStats, trafficStats]) => !!dateRange && !!plannerStats && !!trafficStats),
            map(([_, dateRange, plannerStats, trafficStats]) => {
                const stats: PlannerStats = {
                    ...plannerStats,
                    projectedPlanners: trafficStats ? calcMonthlyProjectedValue(plannerStats.totalPlanners, dateRange.duration, trafficStats.percentDiscoveryTraffic) : null,
                    projectedTransactions: trafficStats ? calcMonthlyProjectedValue(plannerStats.totalTransactions, dateRange.duration, trafficStats.percentDiscoveryTraffic) : null,
                    projectedNights: trafficStats ? calcMonthlyProjectedValue(plannerStats.totalNights, dateRange.duration, trafficStats.percentDiscoveryTraffic) : null,
                    projectedRevenue: trafficStats ? calcMonthlyProjectedValue(plannerStats.totalRevenue, dateRange.duration, trafficStats.percentDiscoveryTraffic) : null,
                    percentFliptoUnveil: trafficStats ? calcPercent(plannerStats.totalPlanners, trafficStats.totalDiscoveryVisitors) : null,
                };
                return combineTrafficAndPlannerStats({ plannerStats: stats });
            })
        ));

    loadEmailStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadEmailStats),
            withLatestFrom(
                this.store.pipe(select(fromDiscovery.getDateRange))
            ),
            filter(([, dateRange]) => !!dateRange),
            switchMap(
                ([{ uuid }, dateRange]) => this.experimentService.getEmailStats(uuid, dateRange)
                    .pipe(
                        withLatestFrom(
                            this.store.pipe(
                                select(fromDiscovery.getPlannerStats),
                                filter(stats => !!stats)
                            )
                        ),
                        map(([emailStatsResponse, plannerStats]: [DiscoveryEmailStatsResponse, PlannerStats]) => {
                            const emailStats: DiscoveryEmailStats = {
                                ...emailStatsResponse,
                                percentRecoveredBooking: calcPercent(plannerStats.totalTransactions, emailStatsResponse.totalSent),
                                percentAbandonedOpened: calcPercent(emailStatsResponse.totalOpened, emailStatsResponse.totalSent),
                                percentAbandonedClicked: calcPercent(emailStatsResponse.totalClicked, emailStatsResponse.totalOpened)
                            };
                            return loadEmailStatsSuccess({ emailStats });
                        }),
                        catchError(error => {
                            return of(loadEmailStatsFailure(error));
                        })
                    )
            )
        )
    );

    constructor(private actions$: Actions,
                private store: Store<fromDiscovery.DiscoveryState>,
                private discoveryVersioningService: DiscoveryVersioningService,
                private experimentService: ExperimentService) {
    }
}
