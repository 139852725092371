/**
 * Created by roman.kupin on 9/28/2017.
 */

import { combineReducers } from 'redux';
import accountOverview from "./accountOverview";
import gaReports from "./gaReports";
import photoAdvocacy from "./photoAdvocacy";
import poststay from "./poststay";
import prestay from "./prestay";
import properties from "./properties";
import propertiesStatistics from "./propertiesStatistics";
import propertyAdvocacyImpact from "./propertyAdvocacyImpact";
import propertyAdvocacyOverview from "./propertyAdvocacyOverview";
import propertyGuestsJourneys from "./propertyGuestsJourneys";
import propertyPlatformData from "./propertyPlatformData";
import randomPhotos from "./randomPhotos";

const apiDataReducer = combineReducers({
    properties,
    randomPhotos,
    accountOverview,
    propertyAdvocacyOverview,
    propertyAdvocacyImpact,
    propertyGuestsJourneys,
    propertiesStatistics,
    gaReports,
    propertyPlatformData,
    prestay,
    poststay,
    photoAdvocacy
});

export default apiDataReducer;
