import PropertyApi from "../../../actions/propertyApi";
import { SocialNetwork } from "../../../api/socialNetwork";
import { ShareType } from "../../broadcast/shareType";
import { StoryDescriptor } from "../../broadcast/storyDescriptor";
import { IShareImageActions, LeadShareImageActions } from "../shareImageActions";


export class BroadcastStoryFlowController {

    static STEP_NAME_CHOOSE_GOAL = "ChooseGoal";
    static STEP_NAME_STORY_IMAGE_SETUP = "StoryShareImageSetup";
    static STEP_NAME_PHOTOCONTEST_INVITATION_IMAGE_SETUP = "PhotocontestInvitationShareImageSetup";
    static STEP_NAME_CHOOSE_NETWORK = "ChooseNetwork";
    static STEP_NAME_SHARE_IMAGE_READY = "ShareImageReady";
    static STEP_NAME_CREATE_IMAGE_PROGRESS = "CreateImageProgress";
    static STEP_NAME_CONCLUDE_RECOMMENDATION = "ConcludeRecommendation";


    $filter;
    $rootScope;
    $timeout;
    shareOAuth;
    fileSaver;
    parse;
    $ngRedux;
    PhotoContest;
    StoryModal;
    ftModal;
    $q;
    Layers;
    SpacesService;
    SessionService;

    ftAzureStoredPhoto: any;

    $element;
    $scope;

    fullContext;

    property: any;
    story: any;
    isQuoteOnly = false;

    shareType: ShareType;
    imageSetupOptions;

    contests;
    contest: any;

    shareUrl: any;

    network;
    inviteToContest;
    shareImageUrl: any;
    storySharedTo;

    storyDescriptor: StoryDescriptor;
    shareImageActions: IShareImageActions;
    shareImageTemplateData: any;

    isInitialized: boolean = false;

    static RecommendationTypeToShareTypeMap = {
        1: ShareType.Story,
        2: ShareType.PhotoContestInvitation,
        5: ShareType.PhotoContestInvitation
    };

    constructor(
        public $injector,

        public renderer,
        public propertyUuid,
        public storyUuid,
        public recommendation,
        public steps
    ) {
        ["$filter", "$rootScope", "$timeout", "shareOAuth", "fileSaver", "parse", "$ngRedux", "PhotoContest", "StoryModal", "ftModal", "$q", "Layers", 'SpacesService', 'SessionService'].forEach(d => this[d] = $injector.get(d));
    }

    close() {
        this.renderer.remove();
    }

    setShareType(type) {
        this.shareType = type;
        this.shareImageActions = this.$injector.instantiate(/*type == ShareType.Story ? */LeadShareImageActions/* : InvitationShareImageActions*/);
    }

    createShareImageTemplate() {
        this.shareImageActions.createImageTemplate({
            SocialApplication: 1,
            ContestUUID: this.story.guest.poststay.photo.photoContest.uuid,
            LanguageCode: "en"
        })
            .then(data => this.shareImageTemplateData = data);
    }

    createShareImage(model): any {
        if (this.shareType == ShareType.Story || this.shareType == ShareType.Website) {
            return this.PhotoContest.createCurationShareImage(this.SpacesService.getPropertyUuid(), model)
                .then(shareImageUrl => this.shareImageUrl = shareImageUrl);
        } else if (this.shareType == ShareType.PhotoContestInvitation) {
            this.PhotoContest.createInvitationShareImageFromTemplate(this.SpacesService.getPropertyUuid(), model)
                .then(shareImageUrl => this.shareImageUrl = shareImageUrl)
        }
    }

    openOAuth(message) {
        if (this.shareType == ShareType.Story || this.shareType == ShareType.Website) {
            this.doCurationShare(message);
        } else {
            this.doContestInvitationShare(message);
        }
    }

    downloadShareImage() {
        this.shareImageActions.downloadImage(this.shareImageUrl)
            .then(() => {
                const network = this.story.socialNetworksSharing.find(n => n.socialNetworkType == this.network.type);
                const networkType = this.parse.int(network.socialNetworkType);
                if (networkType == SocialNetwork.Instagram) {
                    if (!this.storySharedTo.includes(networkType)) {
                        this.storySharedTo.push(networkType);
                    }
                    if (!!this.recommendation) {
                        this.$ngRedux.dispatch(PropertyApi.closeRecommendation(this.property.id, this.recommendation.recommendation_uuid, true))
                            .then(this.$rootScope.$broadcast("[StoryModal].RecommendationComplete"));
                    }
                    this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, false);
                    this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK, true);
                }
            });
    }

    chooseDifferentChannel() {
        this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, false);
        this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK, true);
    }

    createImageSetupOptionsFromStory(story) {
        const storyDescriptor = new StoryDescriptor(this.parse, story);
        return {
            isShowGuestPhoto: !!story.guest.poststay.photo,
            isShowProfilePic: !!story.guest.profileImageUrl,
            isShowGuestName: (story.guest.first && story.guest.first.length > 0),
            isShowGuestQuote: (!storyDescriptor.isHotelRatedNegativeQuote() && !storyDescriptor.isHotelRatedNeutralQuote() && storyDescriptor.hasPositiveQuote() && !storyDescriptor.isCaptionBetterThanQuote()),
            isShowPhotoCaption: (!storyDescriptor.isHotelRatedNegativeCaption() && !storyDescriptor.isHotelRatedNeutralCaption() && storyDescriptor.hasCaption() && storyDescriptor.isCaptionBetterThanQuote()),
            isLandscape: storyDescriptor.isLandscape(),
            isRtl: storyDescriptor.isRtl()
        }
    }

    getShareImageModel() {
        if (this.shareType == ShareType.Story || this.shareType == ShareType.Website || this.shareType == ShareType.PhotoContestInvitation) {
            return {
                GuestUUID: this.story.guest.uuid,
                SocialApplication: this.network.type,
                PhotoUUID: this.imageSetupOptions.isShowGuestPhoto ? this.story.guest.poststay.photo.uuid : null,
                ...this.imageSetupOptions,
                SelectedPhotoUrl: this.imageSetupOptions.isShowGuestPhoto ? this.ftAzureStoredPhoto(this.story.guest.poststay.photo) : this.property.BackgroundImageUrl,
            };
        }
        // else if (this.shareType == ShareType.PhotoContestInvitation) {
        //     return {
        //         SocialApplication: options.network.type,
        //         ContestUUID: this.story.guest.poststay.photo.photoContest.uuid,
        //         LanguageCode: "en",
        //         PhotoUuids: this.shareImageTemplateData.images,
        //         BackgroundImageUrl: this.shareImageTemplateData.background_image_url
        //     };
        // }
    }

    getShareUrl() {
        switch (this.shareType) {
            case ShareType.PhotoContestInvitation: {
                return this.$filter("cnameUrl")(this.contest.subdomains, 3, this.story.propertySlug, { contest: this.inviteToContest.slug, source: this.network.title, lang: "en" });
            }
            case ShareType.Story: {
                return `${this.$filter("cnameUrl")(this.contest.subdomains, 1, this.story.propertySlug)}/gallery/${this.story.guest.poststay.photo.uuid}`;
            }
            case ShareType.Website: {
                return this.property.propertyUrl;
            }
            default: {
                throw "Share type not supported";
            }
        }
    }

    openShareModal(network) {
        this.network = network;
        this.shareUrl = this.getShareUrl();

        this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK, false);
        this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CREATE_IMAGE_PROGRESS, true);

        this.shareImageActions.createImage(this.getShareImageModel())
            .then((shareImageUrl) => {
                this.shareImageUrl = shareImageUrl;
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CREATE_IMAGE_PROGRESS, false);
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, true);
            });
    }

    doCurationShare(message) {
        let model = {
            companyCode: this.story.companyCode,
            languageCode: "en",
            socialApp: this.network.type,
            shareMessage: message,
            photoUUID: !!this.story.guest.poststay.photo ? this.story.guest.poststay.photo.uuid : null,
            shareImageUrl: this.shareImageUrl,
            callback: 'fliptoCurationShareComplete'
        };

        if(this.shareType == ShareType.Website) {
            model["websiteShareUrl"] = this.property.propertyUrl;
        }

        this.shareOAuth.log('curation-share', { action: "open", state: model });
        this.shareOAuth.tryOpenWindow(this.network.type);
        this.shareOAuth.trySetLocation(this.network.type, model);

        window["fliptoCurationShareComplete"] = (state) => {
            this.shareOAuth.log('curation-share', { action: "complete", state: state });
            this.$rootScope.$apply(() => {
                if (state == 0 || state == false || typeof state === "string") {
                    alert(this.$filter("translate")('flipto.account.CurationShareError'));
                } else if (state == 1 || state == true) {
                    const network = this.story.socialNetworksSharing.find(n => n.socialNetworkType == this.network.type);
                    const networkType = this.parse.int(network.socialNetworkType);
                    if (!this.storySharedTo.includes(networkType)) {
                        this.storySharedTo.push(networkType);
                    }
                    if (!!this.recommendation) {
                        this.$ngRedux.dispatch(PropertyApi.closeRecommendation(this.property.id, this.recommendation.recommendation_uuid, true))
                            .then(() => this.$rootScope.$broadcast("[StoryModal].RecommendationComplete"));
                    }
                }
                this.shareOAuth.close();
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, false);
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK, true);
            });
        };
    }

    doContestInvitationShare(message) {
        this.shareOAuth.tryOpenWindow(this.network.type);
        var model = {
            contestUUID: this.inviteToContest.id,
            companyCode: this.story.companyCode,
            languageCode: "en",
            socialApp: this.network.type,
            shareMessage: message,
            shareImageUrl: this.shareImageUrl,
            invitationUrl: this.shareUrl,
            callback: 'fliptoInvitationShareComplete'
        };

        this.shareOAuth.log('invitation-share', { action: "open", state: model });
        this.shareOAuth.trySetLocation(this.network.type, model);

        window["fliptoInvitationShareComplete"] = (state) => {
            this.shareOAuth.log('invitation-share', { action: "complete", state: state });
            this.$rootScope.$apply(() => {
                if (state == 0 || state == false) {
                    window.alert(this.$filter("translate")('flipto.account.CurationShareError'));
                } else if (state == 1 || state == true) {
                    const network = this.story.socialNetworksSharing.find(n => n.socialNetworkType == this.network.type);
                    const networkType = this.parse.int(network.socialNetworkType);
                    if (!this.storySharedTo.includes(networkType)) {
                        this.storySharedTo.push(networkType);
                    }
                    if (!!this.recommendation) {
                        this.$ngRedux.dispatch(PropertyApi.closeRecommendation(this.property.id, this.recommendation.recommendation_uuid, true))
                            .then(() => this.$rootScope.$broadcast("[StoryModal].RecommendationComplete"));
                    }
                    window.alert(this.$filter("translate")('flipto.account.ShareSuccess'));
                }
                this.shareOAuth.close();
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, false);
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK, true);
            });
        };
    }

    reprocessImage() {
        this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, false);
        this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CREATE_IMAGE_PROGRESS, true);

        this.shareImageActions.createImage(this.getShareImageModel())
            .then((shareImageUrl) => {
                this.shareImageUrl = shareImageUrl;
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_CREATE_IMAGE_PROGRESS, false);
                this.toggleStep(BroadcastStoryFlowController.STEP_NAME_SHARE_IMAGE_READY, true);
            });
    }

    getActiveStep() {
        return this.steps.find(s => s.isActive);
    }

    getStep(name) {
        return this.steps.find(s => s.name === name);
    }

    toggleStep(name, isActive) {
        this.getStep(name).isActive = isActive;
    }

    moveTo(nextStep) {
        this.getActiveStep().isActive = false;
        this.getStep(nextStep).isActive = true;
    }
}