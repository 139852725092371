import { Component, EventEmitter, Output } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CollaborativeType } from 'account-hybrid/features/account-settings/enums/collaborative-type.enum';
import { CompanyType } from '../../../../../core/authentication/models/user';
import { CreateAccountDto } from '../../models/create-account.dto';
import { createAccount, createAccountFailure, createAccountSuccess } from '../../store/management/management.actions';
import { ManagementState } from '../../store/management/management.state';

@Component({
    selector: 'ft-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent {
    @Output() creatorClose = new EventEmitter();
    companyTypes = [
        {
            label: 'Group',
            value: CompanyType.Group
        },
        {
            label: 'Collaborative',
            value: CompanyType.Collaborative
        }
    ];
    collaborativeCompanyType = CompanyType.Collaborative;
    collaborativeTypes = Object.keys(CollaborativeType)
        .filter(key => typeof CollaborativeType[key] === 'number')
        .map(key => ({
            value: CollaborativeType[key],
            label: key
        }));
    success$ = this.actions$.pipe(ofType(createAccountSuccess));
    error$ = this.actions$.pipe(ofType(createAccountFailure));


    constructor(private store$: Store<ManagementState>,
                private actions$: Actions) {
    }

    onSave(data: CreateAccountDto) {
        this.store$.dispatch(createAccount({ data }));
        this.creatorClose.next(null);
    }
}
