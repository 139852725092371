import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { EditorLocation } from 'apps/account/src/account-shared';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

declare const google;

@Component({
    selector: 'ft-address-editor',
    templateUrl: './address-editor.component.html',
    styleUrls: ['./address-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AddressEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: AddressEditorComponent
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class AddressEditorComponent extends ModalEditorBase implements AfterViewInit, OnDestroy, OnInit {
    @Input() isStructuredLocation = false;
    @Input() isVenue: boolean;
    @Input() isManualEntry = true;
    @ViewChild('lookupRef', { static: false }) lookupRef: ElementRef;
    @ViewChildren('inputs', { read: NgModel }) inputs: QueryList<NgModel>;
    @Output() editorVisible = new EventEmitter();

    isJsLoaded = false;
    fullAddress: string;
    overlaySelector = 'cdk-global-overlay-wrapper';
    initialValue: EditorLocation = {
        streetAddress: null,
        countryName: null,
        region: null,
        city: null,
        postalCode: null,
        latitude: null,
        longitude: null,
        area: null,
    };
    baseFields: EditorLocation = {
        ...this.initialValue
    };

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        super();
        this.value$.pipe(
            tap((value) => {
                if (!value) {
                    this.initValue();
                }
            }),
            filter(value => !!value),
            map(value => ({ ...this.baseFields, ...value })),
            tap(value => {
                this.fullAddress = this.formatAddress(value);
            }),
            takeUntil(this.destroySubj)
        ).subscribe();


        this.onModelInit()
            .pipe(
                tap(value => {
                    this.initValue(value);
                }),
                takeUntil(this.destroySubj)
            ).subscribe();
    }

    formatAddress(location: EditorLocation): string {
        return [[location.venueName, location.streetAddress, location.city, location.region]
            .filter(value => !!value).join(', '), location.postalCode, location.countryName]
            .filter(value => !!value).join(' ');
    }

    initValue(value?: any) {
        this.value = {
            ...this.baseFields,
            ...value,
            latitude: isNaN(value?.latitude) ? null : value?.latitude,
            longitude: isNaN(value?.longitude) ? null : value?.longitude,
        };

        if (this.isVenue) {
            this.value = {
                ...this.value,
                venueName: this.value?.venueName,
                venueCustomName: this.value?.venueCustomName,
            }
        }
    }

    onEditorVisible() {
        (window as any).initAutocomplete();
        super.onEditorVisible();
        document.getElementsByClassName(this.overlaySelector)?.[0].addEventListener('scroll', this.onScroll.bind(this));
        this.editorVisible.emit();
    }

    ngOnInit() {
        this.updateInitialValue();
    }

    ngAfterViewInit() {
        (window as any).initAutocomplete = () => {
            const componentForm = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'short_name',
                postal_code: 'short_name'
            };

            // Create the autocomplete object, restricting the search to full addresses.
            const autocomplete = new google.maps.places.Autocomplete(this.lookupRef.nativeElement, { types: this.isVenue ? [] : ['address'] });

            // When the user selects an address from the dropdown, populate the address fields in the form.
            autocomplete.addListener('place_changed', () => {
                // Get the place details from the autocomplete object.
                const place = autocomplete.getPlace();

                // Get each component of the address from the place details and populate an easy to read object
                const location: any = {
                    lat: place.geometry.location.lat(),
                    long: place.geometry.location.lng()
                };
                for (let i = 0; i < place.address_components.length; i++) {
                    const addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        location[addressType] = place.address_components[i][componentForm[addressType]];
                    }
                }

                // Convert our object into a location object
                let cleanLocation: Partial<EditorLocation> = {
                    streetAddress: ((location.street_number ? `${location.street_number} ` : '') + (location.route || '')) || '',
                    countryName: location.country || '',
                    region: location.administrative_area_level_1 || '',
                    city: location.locality || place.address_components.find(item => item.types.some(type => type === 'administrative_area_level_1'))?.long_name || '',
                    postalCode: location.postal_code || '',
                    latitude: location.lat || '',
                    longitude: location.long || ''
                };

                if (this.isVenue) {
                    cleanLocation = { ...cleanLocation, venueName: place.name || '' }
                }

                this.value = { ...this.value, ...cleanLocation };
                this.changeDetectorRef.detectChanges();
            });
        };
        this.initializeMaps();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        document.getElementsByClassName(this.overlaySelector)?.[0]?.removeEventListener('scroll', this.onScroll.bind(this));
    }

    onScroll() {
        if (document.activeElement === this.lookupRef.nativeElement) {
            this.lookupRef.nativeElement.blur();
        }
    }

    isInvalid(): boolean {
        return this.inputs?.some(input => input.invalid);
    }

    initializeMaps() {
        if (!this.isJsLoaded) {
            // Add the script tag to head.  Should callback to initAutocomplete
            const firstScript = document.getElementsByTagName('script')[0];
            const src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDW_RhNMchTilWj6McY8VEAH-DjZ4do6s8&libraries=places&callback=initAutocomplete';
            if (firstScript.src !== src) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = src;
                script.async = true;
                firstScript.parentNode.insertBefore(script, firstScript);
                this.isJsLoaded = true;
            }
        } else {
            (window as any).initAutocomplete();
        }
    }
}


