import {
    Component,
    ContentChild,
    HostListener,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadService } from "../../services/download.service";

@UntilDestroy()
@Component({
    selector: "ft-file-download",
    templateUrl: "./file-download.component.html",
    styleUrls: ["./file-download.component.scss"],
})
export class FileDownloadComponent implements OnInit {
    @Input() name;
    @Input() url;
    @ContentChild(TemplateRef, { static: false })
    customTemplate: TemplateRef<any>;
    isDisabled = false;

    constructor(private download: DownloadService) { }

    ngOnInit() {
        this.download.loadingStateUpdateSubject$
            .pipe(
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.isDisabled = !!this.download.loadingStateMap.get(this.url);
            })
    }

    @HostListener("click")
    downloadFile() {
        if (!this.isDisabled) {
            this.download.downloadFile(this.url);
        }
    }
}
