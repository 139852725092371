import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShortUrlService } from '../short-urls.service';

@Directive({
  selector: '[shortLinkCodeUnique]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ShortLinkCodeUniqueValidatorDirective, multi: true }]
})
export class ShortLinkCodeUniqueValidatorDirective implements AsyncValidator {
  constructor(private shortUrls: ShortUrlService) { }

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    return this.shortUrls
      .validateShortCode(ctrl.value)
      .pipe(
        map(code => ({ shortLinkCodeUnique: true })),
        catchError(() => of(null))
      );
  }
}
