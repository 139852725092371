import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { loadAvailableAccountLanguages } from 'account-hybrid/common/modules/account-management/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IdentityService } from '../../../core/authentication/services/identity.service';
import { Environment, ENVIRONMENT_TOKEN } from '../../../core/environment.service';
import { UserData } from '../models/user-data';
import { AuthenticationApiService } from '../services/authentication-api.service';
import {
    changePassword,
    changePasswordFailure,
    changePasswordSuccess,
    changeUser,
    changeUserFailure,
    changeUserSuccess,
    enterSite,
    resendUser,
    resendUserFailure,
    resendUserSuccess,
    resetPassword,
    resetPasswordFailure,
    resetPasswordSuccess,
    sendForgotPasswordEmail,
    sendForgotPasswordEmailFailure,
    sendForgotPasswordEmailSuccess,
    signIn,
    signInFailure,
    signInSuccess,
    uploadProfilePicture,
    uploadProfilePictureFailure,
    uploadProfilePictureSuccess,
    verifyToken,
    verifyTokenFailure,
    verifyTokenSuccess,
} from './authentication.actions';
import { getSignInRedirectTo } from './authentication.selectors';
import { AuthenticationState } from './authentication.state';

@Injectable()
export class AuthenticationEffects {

    signIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signIn),
            mergeMap(
                ({ data }) => this.authenticationApiService.signIn(data)
                    .pipe(
                        mergeMap((userSession: any) => {
                            return [
                                signInSuccess({ userSession }),
                                enterSite()
                            ];
                        }),
                        catchError(error => {
                            return of(signInFailure({ error }));
                        })
                    )
            )
        )
    );

    storeSession$ = createEffect(() =>
            this.actions$.pipe(
                ofType(signInSuccess, resetPasswordSuccess),
                tap(({ userSession }) => {
                    localStorage.setItem('flipto.session', JSON.stringify(userSession));
                    localStorage.setItem('flipto.version', this.environment.version);
                })
            ),
        { dispatch: false }
    );

    onLoginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(signInSuccess),
            map(() => loadAvailableAccountLanguages())
        ));

    enterSite$ = createEffect(() =>
            this.actions$.pipe(
                ofType(enterSite),
                withLatestFrom(
                    this.store.pipe(select(getSignInRedirectTo))
                ),
                tap(([, redirectTo]) => {
                    location.href = redirectTo ? redirectTo : '/home';
                })
            ),
        { dispatch: false }
    );

    sendForgotPasswordEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(sendForgotPasswordEmail),
            mergeMap(
                ({ data }) => this.authenticationApiService.sendForgotPasswordEmail(data)
                    .pipe(
                        map(() => sendForgotPasswordEmailSuccess()),
                        catchError(error => of(sendForgotPasswordEmailFailure({ error })))
                    )
            )
        )
    );

    resetPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resetPassword),
            mergeMap(
                ({ data }) => this.authenticationApiService.resetPassword(data)
                    .pipe(
                        map((userSession: any) => {
                            return resetPasswordSuccess({ userSession });
                        }),
                        catchError(error => {
                            return of(resetPasswordFailure({ error }));
                        })
                    )
            )
        )
    );

    changeUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeUser),
            mergeMap(
                ({ data }) => this.authenticationApiService.changeUser(data)
                    .pipe(
                        map((res: any) => {
                            return changeUserSuccess({ userData: data });
                        }),
                        catchError(error => {
                            return of(changeUserFailure({ error }));
                        })
                    )
            )
        )
    );

    uploadProfilePicture$ = createEffect(() =>
        this.actions$.pipe(
            ofType(uploadProfilePicture),
            map(payload => payload.file),
            switchMap(file =>
                this.authenticationApiService.uploadProfilePicture(file)
                    .pipe(
                        mergeMap(({ profileImageUrl }) => this.authenticationApiService.changeUser({ profileImageUrl }).pipe(map(() => profileImageUrl))),
                        map(profileImageUrl => {
                            return uploadProfilePictureSuccess({ profileImageUrl });
                        }),
                        catchError(error => {
                            return of(uploadProfilePictureFailure({ error }));
                        })
                    )
            )
        )
    );

    resendUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(resendUser),
            mergeMap(
                ({ userUuid, companyCode }) => this.authenticationApiService.resendUser(userUuid, companyCode)
                    .pipe(
                        map(() => {
                            return resendUserSuccess();
                        }),
                        catchError(error => {
                            return of(resendUserFailure({ error }));
                        })
                    )
            )
        )
    );

    verifyToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(verifyToken),
            mergeMap(
                ({ userUuid, token }) => this.authenticationApiService.verifyToken(token, userUuid)
                    .pipe(
                        map((userData: UserData) => {
                            return verifyTokenSuccess({ userData });
                        }),
                        catchError(error => {
                            return of(verifyTokenFailure({ error }));
                        })
                    )
            )
        )
    );


    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changePassword),
            mergeMap(
                ({ data }) => this.authenticationApiService.changePassword(data)
                    .pipe(
                        map(() => {
                            return changePasswordSuccess();
                        }),
                        catchError(error => {
                            return of(changePasswordFailure({ error }));
                        })
                    )
            )
        )
    );

    constructor(private actions$: Actions,
                private store: Store<AuthenticationState>,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment,
                private identityService: IdentityService,
                private authenticationApiService: AuthenticationApiService) {
    }
}


