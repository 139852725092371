import { Injectable } from '@angular/core';

@Injectable()
export class UrlFormatter {
    static format() {

    }

    static appendStringToQuery(url: string, query: string) {
        if (!url) return null;
        if (!query) return url;
        return url + (url.indexOf('?') > -1 ? '&' : '?') + query;
    }

    static appendObjectToQuery(url: string, query: object) {
        const strQuery = Object.keys(query)
            .filter(key => query.hasOwnProperty(key) && typeof query[key] !== "undefined" && query[key] !== null)
            .map(key => `${key}=${encodeURIComponent(query[key])}`)
            .reduce((prev, curr) => `${prev}${prev.length === 0 ? '' : '&'}${curr}`, "");
        return UrlFormatter.appendStringToQuery(url, strQuery);
    }

    static updateUrlQueryParams(url: string, addParams?: Record<string, string>, removeParams?: string[]): string {
        if (!url || (!removeParams.length && !addParams)) return url;
        const urlObj = new URL(url);
        Object.keys(addParams || {}).forEach(param => urlObj.searchParams.append(param, addParams[param]));
        (removeParams || []).forEach(param => urlObj.searchParams.delete(param));
        return decodeURI(urlObj.toString());
    }
}