import { Directive, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShortUrlService } from '../short-urls.service';
import { ContestInviteSetupOptions } from './setup-options';

@Directive({
  selector: '[shortLinkNameUnique]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: ShortLinkNameUniqueValidatorDirective, multi: true }]
})
export class ShortLinkNameUniqueValidatorDirective implements AsyncValidator {

  @Input() shortLinkNameUnique: ContestInviteSetupOptions;

  constructor(private shortUrls: ShortUrlService) { }

  validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
    return this.shortUrls
      .validateDisplayName(
        this.shortLinkNameUnique.propertyUuid,
        ctrl.value,
        this.shortLinkNameUnique.contestUuid,
        this.shortLinkNameUnique.theme
      )
      .pipe(
        map(res => { return !!res ? { shortLinkNameUnique: true } : null; }),
        catchError(() => of(null))
      )
  }
}