import { Directive, forwardRef, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
    selector: '[max]',
    providers: [{provide: NG_VALIDATORS,  useExisting: forwardRef(() => MaxValidatorDirective), multi: true}]
})
export class MaxValidatorDirective implements Validator {
    @Input() max: number;

    validate(control: AbstractControl): {[key: string]: any} | null {
        return control.value > this.max ? {'max': true} : null;
    }
}
