import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { AuthError } from "../../models/auth-error";
import { ForgotPasswordStates } from "../../models/forgot-password-states.enum";
import * as fromAuthentication from "../../store/index";

@Component({
    selector: 'ft-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    @Input() token: string;
    @Input() userUuid: string;
    tokenVerificationLoading$: Observable<boolean>;
    tokenVerificationError$: Observable<AuthError>;
    forgotPasswordLoading$: Observable<boolean>;
    forgotPasswordError$: Observable<AuthError>;
    forgotPasswordState$: Observable<ForgotPasswordStates>;
    forgotPasswordStates = ForgotPasswordStates;
    resetPasswordLoading$: Observable<boolean>;
    resetPasswordError$: Observable<AuthError>;
    password: string;
    isAlive = true;


    constructor(private store: Store<fromAuthentication.AuthenticationState>,
                private actions$: Actions) {
        this.tokenVerificationLoading$ = this.store.pipe(select(fromAuthentication.isTokenVerificationLoading));
        this.tokenVerificationError$ = this.store.pipe(select(fromAuthentication.getTokenVerificationError));
        this.forgotPasswordLoading$ = this.store.pipe(select(fromAuthentication.isForgotPasswordLoading));
        this.forgotPasswordError$ = this.store.pipe(select(fromAuthentication.getForgotPasswordError));
        this.forgotPasswordState$ = this.store.pipe(select(fromAuthentication.getForgotPasswordState));
        this.resetPasswordLoading$ = this.store.pipe(select(fromAuthentication.isResetPasswordLoading));
        this.resetPasswordError$ = this.store.pipe(select(fromAuthentication.getResetPasswordError));
    }

    ngOnInit() {
        this.verifyToken();
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    verifyToken() {
        this.store.dispatch(fromAuthentication.verifyToken({
            userUuid: this.userUuid,
            token: this.token
        }));
    }

    submit() {
        this.store.dispatch(fromAuthentication.resetPassword({
                data: {
                    password: this.password,
                    token: this.token,
                    userUuid: this.userUuid
                }
            })
        );

        this.actions$.pipe(
            takeWhile(() => this.isAlive),
            ofType(fromAuthentication.resetPasswordSuccess),
        ).subscribe(() => {
            this.store.dispatch(fromAuthentication.enterSite());
        });
    }

    requestLink() {
        this.store.dispatch(fromAuthentication.sendForgotPasswordEmail({
            data: {
                userUuid: this.userUuid
            }
        }));
    }
}
