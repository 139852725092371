import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { MultiLanguageComponent } from 'account-hybrid/common/components/multi-linguage/multi-language.component';
import {
    domInjectionStrategyOptions
} from 'account-hybrid/common/components/touchpoints/enums/dom-injection-strategy.enum';
import * as fromTouchpoints from 'account-hybrid/common/components/touchpoints/store';
import { mapLanguageNameToCode } from 'account-hybrid/common/modules/account-management/utils/language-name-mapping.util';
import { Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { DiscoveryComponentType } from '../../enums';
import { IDiscoveryInjectableComponentMessaging, IDiscoveryInjectableComponentSettings } from '../../interfaces';

@Component({
    selector: 'ft-discovery-bar',
    templateUrl: './discovery-bar.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DiscoveryBarComponent extends MultiLanguageComponent implements OnInit {
    componentGroupUuid: string;
    domInjectionStrategyOptions = domInjectionStrategyOptions;
    tabsShowed = true;
    discoveryBarComponentsSettings$: Observable<Array<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>>;
    selectedDiscoveryBarComponent$: Observable<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>;
    isLoading$: Observable<boolean>;
    commitSuccess$: Observable<boolean>;
    messaging$: Observable<IDiscoveryInjectableComponentMessaging[]>;
    messaging: IDiscoveryInjectableComponentMessaging[];
    selectedIndex = 0;

    constructor(store: Store<fromTouchpoints.State>,
                private transition: Transition) {
        super(store);
    }

    ngOnInit() {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;

        this.discoveryBarComponentsSettings$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryBarComponents));
        this.selectedDiscoveryBarComponent$ = this.store.pipe(
            select(fromTouchpoints.selectDiscoveryBarComponentSettings),
            tap(selectedComponent => {
                if (!!selectedComponent) {
                    this.messaging$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentMessaging, {
                            componentType: DiscoveryComponentType.DiscoveryBar,
                            componentUuid: selectedComponent.componentUuid
                        }), withLatestFrom(this.languages$),
                        map(([messaging, availableLanguages]) => {
                            this.messaging = mapLanguageNameToCode(messaging, availableLanguages);
                            return this.messaging;
                        }), take(1));
                    this.messaging$.subscribe();
                }
            })
        );
        this.isLoading$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentIsLoading));
        this.commitSuccess$ = this.store.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentCommitSuccess));
    }

    toggleActiveComponent(isActive: boolean) {
        this.commit({ isActive });
    }

    selectComponent(componentUuid: string) {
        this.store.dispatch(fromTouchpoints.selectDiscoveryInjectableComponentSettings({
            componentUuid,
            componentType: DiscoveryComponentType.BookingButton
        }));
        this.refreshTabs();
    }

    refreshTabs() {
        this.tabsShowed = false;
        setTimeout(() => {
            this.tabsShowed = true;
        });
    }

    createComponent(componentName: string) {
        this.store.dispatch(fromTouchpoints.createDiscoveryInjectableComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings: { name: componentName },
            componentType: DiscoveryComponentType.DiscoveryBar
        }));
    }

    commit(data: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>) {
        this.store.dispatch(fromTouchpoints.commitDiscoveryInjectableComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings: data,
            componentType: DiscoveryComponentType.DiscoveryBar
        }));
    }

    getLabel(value: number) {
        return this.domInjectionStrategyOptions.find((option) => option.value === value)?.label;
    }
}
