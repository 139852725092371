import { animate, state, style, transition, trigger } from "@angular/animations";
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { ChecklistRule } from 'apps/account/src/account-shared';
import { fromEvent } from "rxjs";
import { takeWhile } from "rxjs/operators";
import { PasswordStrengthService } from "../../services/password-strength.service";

@Component({
    selector: 'ft-password-strength-checklist',
    templateUrl: './password-strength-checklist.component.html',
    styleUrls: ['./password-strength-checklist.component.scss'],
    animations: [
        trigger('slideDown', [
            state('*', style({
                bottom: '100%',
                opacity: 1
            })),
            state('void', style({
                bottom: '50%',
                opacity: 0
            })),
            transition('void => *', animate(200)),
            transition('* => void', animate(100))
        ])
    ]
})
export class PasswordStrengthChecklistComponent implements OnDestroy, AfterViewInit {
    @Input() inputRef;
    rulesVisible = false;
    rules: ChecklistRule[] = [];
    isAlive = true;

    constructor(private passwordStrengthService: PasswordStrengthService) {
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    ngAfterViewInit() {
        fromEvent(this.inputRef, 'input').pipe(
            takeWhile(() => this.isAlive)
        ).subscribe(() => {
            this.updateRules(this.inputRef.value);
        });

        fromEvent(this.inputRef, 'focus').pipe(
            takeWhile(() => this.isAlive)
        ).subscribe(() => {
            this.rulesVisible = true;
            this.updateRules(this.inputRef.value);
        });

        fromEvent(this.inputRef, 'blur').pipe(
            takeWhile(() => this.isAlive)
        ).subscribe(() => {
            this.rulesVisible = false;
        });
    }

    updateRules(password: string) {
        this.rules = [];
        const config = this.passwordStrengthService.config;
        if (config.minLength > 0) {
            this.rules.push(
                {
                    isValid: this.passwordStrengthService.lengthMatches(password),
                    text: `${config.minLength} or more characters`
                }
            );
        }
        if (config.minUppercaseLength > 0) {
            this.rules.push(
                {
                    isValid: this.passwordStrengthService.uppercaseMatches(password),
                    text: config.minUppercaseLength > 1 ?
                        `${config.minUppercaseLength} uppercase letters` :
                        `An uppercase letter`
                }
            );
        }
        if (config.minLowercaseLength > 0) {
            this.rules.push(
                {
                    isValid: this.passwordStrengthService.lowercaseMatches(password) && !!password,
                    text: config.minLowercaseLength > 1
                        ? `${config.minLowercaseLength} lowercase letters`
                        : `A lowercase letter`
                }
            );
        }

        if (config.numberOrSpecials) {
            this.rules.push(
                {
                    isValid: this.passwordStrengthService.numbersSpecialsMatches(password),
                    text: config.minNumbersLength === 1 && config.minSpecialsLength === 1
                        ? `A number or special character`
                        : `${config.minNumbersLength} number(s) or ${config.minSpecialsLength} special character(s)`
                }
            );
        } else {
            if (config.minNumbersLength > 0) {
                this.rules.push(
                    {
                        isValid: this.passwordStrengthService.numbersMatches(password),
                        text: `${config.minNumbersLength} number(s)`
                    }
                );
            }

            if (config.minSpecialsLength > 0) {
                this.rules.push(
                    {
                        isValid: this.passwordStrengthService.specialsMatches(password),
                        text: `${config.minSpecialsLength} special character(s)`
                    }
                );
            }
        }

        if (config.noSpaces) {
            this.rules.push(
                {
                    isValid: this.passwordStrengthService.noSpaceMatches(password) && !!password,
                    text: `No spaces`
                }
            );
        }
    }
}
