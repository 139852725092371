import {
    DEBUG_PARAMETER,
    FT_DEBUG_PARAMETER,
    FT_PREVIEW_PARAMETER
} from '../../constants/url-params.const';

export class UrlUtil {
    public static rootDomain(): string {
        const parts = document.location.hostname.split('.');
        const uniqueId = '_ft-domain-detector-' + (new Date()).getTime();

        // Start checking domains from the right hand side with at least two sections i.e. x.tld
        for (var i = 2; i < parts.length; i++) {
            // Attempt to place a cookie on the constructed domain
            const domain = parts.slice(-i).join('.');
            document.cookie = uniqueId + '=1;domain=' + domain + ';';

            // Check if cookie was placed successfully
            if (document.cookie.indexOf(uniqueId + '=1') !== -1) {
                // Remove the last (and only successful) cookie
                document.cookie = uniqueId + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain + ';';
                return domain;
            }
        }

        // No lower root domain available
        return document.location.hostname;
    }

    public static parseUrl(url: string) {
        if (!url) return {};
        const link = document.createElement('a');
        link.href = url;
        return {
            absolute: link.href,
            hash: link.hash,
            hashParams: new URLSearchParams(link.hash.split('?')[1] || ''),
            host: link.host,
            hostname: link.hostname,
            origin: link.origin,
            original: url,
            originalOriginPath: url.split(/[\?#]/)[0],
            pathname: link.pathname,
            query: link.search,
            queryParams: new URLSearchParams(link.search)
        };
    }

    public static addToPath(url: string, path: string): string {
        if (url) {
            const queryChunks = url.split('?');
            const hashChunks = queryChunks[0].split('#');
            hashChunks[0] += path;
            queryChunks[0] = hashChunks.join('#');
            return queryChunks.join('?');
        }

        return null;
    }

    public static hasKey(url: string, key: string, ignoreCase = true): boolean {
        return !!key && (this.getValue(url, key, ignoreCase) != null);
    }

    public static hasEmptyKey(url: string, key: string): boolean {
        if (!key) {
            return false;
        }
        const { queryParams } = this.parseUrl(url);

        const hasParam = Array.from((queryParams as any)?.keys())
            .some((queryKey: string) => queryKey.toLowerCase() === key.toLowerCase());


        return hasParam && !this.getValue(url, key);
    }

    public static queryParamExist(url: string, key: string): boolean {
        return this.hasKey(url, key) || this.hasEmptyKey(url, key);
    }

    public static getValue(url: string = window.location.href, key: string, ignoreCase = true): string {
        if (!key || !url) {
            return null;
        }
        const { query, hash, queryParams, hashParams } = this.parseUrl(url);
        // NOTE: can't use UrlSearchParams for case-insensitive search because typescript says it's not iterable for some reason
        const keyRegex = new RegExp(`[?&#]?${key}=([^&#]*)`, 'i');

        // Coalesce eats empty-string, so use null comparison instead
        const queryValue = ignoreCase ? query.match(keyRegex)?.[1] : queryParams.get(key);
        if (queryValue != null) return queryValue;
        const hashValue = ignoreCase ? hash.match(keyRegex)?.[1] : hashParams.get(key);
        if (hashValue != null) return hashValue;
        return null;
    }

    public static addToQueryString(url: string, key: string, value: string): string {
        if (!url || !key || value == null) {
            return url;
        }

        // Parse url so we can get urlsearchparams
        const { query, hash, originalOriginPath } = this.parseUrl(url);

        // If it's already in the fragment, do a replace
        const regex = new RegExp(`(${key}(?:=[^&#]*)?)|(${key}(?=[&#]|$))`);

        // Replace if it exists, otherwise append
        const newQuery = regex.test(query)
            ? query.replace(regex, `${key}=${encodeURIComponent(value)}`)
            : `${query}${query.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(value)}`;

        // Build new url
        return originalOriginPath + newQuery + hash;
    }

    public static addToFragment(url: string, key: string, value: string): string {
        if (!url || !key || value == null) {
            return url;
        }

        // Parse url so we can get urlsearchparams
        const { hash, query, originalOriginPath } = this.parseUrl(url);

        // If it's already in the fragment, do a replace
        const regex = new RegExp(`${key}=[^&#]*`);

        // Replace if it exists, otherwise append
        const newHash = regex.test(hash)
            ? hash.replace(regex, `${key}=${encodeURIComponent(value)}`)
            : `${hash || '#'}${hash.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(value)}`;

        // Build new url
        return originalOriginPath + query + newHash;
    }

    public static removeQueryParams(url: string): string {
        if (!url) {
            return url;
        }
        const { origin, pathname, hash } = UrlUtil.parseUrl(url);
        return origin + pathname + hash;
    }

    public static removeQueryParam(url: string, param: string): string {
        if (!url || !param) {
            return url;
        }

        const { origin, pathname, hash, queryParams } = UrlUtil.parseUrl(url);

        // Remove the specified parameter
        queryParams.delete(param);

        // Construct the updated URL
        const updatedSearch = queryParams.toString();
        return origin + pathname + (updatedSearch ? `?${updatedSearch}` : '') + (hash || '');
    }

    public static removePathParams(url: string): string {
        if (!url) {
            return url;
        }
        const { origin, query, hash } = UrlUtil.parseUrl(url);
        return origin + query + hash;
    }


    public static isDebug(url: string = window.location.href): boolean {
        return UrlUtil.hasKey(url, DEBUG_PARAMETER) || UrlUtil.hasKey(url, FT_DEBUG_PARAMETER);
    }

    public static isPreview(url: string = window.location.href): boolean {
        return UrlUtil.queryParamExist(url, FT_PREVIEW_PARAMETER);
    }

    public static encodeURI(data: Object | Object[]): string{
        if (typeof data !== 'string'){
            data = JSON.stringify(data)
        }
        if (!!data){
            return encodeURIComponent(data as string);
        }
        return null;
    }
    
    public static addParamWithoutParsing(url: string, key: string, val: string){
        // Encode parameters
        key = encodeURIComponent(key);
        val = encodeURIComponent(val);
        
        // Split off hash for easier query parsing
        const hashIndex = url.indexOf("#");
        let hash = "";
        if(hashIndex != -1) {
          hash = url.substring(hashIndex);
          url = url.substring(0,hashIndex);
        }
        
        // Split off the query string
        const queryIndex = url.indexOf('?');
        let query = "";
        if(queryIndex != -1) {
          query = url.substring(queryIndex);
          url = url.substring(0,queryIndex);
        }
        
        
        // Replace if it exists, otherwise append
        const regex = new RegExp(key + "=[^&#]*");
        const keyval = key + "=" + val;
        return url + 
            (regex.test(query)
              ? query.replace(regex, keyval)
              : (query + (queryIndex == -1 ? '?' : '&') + keyval))
            + hash;
    }
}