import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: "[ftImageFallback]"
})
export class ImageFallbackDirective implements OnInit, OnDestroy {
    @Input('ftImageFallback') fallbackUrl: string;
    errorFuncReference: Function = this.onError.bind(this);

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.addEventListener('error', this.errorFuncReference);
    }

    ngOnDestroy() {
        this.el.nativeElement.removeEventListener('error', this.errorFuncReference);
    }

    onError() {
        if (this.fallbackUrl) {
            this.el.nativeElement.setAttribute('src', this.fallbackUrl);
        } else {
            this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
        }
    }
}
