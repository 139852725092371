import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { BooknowSettings } from '../../../models/booknow-settings';
import * as fromBooknow from "../../../store/book-now";


@Component({
    selector: 'ft-book-now-header',
    templateUrl: './book-now-header.component.html'
})
export class BookNowHeaderComponent {
    @Input() data: BooknowSettings[];
    @Input() selectedComponent: BooknowSettings;
    @Output() selected = new EventEmitter<string>();
    @Output() created = new EventEmitter<string>();
    @Output() activeToggled = new EventEmitter<boolean>();
    newName: string;

    constructor(private store: Store<fromBooknow.State>) {
    }
}


