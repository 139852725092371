import { createAction, props } from '@ngrx/store';
import { BannerEmailSettings, BannerEmailStylingSettings } from './../../models/banner-email';

export const loadBannerEmail = createAction('[Banner Email] load',
props<{
  componentGroupUuid: string,
  propertyId: string,
  typeRequest: any,
  state: any
}>());

export const loadBannerEmailSuccess = createAction('[Banner Email] loadSuccess', props<{ data: BannerEmailSettings }>());
export const loadBannerEmailFail = createAction('[Banner Email] loadFail', props<{ error: any }>());

// save changes toogle
export const commitBannerEmail = createAction('[Banner Email] commit',
props<{
  componentGroupUuid: string,
  propertyId: string,
  isActive: boolean
}>());
export const commitBannerEmailSuccess = createAction('[Banner Email] commitSuccess', props<{ data: BannerEmailSettings }>());
export const commitBannerEmailFail = createAction('[Banner Email] commitFail', props<{ error: any }>());

//styling
export const loadStylingBannerEmail = createAction('[Banner Email] load styling',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: any,
    state: any
}>());

export const loadStylingBannerEmailSuccess = createAction('[Banner Email] styling loadSuccess', props<{ data: BannerEmailStylingSettings }>());
export const loadStylingBannerEmailFail = createAction('[Banner Email] styling loadFail', props<{ error: any }>());

// save data campaigns
export const saveBannerEmail = createAction('[Banner Email] save data',
props<{
    componentGroupUuid: string,
    propertyId: string,
    typeRequest: string,
    changes: BannerEmailSettings
}>());

export const saveBannerEmailSuccess = createAction('[Banner Email] save loadSuccess');
export const saveBannerEmailFail = createAction('[Banner Email] save loadFail', props<{ error: any }>());
