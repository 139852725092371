import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Carousel3DComponent } from './components/carousel-3d/carousel-3d.component';

@NgModule({
    declarations: [
        Carousel3DComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        Carousel3DComponent
    ]
})
export class CarouselModule {
}
