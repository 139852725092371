import {
    AfterContentInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    Input
} from '@angular/core';

@Directive({
    selector: '[ftAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {
    @Input() ftAutofocus: any = true;

    constructor(private elem: ElementRef,
                private cdr: ChangeDetectorRef) {
    }

    ngAfterContentInit(): void {
        if (this.ftAutofocus !== false) {
            setTimeout(() => {
                this.elem.nativeElement.focus();
                this.cdr.detectChanges();
            }, 100);
        }
    }
}
