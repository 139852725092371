import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { DiscoveryVersions } from 'account-hybrid/features/discovery/enums/discovery-versions.enum';
import { DiscoveryThemingOption } from 'account-hybrid/features/discovery/models/discovery-theming-option.model';
import { selectDiscoveryThemingOptions } from 'account-hybrid/features/discovery/store';
import { Observable } from 'rxjs';
import { commitDiscoveryComponentSettings } from '../../store/discovery-component/discovery-component.actions';
import { DiscoveryComponentSettings } from './../../models/discovery-component/discovery-component-settings.model';
import {
    selectDiscoveryComponentSettings,
    selectDiscoveryComponentSettingsCommitSuccess,
    selectDiscoveryComponentSettingsIsLoading,
    selectDiscoveryComponentSettingsLoadingError
} from './../../store/touchpoints.selectors';

@Component({
    selector: 'ft-discovery-router',
    templateUrl: './discovery-router.component.html',
    styleUrls: ['./discovery-router.component.scss']
})
export class DiscoveryRouterComponent implements OnInit {
    discoverySettings$: Observable<DiscoveryComponentSettings>;
    commitSuccess$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    error$: Observable<any>;
    componentGroupUuid: string;
    selectedIndex = 0;
    discoveryRouterThemeOptions$: Observable<DiscoveryThemingOption[]>;
    currentDiscoveryVersion: DiscoveryVersions;

    constructor(
        private store$: Store<any>,
        private transition: Transition
    ) {
    }

    ngOnInit() {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;
        this.currentDiscoveryVersion = this.transition.params().version;
        this.discoverySettings$ = this.store$.pipe(
            select(selectDiscoveryComponentSettings)
        );
        this.discoveryRouterThemeOptions$ = this.store$.pipe(select(selectDiscoveryThemingOptions));
        this.isLoading$ = this.store$.pipe(select(selectDiscoveryComponentSettingsIsLoading));
        this.commitSuccess$ = this.store$.pipe(select(selectDiscoveryComponentSettingsCommitSuccess));
        this.error$ = this.store$.pipe(select(selectDiscoveryComponentSettingsLoadingError));
    }

    commit(settings: Partial<DiscoveryComponentSettings>) {
        this.store$.dispatch(commitDiscoveryComponentSettings({
            componentGroupUuid: this.componentGroupUuid,
            settings
        }));
    }
}
