import {
    Component,
    forwardRef,
    Input,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';


@Component({
    selector: 'ft-checkbox-editor',
    templateUrl: './checkbox-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => CheckboxEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: CheckboxEditorComponent
        }
    ]
})
export class CheckboxEditorComponent extends ModalEditorBase implements OnInit {
    @ViewChildren('control', { read: NgModel }) controls: QueryList<NgModel>;
    @Input() options: any[];
    @Input() bindLabel: string;
    @Input() bindValue: string;
    @Input() bindSummary: string;
    optionsSet: Set<any> = new Set([]);


    constructor() {
        super();
    }

    ngOnInit() {
        this.onModelInit()
            .pipe(takeUntil(this.destroySubj))
            .subscribe((value) => {
                this.optionsSet = new Set(value);
            });
    }

    isOptionChecked(option) {
        return this.optionsSet?.has(this.getOptionValue(option));
    }

    onOptionChecked(checked, option) {
        option = this.getOptionValue(option);
        if (checked) {
            this.optionsSet.add(option);
        } else {
            this.optionsSet.delete(option);
        }
        this.value = [...this.optionsSet];
    }

    isInvalid() {
        if (this.controls) {
            return !this.controls.some(control => {
                return control.valid;
            });
        }
        return super.isInvalid();
    }

    getOptionValue(option) {
        return this.bindValue ? option[this.bindValue] : option;
    }
}

