/**
 * Created by roman.kupin on 1/29/2018.
 */
import {
    getControllerInjectables,
    getTouchpointController,
    getWarmLeadNurturingController, getWarmLeadNurturingControllerInjectables
} from "./touchpointController";
import { SUPPORTED_TABS } from "./touchpoints";
import resolveTempalteUrl from "./touchpointTemplate";

const TEMPLATE_URL = require("./touchpointContainer.component.html");


declare let angular;
declare let _;
declare let TouchpointTitleProvider;
export const NAME = "ftTouchpointContainer";

export const DEFINITION = [
    "$compile", "$templateCache", "$controller", "$injector", "ftModal", "TouchpointContainer", "$timeout", "$rootScope",
    function TouchpointContainerComponent($compile, $templateCache, $controller, $injector, ftModal, TouchpointContainer, $timeout, $rootScope) {

        return {
            compile: function () {
                return function ($scope, elem, attrs) {

                    let containerTemplate,
                        containerElement;

                    async function watchTouchpointArgs(datas) {
                        const campaignUuid = datas[0],
                            propertyUuid = datas[1],
                            touchpointName = datas[2],
                            dataType = datas[3];

                        if (!campaignUuid || !propertyUuid || !touchpointName || !dataType) return;
                        try {
                            const injectables = await <Promise<{ layerName, touchpointType, touchpointGroupId }>>getControllerInjectables({
                                $injector,
                                $scope,
                                campaignUuid,
                                propertyUuid,
                                touchpointName,
                                dataType
                            });;
                            $scope.supportedTabs = SUPPORTED_TABS[touchpointName];
                            $scope.$ftTouchpointController = getTouchpointController({
                                $controller,
                                injectables,
                                touchpointName,
                                dataType
                            });

                            $scope.$ftTouchpointHeaderController = () => $injector.invoke(["endpointUrl", "Touchpoint", "touchpointActivator", "parse",
                                (endpointUrl, Touchpoint, touchpointActivator, parse) => {

                                    const touchpointsWithoutIsActiveFlag = ['finalistsEmail', 'reminderEmail', 'activityEmail', 'winnerEmail', 'homepageEmail', 'followup'];
                                    let urlFactory;
                                    switch (dataType) {
                                        case 'styling':
                                            urlFactory = _.includes(touchpointsWithoutIsActiveFlag, touchpointName) ? endpointUrl.getDataUrl : endpointUrl.getStyleUrl;
                                            break;
                                        case 'integration':
                                            urlFactory = endpointUrl.getIntegrationUrl;
                                            break;
                                        default:
                                            urlFactory = endpointUrl.getDataUrl;
                                    }
                                    const url = urlFactory(injectables.layerName, injectables.touchpointType, injectables.touchpointGroupId, propertyUuid);
                                    Touchpoint.get(url).then(function (response) {

                                        let isEnabledControl = true;
                                        // re-engagement emails tp
                                        if (angular.isDefined(response.data.isPhotoExplorerActive)) {
                                            isEnabledControl = parse.bool(response.data.isPhotoExplorerActive);
                                        }

                                        angular.extend($scope, {
                                            $ftTHC: {
                                                layerName: injectables.layerName,
                                                touchpointType: injectables.touchpointType,
                                                groupId: injectables.touchpointGroupId,
                                                isActive: response.data.isActive == 1 || response.data.isEmailActive == 1,
                                                updateState: function () {
                                                    Touchpoint.activate({
                                                        propertyUuid,
                                                        layer: $scope.$ftTHC.layerName.replace('-', ''),
                                                        touchpoint: $scope.$ftTHC.groupId,
                                                        touchpointType: $scope.$ftTHC.touchpointType,
                                                        isActive: $scope.$ftTHC.isActive
                                                    }).then(function (model) {
                                                        $rootScope.$broadcast('touchpoint.statusChanged', model);
                                                    });
                                                },
                                                canActivate: touchpointActivator.canActivate,
                                                isEnabledControl: isEnabledControl
                                            }
                                        });
                                    });
                                }]);

                            if (!containerElement) {
                                containerTemplate = $templateCache.get(TEMPLATE_URL);
                                containerElement = $compile(containerTemplate)($scope);
                                elem.append(containerElement);
                            }

                            ftModal.show("accountv2.TouchpointContainer");
                            $timeout(() => {
                                const tabContainer = angular.element(containerElement[0].querySelector("[tab-container]"));
                                tabContainer.empty();
                                const touchpointTemplate = $templateCache.get(resolveTempalteUrl(touchpointName, dataType));
                                tabContainer.append($compile(`
                                <div ng-controller="$ftTouchpointController">
                                    ${touchpointTemplate}                    
                                </div>`)($scope));

                            });

                        } catch (e) {
                            alert("Could not get touchpoint data");
                            $scope.destroyContainer();
                        }
                    }

                    async function watchCertificateEmailsArgs(datas) {
                        const campaignUuid = datas[0],
                            propertyUuid = datas[1],
                            touchpointName = datas[2],
                            dataType = datas[3];

                        if (!(!!propertyUuid && !!touchpointName && (!campaignUuid || !dataType))) return;

                        try {
                            const injectables = await getWarmLeadNurturingControllerInjectables({
                                $injector,
                                $scope,
                                propertyUuid,
                                touchpointName
                            });
                            $scope.$ftTouchpointHeaderController = () => $injector.invoke(['$rootScope', '$compile', '$q', '_', 'IdentityService', 'parse', 'Platform', 'Property', 'touchpointActivator', 'Touchpoint',
                                function ($rootScope, $compile, $q, _, identity, parse, Platform, Property, touchpointActivator, Touchpoint) {

                                    var defer = $q.defer();

                                    Platform.property({ id: propertyUuid })
                                        .then(function (data) {
                                            angular.extend($scope, {
                                                $ftTHC: {
                                                    touchpointType: touchpointName,
                                                    identity: identity,
                                                    isActive: parse.bool(data.warmLeadNurturing.isCertificateReminderEnabled),
                                                    canActivate: touchpointActivator.canActivate,
                                                    isEnabledControl: identity.isFliptoStaff(),
                                                    updateState: function () {
                                                        Property.updateSettings(propertyUuid, { isCertificateReminderEnabled: $scope.$ftTHC.isActive })
                                                            .then(function (model) {
                                                                $rootScope.$broadcast('touchpoint.statusChanged', { layer: "warn-lead-nurturing", isActive: $scope.$ftTHC.isActive });
                                                            });
                                                    }
                                                }
                                            });
                                        });
                                }]);

                            $scope.$ftTouchpointController = getWarmLeadNurturingController({
                                $controller,
                                injectables
                            });


                            if (!containerElement) {
                                containerTemplate = $templateCache.get(TEMPLATE_URL);
                                containerElement = $compile(containerTemplate)($scope);
                                elem.append(containerElement);
                            }

                            ftModal.show("accountv2.TouchpointContainer");
                            $timeout(() => {
                                const tabContainer = angular.element(containerElement[0].querySelector("[tab-container]"));
                                tabContainer.empty();
                                const touchpointTemplate = $templateCache.get(resolveTempalteUrl(touchpointName, dataType));
                                tabContainer.append($compile(`
                                <div ng-controller="$ftTouchpointController">
                                    ${touchpointTemplate}                    
                                </div>`)($scope));

                            });
                        } catch (e) {
                            alert("Could not get touchpoint data");
                            $scope.destroyContainer();
                        }

                    }

                    $scope.$watchGroup([
                        () => $scope.$eval(attrs.campaignUuid),
                        () => $scope.$eval(attrs.propertyUuid),
                        () => $scope.$eval(attrs.touchpointName),
                        () => $scope.$eval(attrs.type)
                    ], watchTouchpointArgs);

                    $scope.$watchGroup([
                        () => $scope.$eval(attrs.campaignUuid),
                        () => $scope.$eval(attrs.propertyUuid),
                        () => $scope.$eval(attrs.touchpointName),
                        () => $scope.$eval(attrs.type)
                    ], watchCertificateEmailsArgs);

                    $scope.destroyContainer = function () {
                        ftModal.hide("accountv2.TouchpointContainer");
                        TouchpointContainer.hide();
                    };

                    $scope.switchDataType = function (newDataType) {
                        $scope.$ftTouchpointContainer.dataType = newDataType;
                    };

                    $scope.isDataType = function (dataType) {
                        return $scope.$ftTouchpointContainer.dataType === dataType;
                    };

                }
            }
        }
    }
];

