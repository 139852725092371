import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentGroupType } from '../../../enums/component-group-type.enum';
import { ComponentGroup, ComponentGroupsResponse } from '../models/component-group.model';


@Injectable({
    providedIn: 'root'
})
export class ComponentGroupsService {
    isPropertyTypeSpace = this.spacesService.isPropertySpace();

    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    getComponentsGroups(): Observable<ComponentGroup[]> {
        const propertyUuid = this.spacesService.getPropertyUuid();
        const companyUuid = this.spacesService.getCompanyUuid();
        const url = this.isPropertyTypeSpace
            ? `${this.environment.apiBaseUrl}/${propertyUuid}/properties/${propertyUuid}/discovery/implementations`
            : `${this.environment.apiBaseUrl}/${companyUuid}/company/${companyUuid}/discovery/implementations` + (propertyUuid ? `?propertyUuid=${propertyUuid}` : '');
        return this.http.get<ComponentGroupsResponse>(url).pipe(
            map((componentGroups: ComponentGroupsResponse) => {
                return this.convertComponentsGroups(componentGroups);
            })
        );
    }

    addComponentGroup(name: string, componentGroupType: ComponentGroupType): Observable<ComponentGroup> {

        return this.http.put<ComponentGroup>(
            `${this.environment.apiBaseUrl}/touchpoints/discovery`, {
                name,
                componentGroupType,
                ownerCompanyUUID: componentGroupType == ComponentGroupType.Company ? this.spacesService.getCompanyUuid() : null,
                ownerPropertyUUID: componentGroupType == ComponentGroupType.Property ? this.spacesService.getPropertyUuid() : null
            }).pipe(
            // @todo discussed temporary solution with Kevin, will do mapping here, but it should be changed on API side to match component group GET request
            map((data: any) => ({ uuid: data?.componentGroupUuid || data?.componentGroupUUID, name: data?.name } as ComponentGroup))
        );
    }

    convertComponentsGroups(componentGroups: ComponentGroupsResponse): ComponentGroup[] {
        return [...new Set([...componentGroups.owned, ...componentGroups.referenced.map(item => ({
            ...item,
            isReferenced: true
        }))])].sort((a, b) => {
            if ((a?.ownerCompanyUuid && !a?.ownerPropertyUuid) && (b?.ownerCompanyUuid && b?.ownerPropertyUuid) && !(a?.isReferenced || b?.isReferenced)) {
                return -1;
            }
            if ((a?.ownerCompanyUuid && a?.ownerPropertyUuid) && (b?.ownerCompanyUuid && b?.ownerPropertyUuid) && !(a?.isReferenced || b?.isReferenced)) {
                return 1;
            }
            return 0;
        });
    }

}

