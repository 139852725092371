import { createAction, props } from '@ngrx/store';

export const toggleMany = createAction(
    '[ContextSelectorGroup] Toggle many',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
            contextId: string;
        }
    }>()
);
export const toggleSingle = createAction(
    '[ContextSelectorGroup] Toggle single',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
            contextId: string;
        }
    }>()
);
export const setSingle = createAction(
    '[ContextSelectorGroup] Set single',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
            contextId: string;
        }
    }>()
);
export const selectAll = createAction(
    '[ContextSelectorGroup] Select all',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
        }
    }>()
);
export const selectNone = createAction(
    '[ContextSelectorGroup] Select none',
    props<{
        payload: {
            contextSelectorId: string;
            groupName: string;
        }
    }>()
);
