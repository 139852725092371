import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from "rxjs";
import { AuthError } from "../../models/auth-error";

@Component({
    selector: 'ft-request-link',
    templateUrl: './request-link.component.html'
})
export class RequestLinkComponent implements OnInit {
    @Input() isLinkSent = false;
    @Input() error: AuthError;
    @Input() loading$: Observable<boolean>;
    @Output() sendLink = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

}
