import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LoadingModule } from '../loading/loading.module';
import { DropzoneUploaderComponent } from './components/dropzone-uploader/dropzone-uploader.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { UploadAreaComponent } from './components/upload-area/upload-area.component';


@NgModule({
    declarations: [
        DropzoneUploaderComponent,
        FileUploaderComponent,
        UploadAreaComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        NgxDropzoneModule,
        FileUploadModule,
        LoadingModule
    ],
    exports: [
        NgxDropzoneModule,
        FileUploadModule,
        DropzoneUploaderComponent,
        FileUploaderComponent,
        UploadAreaComponent
    ]
})
export class UploadersModule {
}



