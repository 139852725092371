import { Injectable } from '@angular/core';
import { SpacesService, SpaceType } from 'account-hybrid/common/components/spaces';
import Parse from 'v2/app/utils/parse';
import { IdentityService, LicenseType, SessionService } from './authentication';

export type CheckLicenseOperator = 'HAS_NOT' | 'HAS';


@Injectable({ providedIn: 'root' })
export class LicenseService {

    constructor(private identity: IdentityService,
        private spaceService: SpacesService,
        private sessionService: SessionService) {
    }

    checkLicense(licenses: LicenseType[], license: LicenseType, operator: CheckLicenseOperator = 'HAS') {
        if (operator === 'HAS') {
            return licenses.some(existingLicense => existingLicense === license);
        } else if (operator === 'HAS_NOT') {
            return !licenses.some(existingLicense => existingLicense === license);
        }
    }

    checkCurrentSpaceLicense(license: LicenseType, operator: CheckLicenseOperator = 'HAS') {
        if (this.identity.isAnonymous() || !this.spaceService.current) return;

        const licenses = Parse.arrayOrDefault(this.getCurrentSpaceLicenses());
        return this.checkLicense(licenses, license, operator);
    }

    private getCurrentSpaceLicenses() {
        const space = this.spaceService.current;
        switch (space.type) {
            case SpaceType.Company: {
                const company = this.sessionService.getCompany(this.spaceService.getCompanyUuid());
                return company.Licenses || [];
            }
            case SpaceType.Mixed: {
                const company = this.sessionService.getCompany(this.spaceService.getCompanyUuid());
                const property = this.sessionService.getProperty(this.spaceService.getPropertyUuid());
                return [...new Set([...(company.Licenses || []), ...(property.Licenses || [])])];
            }
            case SpaceType.Property: {
                const property = this.sessionService.getProperty(this.spaceService.getPropertyUuid());
                return property.Licenses || [];
            }
        }
    }
}
