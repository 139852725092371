import { UIRouter } from '@uirouter/core';
import { AreasRegistry, RegistryItem } from './areas.service';

export class AreasRegistryContext {
    areas: RegistryItem[];

    constructor(public registry: AreasRegistry, public $injector: any, public router: UIRouter) {
        this.areas = registry.areas;
    }

    isActive = (stateRegistryItem) => {
        const checkForState = stateRegistryItem.activeWhen || stateRegistryItem.state || stateRegistryItem.name.toLowerCase();
        return this.router.stateService.includes(checkForState);
    }


    invoke = ($event, handler) => {
        if (!!handler) {
            if ($event.cancelable) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            this.$injector.invoke(handler);
        }
    }
}
