import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { RemoteImageService } from '@flipto/shared/src/lib/services/images/remote-image.service';
import { first } from 'rxjs/operators';

@Directive({
    selector: '[ftBackgroundImage]'
})
export class BackgroundImageDirective implements OnChanges {

    constructor(
        public elemRef: ElementRef,
        public renderer: Renderer2,
        public remoteImage: RemoteImageService) {
    }

    @Input() fallbackImage: string | null;
    @Input() fallbackClass: string | null;
    @Input() checkExistence = true;
    @Input('ftBackgroundImage') backgroundImage: string | null;
    @Input() size2xImage: string;

    ngOnChanges(): void {
        this.onSetBackgroundUrl();
    }

    onSetBackgroundUrl(): void {
        if (this.checkExistence) {
            this.remoteImage.checkExistence(this.backgroundImage)
                .pipe(first())
                .subscribe(exists => {
                    if (exists) {
                        this.setBackgroundUrl(this.backgroundImage);
                    } else if (!!this.fallbackImage) {
                        this.setBackgroundUrl(this.fallbackImage);
                    } else if (!!this.fallbackClass) {
                        this.fallbackClass.split(' ').forEach(cssClass => this.addClass(cssClass));
                    }
                });
        } else {
            this.setBackgroundUrl(this.backgroundImage);
        }
    }


    private setBackgroundUrl(url: string) {
        this.renderer.setStyle(this.elemRef.nativeElement, 'background-image', this.size2xImage ? `-webkit-image-set(url('${url}') 1x, url('${this.size2xImage}') 2x), url('${url}')` : `url('${url}')`);
    }

    private addClass(cssClass: string) {
        this.renderer.addClass(this.elemRef.nativeElement, cssClass);
    }
}
