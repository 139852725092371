import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { SpacesService } from 'account-hybrid/common/components/spaces';
import { SessionService } from 'account-hybrid/core/authentication';
import { Observable } from 'rxjs';
import * as fromTouchpoints from '../../store';
import { commitBannerEmail, loadBannerEmail } from '../../store/banner-email';
import { loadStylingBannerEmail, saveBannerEmail } from './../../store/banner-email/actions';

@Component({
  selector: 'ft-banner-email',
  templateUrl: './banner-email.component.html',
  styleUrls: ['./banner-email.component.scss']
})
export class BannerEmailComponent implements OnInit {
  // touchpoints params
  propertyId:string;
  componentGroupUuid: string;
  selectedIndex = 0;
  options: any;

  // touchpoint data
  selectedBannerEmailComponent$: Observable<any>;
  commitSuccess$: Observable<any>;
  selectedBannerEmailLoading$: Observable<boolean>;
  stylingBannerEmailComponent$: Observable<any>;
  defaultBackgroundImage: string;

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private sessionService: SessionService,
    private spacesService: SpacesService,
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;
    this.defaultBackgroundImage = this.sessionService.getProperty(this.spacesService.getPropertyUuid())?.BackgroundImageUrl
    this.options = this.transition.options().custom;

    this.store$.dispatch(loadBannerEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state
    }));
    this.store$.dispatch(loadStylingBannerEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      state: this.options.state
    }));

    this.selectedBannerEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getBannerEmailData));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getBannerEmailCommitSuccess));
    this.selectedBannerEmailLoading$ = this.store$.pipe(select(fromTouchpoints.getBannerEmailLoading));
    this.stylingBannerEmailComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingBannerEmailData));
  }

  commit(isActive) {
    this.store$.dispatch(commitBannerEmail({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params, data) {
    const changes: any = [{
      languageCode: 'en',
      ...params,
    }];
    this.store$.dispatch(saveBannerEmail({
      componentGroupUuid: this.componentGroupUuid,
      propertyId: this.propertyId,
      typeRequest: this.options.type,
      changes: changes
    }));
  }

  getImageBackground(styleData) {
    return styleData.backgroundImage || this.defaultBackgroundImage;
  }
}
