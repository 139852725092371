import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    Component,
    ContentChild,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { IdentityService } from 'account-hybrid/core/authentication';
import dayjs from 'dayjs';
import { DateRange } from '../../models/date-range.model';
import { SeasonRangePipe } from './../../pipes/season-range.pipe';

@Component({
    selector: 'ft-daterange-picker',
    templateUrl: './daterange-picker.component.html',
    styleUrls: ['./daterange-picker.component.scss'],
    animations: [
        trigger('toggleRangePicker', [
            state('*', style({
                opacity: '1',
                marginTop: '0'
            })),
            state('void', style({
                opacity: '0',
                marginTop: '-20px'
            })),
            transition(':enter', animate('0.3s cubic-bezier(0, 0, 0.1, 1)')),
            transition(':leave', animate('0.1s 0.1s cubic-bezier(0, 0, 0.1, 1)')),
        ]),
        trigger('toggleCalendar', [
            state('true', style({
                width: '*',
            })),
            state('false', style({
                width: '0',
            })),
            transition('false <=> true', animate(300))
        ]),
    ]
})
export class DaterangePickerComponent implements OnChanges, OnInit {
    hoveredDate: NgbDate;
    dateFormat = 'yyyy-MM-dd';
    ngbFromDate: NgbDate;
    ngbToDate: NgbDate;
    @Input() storageKey: string;
    @Input('from') fromDate: string | Date;
    @Input('to') toDate: string | Date;
    @Input() hasSeasonRange: boolean = false;
    @Input() fullWidth = false;
    @Input() pastDays = 30;
    @Input() seasonDateType: number = 1;
    @Input() rangeLimit: number;
    @Input() darkMode = false;
    @Input() inlineMode = false;
    @Input() durationVisible = true;
    @Input() datesVisible = true;
    @Input() futureDisabled = false;
    @Input() pastDisabled = false;
    @Input() hidden = false;
    @Input() verticalAppearance: 'bottom' | 'top' = 'bottom';
    @Input() horizontalAppearance: 'left' | 'center' | 'right' = 'center';
    @Input() excludeClickOutside: string;
    @Input() excludeToday = false;
    @Output() changed = new EventEmitter<DateRange>();
    _dateRange: DateRange;
    currentYear = dayjs().year();
    dayjs = dayjs;
    isCalendarVisible = false;
    isRangePickerVisible = false;
    @ContentChild('placeholderTmpl', { static: false }) placeholderTmpl: TemplateRef<any>;
    @ContentChild('valueTmpl', { static: false }) valueTmpl: TemplateRef<any>;
    @ViewChild('dp') datepicker: NgbDatepicker;
    durationLabel: string;
    datesLabel: string;
    fromInputError: string = null;
    toInputError: string = null;
    @Input() disableAfterDate: Date;
    isFliptoStaff: boolean;

    constructor(
        private calendar: NgbCalendar,
        private seasonRangePipe: SeasonRangePipe,
        public identityService: IdentityService,
    ) {
    }

    set dateRange(dateRange: DateRange) {
        this._dateRange = dateRange;
        this.fromDate = dateRange.fromDate;
        this.toDate = dateRange.toDate;
    }

    get dateRange(): DateRange {
        return this._dateRange;
    }


    ngOnInit() {
        this.isFliptoStaff = this.identityService.isFliptoStaff();
        this.initDates();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.hasOwnProperty('fromDate') || changes.hasOwnProperty('toDate')) && (changes?.fromDate?.currentValue && changes?.toDate?.currentValue)) {
            // let's sure that date really changed, and not just format
            if (this.isDateChange(this.dateRange?.fromDate, changes.fromDate.currentValue) || (this.isDateChange(this.dateRange?.toDate, changes.toDate.currentValue))) {
                this.initDates(true);
            }
        }
    }

    isDateChange(date1: Date | string, date2: Date | string): boolean {
        return !dayjs(date1).isSame(date2);
    }

    @HostListener('click', ['$event']) handleClick(event) {
        if (!this.isRangePickerVisible) {
            this.openDateRangePicker();
            event.stopPropagation();
        }
    }

    initDates(skipStorage = false) {
        if (this.storageKey && !skipStorage) {
            this.initDatesFromStore();
        }
        if (this.fromDate && this.toDate) {
            this.ngbFromDate = this.dateToNgbDate(this.fromDate);
            this.ngbToDate = this.dateToNgbDate(this.toDate);
            this.initPastDays();
        } else if (this.pastDays) {
            this.initDatesByPastDays();
        } else {
            return;
        }
        this.onRangeSelected();
    }

    initDatesFromStore() {
        try {
            const storedRange = JSON.parse(sessionStorage.getItem(`flipto.${this.storageKey}.daterange`));
            if (storedRange && storedRange.toDate && storedRange.fromDate) {
                this.toDate = storedRange.toDate;
                this.fromDate = storedRange.fromDate;
            } else {
                sessionStorage.removeItem(`flipto.${this.storageKey}.daterange`);
            }
        } catch (e) {
            sessionStorage.removeItem(`flipto.${this.storageKey}.daterange`);
        }
    }

    storeDateRange() {
        if (this.storageKey) {
            sessionStorage.setItem(`flipto.${this.storageKey}.daterange`, JSON.stringify(this.dateRange));
        }
    }

    updateLabels() {
        const duration = this.dateRange.duration;
        this.durationLabel = this.isTillToday() ? 'Past ' : '';
        this.durationLabel += duration + (duration === 1 ? ' day' : ' days');
        const isSameYear = this.ngbToDate.year === this.ngbFromDate.year;
        this.datesLabel = `${dayjs(this.fromDate).format(isSameYear ? 'MMM D' : 'MMM D, YYYY')} - ${dayjs(this.toDate).format('MMM D, YYYY')}`;
    }

    openDateRangePicker() {
        this.isRangePickerVisible = true;
    }

    onCancel() {
        this.closeCalendar();
        this.closeDaterangePicker();
    }

    closeDaterangePicker() {
        this.isRangePickerVisible = false;
        this.closeCalendar();
    }

    openCalendar() {
        this.pastDays = null;
        this.isCalendarVisible = true;
    }

    closeCalendar() {
        this.isCalendarVisible = false;
    }

    initPastDays() {
        if (this.isTillToday()) {
            this.pastDays = dayjs(this.toDate).diff(this.fromDate, 'days') + 1;
        } else {
            this.pastDays = null;
        }
    }

    initDatesByPastDays() {
        this.ngbToDate = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
        this.ngbFromDate = this.calendar.getPrev(this.calendar.getToday(), 'd', this.pastDays);
    }

    changePastDays() {
        this.initDatesByPastDays();
        this.closeCalendar();
        this.onRangeSelected();
    }

    changeSeasonDaysRange(date?) {
        let seasonName = '';

        if (date) {
            seasonName = this.seasonRangePipe.transform(date);
            this.dateRange = DateRange.forSeason(date);
        }

        switch (this.seasonDateType) {
            case 1:
                this.dateRange = DateRange.forPast30Days();
                this.durationLabel = 'Past 30 days';
                break;
            case 2:
            case 3:
            case 4:
                this.durationLabel = `${seasonName}`;
                break;
            case 5:
                this.dateRange = DateRange.forCurrentYear();
                this.durationLabel = `${this.currentYear}`;
                break;
            case 6:
                this.dateRange = DateRange.forPastYear();
                this.durationLabel = `${this.currentYear - 1}`;
                break;
        }

        this.closeDaterangePicker();
        this.storeDateRange();
        this.changed.next(this.dateRange);
    }

    onRangeSelected() {
        this.closeDaterangePicker();
        const fromDate = this.ngbDateToDate(this.ngbFromDate);
        const toDate = this.ngbDateToDate(this.ngbToDate);
        this.dateRange = new DateRange(fromDate, toDate);
        this.storeDateRange();
        this.updateLabels();
        this.changed.next(this.dateRange);
    }

    dateToNgbDate(date: string | Date) {
        let year;
        let month;
        let day;
        [year, month, day] = dayjs(date).format('YYYY-MM-DD').split('-');
        return new NgbDate(
            +year,
            +month,
            +day
        );
    }

    ngbDateToDate(date: NgbDate) {
        const year = date.year;
        const month = ('0' + date.month).slice(-2);
        const day = ('0' + date.day).slice(-2);
        return `${year}-${month}-${day}`;
    }

    setInputFromDate(date: string) {
        if (date) {
            const ngbDate = this.dateToNgbDate(date);
            this.ngbFromDate = null;

            if (this.isLimitedByFuture(ngbDate)) {
                this.showValidationError('fromInputError', 'future');
            } else if (this.isLimitedByAfterDate(ngbDate)) {
                this.showValidationError('fromInputError', 'afterDate');
            } else if (this.isLimitedByPast(ngbDate)) {
                this.showValidationError('fromInputError', 'past');
            } else if (this.isLimitedByRange(ngbDate) || ngbDate.after(this.ngbToDate)) {
                this.showValidationError('fromInputError', 'range');
            } else {
                this.ngbFromDate = ngbDate;
                this.fromInputError = null;
            }

            this.navigateToStartDate();
            this.datepicker.focus();
        }
    }

    setInputToDate(date: string) {
        if (date) {
            const ngbDate = this.dateToNgbDate(date);
            this.ngbToDate = null;

            if (this.isLimitedByFuture(ngbDate)) {
                this.showValidationError('toInputError', 'future');
            } else if (this.isLimitedByAfterDate(ngbDate)) {
                this.showValidationError('toInputError', 'afterDate');
            } else if (this.isLimitedByPast(ngbDate)) {
                this.showValidationError('toInputError', 'past');
            } else if (this.isLimitedByRange(ngbDate) || ngbDate.before(this.ngbFromDate)) {
                this.showValidationError('toInputError', 'range');
            } else {
                this.ngbToDate = ngbDate;
                this.toInputError = null;
            }
            this.navigateToEndDate();
            this.datepicker.focus();
        }
    }

    onDateSelection(date: NgbDate) {
        if (!this.isDisabled(date)) {
            if (!this.ngbFromDate && !this.ngbToDate) {
                this.ngbFromDate = date;
            } else if (this.ngbFromDate && !this.ngbToDate) {
                this.ngbToDate = date;
            } else if (this.ngbToDate && !this.ngbFromDate) {
                this.ngbFromDate = date;
            } else {
                this.ngbFromDate = date;
                this.ngbToDate = null;
            }
        }
    }

    isHovered(date: NgbDate) {
        return this.ngbFromDate && !this.ngbToDate && this.hoveredDate && date.after(this.ngbFromDate) && date.before(this.hoveredDate)
            || !this.ngbFromDate && this.ngbToDate && this.hoveredDate && date.before(this.ngbToDate) && date.after(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.ngbFromDate) && date.before(this.ngbToDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.ngbFromDate) || date.equals(this.ngbToDate) || this.isInside(date) || this.isHovered(date);
    }

    isDisabled(date: NgbDate) {
        if (!date) {
            return false;
        }
        if (this.isLimitedByRange(date)) {
            return true;
        } else if (this.isLimitedByFuture(date)) {
            return true;
        } else if (this.isLimitedByPast(date)) {
            return true;
        } else if (this.isLimitedByAfterDate(date)) {
            return true;
        }
        return false;
    }

    isLimitedByRange(date: NgbDate) {
        if (!this.rangeLimit || !date) {
            return false;
        }
        if (this.ngbFromDate && !this.ngbToDate) {
            const limitDate =  this.calendar.getNext(this.ngbFromDate, 'd', this.rangeLimit - 1);
            return date.after(limitDate);
        }
        if (!this.ngbFromDate && this.ngbToDate) {
            const limitDate = this.calendar.getPrev(this.ngbToDate, 'd', this.rangeLimit - 1);
            return date.before(limitDate);
        }
        return false;
    }

    isLimitedByFuture(date: NgbDate) {
        if (this.futureDisabled) {
            return (this.excludeToday && date.equals(this.calendar.getToday())) || date.after(this.calendar.getToday());
        }
        return false;
    }

    isLimitedByPast(date: NgbDate) {
        if (this.rangeLimit && this.pastDisabled) {
            const pastLimitDate = this.getPastLimitDate();
            return date.before(pastLimitDate);
        }
        return false;
    }

    isLimitedByAfterDate(date: NgbDate) {
        if (this.disableAfterDate && !this.isFliptoStaff) {
            return date.after(this.dateToNgbDate(this.disableAfterDate));
        }

        return false;
    }

    isTillToday() {
        const yesterday = this.calendar.getPrev(this.calendar.getToday(), 'd', 1);
        return this.ngbToDate.equals(yesterday);
    }

    getPastLimitDate() {
        return this.calendar.getPrev(this.calendar.getToday(), 'd', this.rangeLimit);
    }

    navigateToStartDate() {
        if (this.datepicker && this.ngbFromDate) {
            this.datepicker.navigateTo(this.ngbFromDate);
        }
    }

    navigateToEndDate() {
        if (this.datepicker && this.ngbToDate) {
            this.datepicker.navigateTo(this.calendar.getPrev(this.ngbToDate, 'd', 31));
        }
    }

    onHover(date) {
        this.hoveredDate = date;

        if (this.ngbFromDate && !this.ngbToDate) {
            if (date.before(this.ngbFromDate)) {
                this.ngbToDate = this.ngbFromDate;
                this.ngbFromDate = null;
            }
        }

        if (!this.ngbFromDate && this.ngbToDate) {
            if (date.after(this.ngbToDate)) {
                this.ngbFromDate = this.ngbToDate;
                this.ngbToDate = null;
            }
        }
    }

    showValidationError(errorDist: string, errorKey: string) {
        setTimeout(() => {
            this[errorDist] = this.validationErrors[errorKey];
        }, 100);
    }

    get validationErrors() {
        return {
            range: `Allowed date range limit is ${this.rangeLimit} days.`,
            future: 'Unable to select date from future',
            past: 'Unable to select date from past',
            afterDate: `Unable to select date after (${dayjs(this.disableAfterDate).format('YYYY-MM-DD')})}`
        };
    }
}
