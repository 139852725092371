import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DiscoveryApiService } from "account-hybrid/common/components/touchpoints/services/discovery-api.service";
import * as fromTouchpoints from 'account-hybrid/common/components/touchpoints/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
    commit,
    commitFail,
    commitSuccess,
    create,
    createFail,
    createSuccess,
    load,
    loadFail,
    loadSuccess
} from './actions';


@Injectable()
export class BookingbarEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(load),
            mergeMap(({componentGroupUuid}) =>
                this.discoveryApiService.getComponentSettings(componentGroupUuid, 'bookingbar')
                    .pipe(
                        map(data => {
                            return loadSuccess({data: data[0]});
                        }),
                        catchError(error => of(loadFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commit),
            withLatestFrom(
                this.store$.pipe(select(fromTouchpoints.selectBookingbar))
            ),
            mergeMap(([{data, componentGroupUuid}, bookingbarState]) =>
                this.discoveryApiService.updateComponentSettings(componentGroupUuid, 'bookingbar', bookingbarState.settings.componentUUID, data)
                    .pipe(
                        map(settings => {
                            return commitSuccess({data: settings});
                        }),
                        catchError(error => of(commitFail({error})))
                    ))
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(create),
            mergeMap(action =>
                this.discoveryApiService.createComponent(action.componentGroupUuid, 'bookingbar', action.data)
                    .pipe(
                        map(data => createSuccess({data})),
                        catchError(error => of(createFail({error})))
                    ))
        )
    );

    constructor(private actions$: Actions,
                private store$: Store<any>,
                private discoveryApiService: DiscoveryApiService) {
    }
}


