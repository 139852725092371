import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalWrapperComponent } from './components/modal-wrapper/modal-wrapper.component';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';
import { TargetsModalDirective } from './targetsmodal.directive';

@NgModule({
    declarations: [
        TargetsModalDirective,
        ModalComponent,
        ModalWrapperComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        TargetsModalDirective,
        ModalComponent,
        ModalWrapperComponent
    ],
    providers: []
})
class ModalModule {
}

export {
    ModalComponent, ModalModule, ModalService, TargetsModalDirective
};

