import { StoryExplorerStylingSettings } from './../../models/story-explorer';
import { loadStylingStoryExplorerFail, saveStoryExplorer, saveStoryExplorerFail, saveStoryExplorerSuccess } from './actions';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { commitStoryExplorer, commitStoryExplorerFail, commitStoryExplorerSuccess, loadStoryExplorer, loadStoryExplorerFail, loadStoryExplorerSuccess, loadStylingStoryExplorer, loadStylingStoryExplorerSuccess } from '.';
import { StoryExplorerIntegrationSettings } from '../../models/story-explorer';
import { JourneysApiService } from '../../services/journeys-api.service';

@Injectable()
export class StoryExplorerEffects {
    constructor(private actions$: Actions,
        private store$: Store<any>,
        private journeysApiService: JourneysApiService) {
    }

    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStoryExplorer),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getTouchpointIntegrationStoryExplorerData(componentGroupUuid, propertyId)
                    .pipe(
                        map((settings: StoryExplorerIntegrationSettings) => {
                            return loadStoryExplorerSuccess({data: settings});
                        }),
                        catchError(error => of(loadStoryExplorerFail({error})))
                    ))
        )
    );

    commit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commitStoryExplorer),
            mergeMap(({componentGroupUuid, propertyId, isActive}) =>
                this.journeysApiService.commitTouchpointConfirmationSettings(componentGroupUuid, propertyId, isActive)
                    .pipe(
                        map((settings: StoryExplorerIntegrationSettings) => {
                            return commitStoryExplorerSuccess({data: settings});
                        }),
                        catchError(error => of(commitStoryExplorerFail({error})))
                    ))
        )
    );

    loadStyling$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadStylingStoryExplorer),
            mergeMap(({componentGroupUuid, propertyId}) =>
                this.journeysApiService.getTouchpointStylingStoryExplorerData(componentGroupUuid, propertyId)
                    .pipe(
                        map((stylingSettings: StoryExplorerStylingSettings) => {
                            return loadStylingStoryExplorerSuccess({data: stylingSettings});
                        }),
                        catchError(error => of(loadStylingStoryExplorerFail({error})))
                    ))
        )
    );

    saveChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveStoryExplorer),
            withLatestFrom(this.store$),
            mergeMap(
                ([{ componentGroupUuid, propertyId, changes, tab }]) => this.journeysApiService.saveTouchpointIntegrationStoryExplorerData(
                    componentGroupUuid, propertyId, changes, tab
                ).pipe(map(() => {
                    return saveStoryExplorerSuccess();
                }),
                    catchError(error => {
                        return of(saveStoryExplorerFail(error));
                    })
                )
            )
        )
    );
}
