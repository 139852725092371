import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, skip, takeWhile } from 'rxjs/operators';

@Directive({
    selector: '[ftLoading]'
})
export class LoadingDirective implements OnInit, OnDestroy, OnChanges {
    initialText: string;
    @Input() ftLoading: Observable<boolean> | Array<Observable<boolean>> | boolean;
    @Input() loadingText = '';
    isAlive = true;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    ngOnInit() {
        this.initialText = this.elementRef.nativeElement.innerHTML;
        if (typeof this.ftLoading !== 'boolean') {
            this.handleSubscription(this.ftLoading);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.ftLoading.firstChange && typeof this.ftLoading === 'boolean') {
            this.handleLoading(this.ftLoading);
        }
    }

    handleSubscription(loading$: any) {
        const loadings: Observable<boolean>[] = Array.isArray(loading$) ? loading$ : [loading$];
        combineLatest(loadings).pipe(
          skip(1),
          takeWhile(() => this.isAlive),
          map((isLoadings: boolean[]) => {
              if (isLoadings.some((isLoading) => isLoading === true)) {
                  return true;
              }
              if (isLoadings.every((isLoading) => isLoading === false)) {
                  return false;
              }
          })
        ).subscribe((isLoading) => {
            this.handleLoading(isLoading);
        });
    }

    handleLoading(isLoading) {
        if (isLoading) {
            const elementWidth = this.elementRef.nativeElement.offsetWidth + 'px';
            this.renderer.setStyle(
              this.elementRef.nativeElement,
              'min-width',
              elementWidth
            );
            this.initialText = this.elementRef.nativeElement.innerHTML;
            this.elementRef.nativeElement.innerHTML = `<span class="ft2-spinner"></span> ${this.loadingText}`;
            this.elementRef.nativeElement.disabled = true;
        } else {
            this.elementRef.nativeElement.innerHTML = this.initialText;
            this.renderer.removeStyle(
              this.elementRef.nativeElement,
              'min-width'
            );
            this.elementRef.nativeElement.disabled = false;
        }
    }
}

