import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { UrlFormatter } from '@flipto/shared/src/lib/utils/common/url-formatter';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { IdentityService } from 'account-hybrid/core/authentication';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { ConfirmationExperienceDisplayId, ConfirmationExperienceIntegrationSettings, ConfirmationExperienceSettings, ConfirmationExperienceStylingSettings, ConfirmationTimerActionTypes } from '../../models/confirmation-experience';
import { JourneysApiService } from '../../services/journeys-api.service';
import * as fromTouchpoints from '../../store';
import {
    changeConfirmationExperience,
    commitConfirmationExperience,
    loadConfirmationExperience,
    loadIntegrationConfirmationExperience,
    loadStylingConfirmationExperience
} from '../../store/confirmation-experience';

@Component({
  selector: 'ft-confirmation-experience',
  templateUrl: './confirmation-experience.component.html',
  styleUrls: ['./confirmation-experience.component.scss']
})
export class ConfirmationExperienceComponent implements OnInit {
  @ViewChild('input', { read: NgModel, static: false }) input: NgModel;
  propertyId: string;
  componentGroupUuid: string;
  selectedConfirmationExperienceComponent$: Observable<ConfirmationExperienceSettings>;
  stylingConfirmationExperienceComponent$: Observable<ConfirmationExperienceStylingSettings>;
  integrationConfirmationExperienceComponent$: Observable<ConfirmationExperienceIntegrationSettings>;
  selectedConfirmationExperienceLoading$: Observable<boolean>;
  rateCodes$: Observable<string[]>;
  commitSuccess$: Observable<any>;
  selectedIndex = 0;
  text: string = '';
  confirmationExperienceDisplayId = ConfirmationExperienceDisplayId;
  confirmationTimerActionTypes = ConfirmationTimerActionTypes;
  insertionType = [
    { id: "0", name: "Appears before the element" },
    { id: "1", name: "Appears inside the element as the first child" },
    { id: "2", name: "Appears inside the element as the last child" },
    { id: "3", name: "Appears after the element" }
  ];
  bookingEngines = [];
  engineCodes = [];
  sharedMessageTokens = [
    {name: 'property', value: '{property}'},
    {name: 'city', value: '{city}'},
    {name: 'first', value: '{first}'},
    {name: 'last', value: '{last}'},
    {name: 'incentive', value: '{incentive}'},
    {name: 'incentive-image', value: '{incentive-image}'},
    {name: 'incentive-short', value: '{incentive-short}'}
  ];

  constructor(
    private store$: Store<fromTouchpoints.State>,
    private transition: Transition,
    private journeysApiService: JourneysApiService,
    private identityService: IdentityService,
    private actions$: Actions,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) { }

  ngOnInit() {
    this.propertyId = this.transition.params().propertyId;
    this.componentGroupUuid = this.transition.params().componentGroupUuid;

    this.store$.dispatch(loadConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));
    this.store$.dispatch(loadStylingConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));
    this.store$.dispatch(loadIntegrationConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));

    this.selectedConfirmationExperienceComponent$ = this.store$.pipe(select(fromTouchpoints.getConfirmationExperienceData));
    this.stylingConfirmationExperienceComponent$ = this.store$.pipe(select(fromTouchpoints.getStylingConfirmationExperienceData));
    this.integrationConfirmationExperienceComponent$ = this.store$.pipe(select(fromTouchpoints.getIntegrationConfirmationExperienceData));
    this.selectedConfirmationExperienceLoading$ = this.store$.pipe(select(fromTouchpoints.getConfirmationExperienceLoading));
    this.commitSuccess$ = this.store$.pipe(select(fromTouchpoints.getConfirmationExperienceCommitSuccess));
    this.rateCodes$ = this.journeysApiService.getConfirmationRateCodes(this.componentGroupUuid);
    this.journeysApiService.getBookingEngines().subscribe((res: any) => {
      this.bookingEngines = res.item;
    });
  }

  commit(isActive) {
    this.store$.dispatch(commitConfirmationExperience({
        componentGroupUuid: this.componentGroupUuid,
        propertyId: this.propertyId,
        isActive: isActive
    }));
  }

  onFormCommit(params: any, tab: string, id?: string) {
    let changes: any;
    if (tab === 'data') {
      const value = params.header ? params.header : params;
      changes = [{
        languageCode: 'en',
        ...value
      }];
    }

    if (tab === 'styling') {
      changes = [{
        bookingEngineUUID: id,
        ...params
      }];
    }

    this.store$.dispatch(changeConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId, changes, tab}));
  }

  updateRateCodes(params: {rateCodes: string[]}) {
    this.journeysApiService.updateConfirmationRateCodes(this.componentGroupUuid, params.rateCodes).subscribe();
  }

  onSaveIntagration(changes) {
    this.journeysApiService.updateIntegrationConfirmationData(this.componentGroupUuid, this.propertyId, changes).subscribe(() => {
      this.store$.dispatch(loadIntegrationConfirmationExperience({componentGroupUuid: this.componentGroupUuid, propertyId: this.propertyId}));
    });
  }

  openConfirmationLivePreview(code, fullCode) {
    const url = UrlFormatter.appendObjectToQuery(`${this.environment.v1BaseUrl}demo/prestay/confirmation?debug=1`, {
      lang: code,
      cid: fullCode,
      email: this.identityService.user.email,
      first: this.identityService.user.firstName,
      last: this.identityService.user.lastName
    })
    window.open(url);
  };

  getEngineCodes(itemsArr) {
    return itemsArr.map(function(item) {
      return item['code'];
    });
  }

  saveBookingEngine(params, engine) {
    let oldArray = this.getEngineCodes(engine.codes.item);
    let newCodes = _.difference(params.engineCodes, oldArray);
    let changes = [{
      bookingEngineTypeID: params.type ? params.type : engine.type,
      bookingEngineUUID: engine.id,
      codes: {newCodes: newCodes, deleteCodes: []},
      isActive: params.hasOwnProperty('isActive')
        ? params.isActive
        : null
    }];
    this.onSaveIntagration(changes);
  }

  saveAppMode(params, engine) {
    let changes = [{
      bookingEngineTypeID: engine.type,
      bookingEngineUUID: engine.id,
      isShowIncentiveOnIntro: Number.isInteger(engine.isShowIncentiveOnIntro) ? Boolean(Number(engine.isShowIncentiveOnIntro)) : engine.isShowIncentiveOnIntro,
      timerActionType: engine.timerActionType,
      isTimerEnabled: Number.isInteger(engine.isTimerEnabled) ? Boolean(Number(engine.isTimerEnabled)) : engine.isTimerEnabled,
    }];
    this.onSaveIntagration(changes);
  }
}
