import { Action, createReducer, on } from '@ngrx/store';
import { DiscoveryVisibility } from 'account-hybrid/features/mission-control/enums';
import { DateRange } from 'apps/account/src/account-shared';
import { ThemeType } from 'shared';
import { DiscoveryVersions } from '../enums/discovery-versions.enum';
import {
    combineTrafficAndPlannerStats,
    loadCompletePerformanceStats,
    loadCompletePerformanceStatsFailure,
    loadCompletePerformanceStatsSuccess,
    loadDiscoveryConversionReport,
    loadDiscoveryConversionReportFailuire,
    loadDiscoveryConversionReportSuccess, loadDiscoveryVersioning,
    loadDiscoveryVersioningFailure,
    loadDiscoveryVersioningSuccess,
    loadEmailStats,
    loadEmailStatsFailure,
    loadEmailStatsSuccess,
    loadExperiments,
    loadExperimentsFailure,
    loadExperimentsSuccess,
    loadExperimentStats,
    loadExperimentStatsFailure,
    loadExperimentStatsSuccess,
    loadPerformanceStats,
    loadPerformanceStatsFailure,
    loadPerformanceStatsSuccess,
    loadPlannerStats,
    loadPlannerStatsFailure,
    loadPlannerStatsSuccess,
    loadPlannerStatsYTD,
    loadPlannerStatsYTDFailure,
    loadPlannerStatsYTDSuccess,
    loadTimelineStats,
    loadTimelineStatsFailure,
    loadTimelineStatsSuccess,
    loadTrafficStats,
    loadTrafficStatsFailure,
    loadTrafficStatsSuccess,
    setCurrentDisplayedDiscoveryVersion,
    setDateRange,
    setPerformanceSectionDateRange
} from './discovery.actions';
import { DiscoveryState } from './discovery.state';


export const discoveryInitialState: DiscoveryState = {
    versioning: null,
    discoveryThemingOptions: [...Object.values(ThemeType).filter(theme => !isNaN(theme as number) && theme === ThemeType.Light).map((theme: ThemeType) => ({
        value: theme,
        name: ThemeType[theme]
    }))],
    currentDisplayedVersion: DiscoveryVersions.V1,
    experiments: null,
    experimentDateRange: null,
    dateRangeYTD: DateRange.forYearToDate(),
    performanceSectionDateRange: null,
    stats: [],
    performanceStats: [],
    completePerformanceStats: [],
    experimentsError: null,
    experimentsLoading: false,
    statsLoading: false,
    statsError: null,
    performanceStatsLoading: false,
    performanceStatsError: null,
    completePerformanceLoading: false,
    completePerformanceError: null,
    // new
    plannerStats: null,
    plannerStatsLoading: false,
    plannerStatsError: null,

    plannerStatsYTD: null,
    plannerStatsYTDLoading: false,
    plannerStatsYTDError: null,

    conversionReport: null,
    conversionReportIsLoading: false,
    conversionReportError: null,

    trafficStats: null,
    trafficStatsLoading: false,
    trafficStatsError: null,

    timelineStats: null,
    timelineStatsLoading: false,
    timelineStatsError: null,

    emailStats: null,
    emailStatsLoading: false,
    emailStatsError: null
};

const reducer = createReducer(
    discoveryInitialState,
    on(loadExperiments, state => {
        return {
            ...state,
            experimentsError: null,
            experimentsLoading: true
        };
    }),
    on(loadExperimentsSuccess, (state, { experiments }) => {
        return {
            ...state,
            experimentsLoading: false,
            experimentsError: null,
            experiments
        };
    }),
    on(loadExperimentsFailure, (state, { error }) => {
        return {
            ...state,
            experimentsLoading: false,
            experimentsError: error,
            experiments: null
        };
    }),
    on(loadExperimentStats, state => {
        return {
            ...state,
            stats: [],
            statsError: null,
            statsLoading: true
        };
    }),
    on(loadExperimentStatsSuccess, (state, { stats }) => {
        return {
            ...state,
            statsLoading: false,
            stats
        };
    }),
    on(loadExperimentStatsFailure, (state, { error }) => {
        return {
            ...state,
            statsLoading: false,
            statsError: error
        };
    }),
    on(loadPerformanceStats, state => {
        return {
            ...state,
            performanceStatsError: null,
            performanceStatsLoading: true
        };
    }),
    on(loadPerformanceStatsSuccess, (state, { performanceStats }) => {
        return {
            ...state,
            performanceStatsLoading: false,
            performanceStats
        };
    }),
    on(loadPerformanceStatsFailure, (state, { error }) => {
        return {
            ...state,
            performanceStatsLoading: false,
            performanceStatsError: error
        };
    }),
    on(loadCompletePerformanceStats, state => {
        return {
            ...state,
            completePerformanceError: null,
            completePerformanceLoading: true
        };
    }),
    on(loadCompletePerformanceStatsSuccess, (state, { completePerformanceStats }) => {
        return {
            ...state,
            completePerformanceLoading: false,
            completePerformanceStats
        };
    }),
    on(loadCompletePerformanceStatsFailure, (state, { error }) => {
        return {
            ...state,
            completePerformanceLoading: false,
            completePerformanceError: error
        };
    }),
    on(setDateRange, (state, { experimentDateRange }) => {
        return {
            ...state,
            experimentDateRange
        };
    }),
    on(setPerformanceSectionDateRange, (state, { performanceDateRange }) => {
        return {
            ...state,
            performanceSectionDateRange: performanceDateRange,
            performanceStatsLoading: true
        };
    }),
    // new discovery
    on(loadTimelineStats, state => {
        return {
            ...state,
            timelineStatsError: null,
            timelineStatsLoading: true
        };
    }),
    on(loadTimelineStatsSuccess, (state, { timelineStats }) => {
        return {
            ...state,
            timelineStatsLoading: false,
            timelineStats
        };
    }),
    on(loadTimelineStatsFailure, (state, { error }) => {
        return {
            ...state,
            timelineStatsLoading: false,
            timelineStatsError: error
        };
    }),
    on(loadDiscoveryConversionReport, state => {
        return {
            ...state,
            conversionReport: null,
            conversionReportIsLoading: true,
            conversionReportError: null
        };
    }),
    on(loadDiscoveryConversionReportSuccess, (state, { conversionReport }) => {
        return {
            ...state,
            conversionReport,
            conversionReportError: null,
            conversionReportIsLoading: false
        };
    }),
    on(loadDiscoveryConversionReportFailuire, (state, { error }) => {
        return {
            ...state,
            conversionReport: null,
            conversionReportIsLoading: false,
            conversionReportError: error
        };
    }),
    on(loadPlannerStats, state => {
        return {
            ...state,
            plannerStats: null,
            trafficStats: null,
            emailStats: null,
            plannerStatsError: null,
            plannerStatsLoading: true
        };
    }),
    on(loadPlannerStatsSuccess, (state, { plannerStats }) => {
        return {
            ...state,
            plannerStatsLoading: false,
            plannerStats
        };
    }),
    on(loadPlannerStatsFailure, (state, { error }) => {
        return {
            ...state,
            plannerStatsLoading: false,
            plannerStatsError: error
        };
    }),
    on(combineTrafficAndPlannerStats, (state, { plannerStats }) => {
        return {
            ...state,
            plannerStatsLoading: false,
            plannerStats
        };
    }),
    on(loadPlannerStatsYTD, state => {
        return {
            ...state,
            plannerStatsYTD: null,
            plannerStatsYTDError: null,
            plannerStatsYTDLoading: true
        };
    }),
    on(loadPlannerStatsYTDSuccess, (state, { plannerStatsYTD }) => {
        return {
            ...state,
            plannerStatsYTDLoading: false,
            plannerStatsYTD
        };
    }),
    on(loadPlannerStatsYTDFailure, (state, { error }) => {
        return {
            ...state,
            plannerStatsYTDLoading: false,
            plannerStatsYTDError: error
        };
    }),
    on(loadTrafficStats, state => {
        return {
            ...state,
            trafficStats: null,
            trafficStatsError: null,
            trafficStatsLoading: true
        };
    }),
    on(loadTrafficStatsSuccess, (state, { trafficStats }) => {
        return {
            ...state,
            trafficStatsLoading: false,
            trafficStats
        };
    }),
    on(loadTrafficStatsFailure, (state, { error }) => {
        return {
            ...state,
            trafficStatsLoading: false,
            trafficStatsError: error
        };
    }),
    // email stats
    on(loadEmailStats, state => {
        return {
            ...state,
            emailStats: null,
            emailStatsError: null,
            emailStatsLoading: true
        };
    }),
    on(loadEmailStatsSuccess, (state, { emailStats }) => {
        return {
            ...state,
            emailStatsLoading: false,
            emailStats
        };
    }),
    on(loadEmailStatsFailure, (state, error) => {
        return {
            ...state,
            emailStatsLoading: false,
            emailStatsError: error
        };
    }),
    on(setCurrentDisplayedDiscoveryVersion, (state, action) => {
        // set default version if wrong was sent
        let versionToSet = Object.values(DiscoveryVersions).filter((v) => !isNaN(Number(v))).some(version => version == (action.version || state?.versioning?.discoveryVersionCurrent))
            ? +action.version
            : DiscoveryVersions.V1;
        versionToSet = isNaN(versionToSet) ? DiscoveryVersions.V1 : versionToSet;
        return {
            ...state,
            currentDisplayedVersion: versionToSet,
            discoveryThemingOptions: [...state.discoveryThemingOptions.filter(theme => theme.value === ThemeType.Light)]
        };
    }),
    on(loadDiscoveryVersioning, state => ({
        ...state,
        currentDisplayedVersion: discoveryInitialState.currentDisplayedVersion
    })),
    on(loadDiscoveryVersioningSuccess, (state, action) => {
        const versionToDisplay = state.currentDisplayedVersion < action.versioning?.discoveryVersionCurrent ? action.versioning?.discoveryVersionCurrent : state.currentDisplayedVersion;
        return {
            ...state,
            versioning: action.versioning,
            currentDisplayedVersion: isNaN(versionToDisplay) ? DiscoveryVersions.V1 : versionToDisplay
        };
    }),
    on(loadDiscoveryVersioningFailure, state => ({
        ...state,
        versioning: {
            discoveryVersionCurrent: DiscoveryVersions.V1,
            isUpgradeAvailable: false,
            discoveryVisibility: DiscoveryVisibility.Hidden
        }
    }))
);

export function discoveryReducer(state: DiscoveryState | undefined, action: Action) {
    return reducer(state, action);
}
