import dayjs from "dayjs";
import { Experiment, ExperimentResponse } from "../models/experiment.model";

class ExperimentFactory {
    createExperiment(experiment: ExperimentResponse, index = 0): Experiment {
        const is_active = experiment.start_date && !experiment.end_date;
        const uuid = experiment.uuid;
        const name = index ? `Experiment ${index}` : null;
        const start_date = dayjs(experiment.start_date).format('YYYY-MM-DD');
        const end_date = dayjs(experiment.end_date).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD');
        const percentage = [Math.round(experiment.percentage), 100 - Math.round(experiment.percentage)];
        const duration = dayjs(experiment.end_date).diff(experiment.start_date, 'days');
        return {
            uuid,
            name,
            start_date,
            end_date,
            percentage,
            duration,
            is_active
        };
    }

    createExperiments(experiments: ExperimentResponse[]): Experiment[] {
        return experiments.map((experiment, index) => {
            return this.createExperiment(experiment, index + 1);
        });
    }
}

export const experimentsFactory = new ExperimentFactory();
