/**
 * Created by roman.kupin on 2/20/2018.
 */

import { getSpaceAttributesFromSpace } from 'account-hybrid/common/components/spaces';
import { stateGo } from "redux-ui-router";

export const PREFIX = "[AdminReports]";

export const INITIALIZED = `${PREFIX}.Initialized`;

export function navigateToAdminReports() {
    return (dispatch, getState, { SpacesService }) => {
        return dispatch(stateGo("admin-reports", getSpaceAttributesFromSpace(SpacesService.current)));
    };
}

export function initialized(value) {
    return dispatch => {
        return dispatch({ type: INITIALIZED, payload: value });
    }
}

const All = { navigateToAdminReports, initialized };
export default All;