import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';
import { InputFieldType } from '../../../types/input-field.type';

@Component({
    selector: 'ft-list-editor',
    templateUrl: './list-editor.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => ListEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: ListEditorComponent
        }
    ]
})
export class ListEditorComponent extends ModalEditorBase implements OnChanges, OnInit {
    @ViewChild('input', { read: NgModel, static: false }) input: NgModel;
    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
    @Input() controlName = 'formControl';
    @Input() email: boolean;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() min: number;
    @Input() max: number;
    @Input() pattern: string;
    @Input() patternValidationText: string;
    @Input() type: InputFieldType = 'text';
    @Input() placeholder = '';
    @Input() options: any[];
    @Input() bindValue: string;
    @Input() bindLabel: string;


    newValue: string;

    @Input() caseSensitive = false;
    @Input() allowDuplicates = false;
    hasDuplicatesError = false;


    constructor() {
        super();
    }

    addItem() {
        if (!this.input?.invalid && this.newValue) {
            this.validateDuplicates();
            if (!this.hasDuplicatesError) {
                this.value = [...this.value, this.newValue];
                this.newValue = null;
            }
        }
    }

    validateDuplicates() {
        if (!this.allowDuplicates) {
            this.hasDuplicatesError = false;
            this.value?.forEach(item => {
                if (this.caseSensitive) {
                    if (this.bindLabel ? (item[this.bindLabel] === this.newValue[this.bindLabel]) : (item === this.newValue)) {
                        this.hasDuplicatesError = true;
                    }
                } else {
                    if (this.bindLabel ? (item[this.bindLabel].toLowerCase() === this.newValue[this.bindLabel].toLowerCase()) : (item.toLowerCase() === this.newValue.toLowerCase())) {
                        this.hasDuplicatesError = true;
                    }
                }
            });
        }
    }

    removeItem(itemIndex) {
        this.value = this.value.filter((val, index) => itemIndex !== index);
    }

    ngOnInit(): void {
        if (this.type === 'url') {
            this.patternValidationText = 'Value is not valid url.';
            this.pattern = 'https?://.+';
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.type) {
            this.email = changes.type.currentValue === 'email';
        }
    }

    onSelect(event) {
        console.log(event);
    }
}


