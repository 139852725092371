/**
 * Created by roman.kupin on 9/12/2017.
 */

// import {AREAS_REGISTRY, RegistryItem} from "./areasRegistry.service";
import { AREAS_REGISTRY, RegistryItem } from 'apps/account/src/account-shared';


export const NAME = "ftAreas";

export class AreasController {

    static $inject = ["$state", "$injector"];

    registry: RegistryItem[];

    constructor(public $state, public $injector) {
        this.$state = $state;
        this.$injector = $injector;
    }

    setupRegistry(registryService: string) {
        this.registry = this.$injector.get(registryService).areas;
    }

    isActive(stateRegistryItem): boolean {
        const checkForState = stateRegistryItem.activeWhen || stateRegistryItem.state || stateRegistryItem.name.toLowerCase();
        return this.$state.includes(checkForState);
    }

    invoke($event, handler) {
        if (!!handler) {
            if ($event.cancelable) {
                $event.preventDefault();
                $event.stopPropagation();
            }
            this.$injector.invoke(handler);
        }
    }
}


export const DEFINITION = {
    scope: true,
    controller: AreasController,
    controllerAs: "$ftAreas",
    bindToController: true,
    require: "ftAreas",
    link: function AreasLinkFn(scope, elem, attrs, ctrl: AreasController) {
        ctrl.setupRegistry(!!attrs[NAME].length ? attrs[NAME] : AREAS_REGISTRY);
    }
};

