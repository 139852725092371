import { Action, createReducer, on } from "@ngrx/store";
import {
    commitBannerPrearrivalEmail,
    commitBannerPrearrivalEmailSuccess,
    loadStylingPrearrivalEmail,
    loadStylingPrearrivalEmailFail,
    loadStylingPrearrivalEmailSuccess,
    saveBannerPrearrivalEmail,
    saveBannerPrearrivalEmailFail,
    saveBannerPrearrivalEmailSuccess
} from './actions';
import { State } from "./state";

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        commitSuccess: false,
        stylingSettings: null
    } as State,
   // styling
   on(loadStylingPrearrivalEmail, (state) => {
    return {
        ...state,
        loading: true
    };
    }),
    on(loadStylingPrearrivalEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            stylingSettings: data
        };
    }),
    on(loadStylingPrearrivalEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    // commit
    on(commitBannerPrearrivalEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitBannerPrearrivalEmailSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data
        };
    }),
    // save snapshot
    on(saveBannerPrearrivalEmail, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(saveBannerPrearrivalEmailSuccess, (state) => {
        return {
            ...state,
            loading: false
        };
    }),
    on(saveBannerPrearrivalEmailFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    })
);

export function bannerPrearrivalEmailReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}
