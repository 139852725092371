import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, flatMap, map, takeWhile } from 'rxjs/operators';

@Directive({
    selector: '[ft-is-visible]'
})
export class IsVisibleDirective implements OnInit, OnDestroy {

    isAlive = true;

    @Input() when: boolean;
    @Output() callback = new EventEmitter();

    constructor(private el: ElementRef) { }

    ngOnInit(): void {
        this.elementInSight(this.el)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(isVisible => {
                if (isVisible && this.when) {
                    this.callback.next(null);
                }
            });
    }

    ngOnDestroy() {
        this.isAlive = false;
    }

    elementInSight(element: ElementRef): Observable<boolean> {
        const elementVisible$ = Observable.create(observer => {
            const intersectionObserver = new IntersectionObserver(entries => {
                observer.next(entries);
            });
            intersectionObserver.observe(element.nativeElement);
            return () => { intersectionObserver.disconnect(); };
        }).pipe(
            flatMap((entries: any) => entries),
            map((entry: { isIntersecting: boolean }) => entry.isIntersecting),
            distinctUntilChanged()
        );

        return elementVisible$;
    }

}
