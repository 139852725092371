import { Action, createReducer, on } from '@ngrx/store';
import {
    commit,
    commitSuccess,
    create,
    createSuccess,
    load,
    loadFail,
    loadSuccess,
    selectComponent
} from './actions';
import { State } from './state';

export const reducer = createReducer(
    {
        loading: false,
        settings: null,
        selectedComponentUuid: null
    } as State,
    on(load, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(loadSuccess, (state, {data}) => {
        return {
            ...state,
            loading: false,
            settings: data,
            selectedComponentUuid: !!data.length ? data[0].componentUUID : null,
        };
    }),
    on(loadFail, (state, {error}) => {
        return {
            ...state,
            loading: false,
            error
        };
    }),
    on(selectComponent, (state, {componentUuid}) => {
        return {
            ...state,
            selectedComponentUuid: componentUuid,
        };
    }),
    on(commit, (state) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(commitSuccess, (state, {data}) => {
        return {
            ...state,
            settings: state.settings.map(cmp => cmp.componentUUID === data.componentUUID ? data : cmp),
            loading: false,
            commitSuccess: true,
        };
    }),
    on(create, (state) => {
        return {
            ...state,
            creating: true
        };
    }),
    on(createSuccess, (state, {data}) => {
        const selectedIndex = state.settings.length;
        const settings = [...state.settings, data];
        return {
            ...state,
            creating: false,
            settings,
            selectedComponentUuid: settings[selectedIndex].componentUUID,
        };
    }),
    on(loadFail, (state, {error}) => {
        return {
            ...state,
            creating: false,
            error
        };
    }),
);

export function booknowReducer(state: State | undefined, action: Action) {
    return reducer(state, action);
}

