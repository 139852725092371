/**
 * Created by roman.kupin on 1/29/2018.
 */

import { TouchpointContainerService } from "./touchpointContainer.service";

export const NAME = "ftTouchpointTrigger";

export const DEFINITION = ["TouchpointContainer", function (TouchpointContainer: TouchpointContainerService) {
    return {
        scope: false,
        link: function TouchpointTriggerLinkFn(scope, elem, attrs) {

            function showTouchpoint() {
                scope.$apply(() => {
                    const options = scope.$eval(attrs[NAME]);
                    TouchpointContainer.show(options.campaignUuid, options.propertyUuid, options.name, options.dataType);
                });
            }

            elem.bind("click", showTouchpoint);

            scope.$on("$destroy", () => {
                elem.unbind("click", showTouchpoint);
            });
        }
    };
}];


