import { MixedSpace, SpaceType, getPathForSpace, storedHistory } from 'account-hybrid/common/components/spaces';
import * as fromSpaces from 'account-hybrid/common/components/spaces/store/reducers/spaces.reducer';

export class StateConfig {

    static $inject = ["$stateProvider"];

    constructor($stateProvider) {

        $stateProvider
            .state({
                name: "invitation",
                url: "/invitation/{companyCode:[\\d\\w]{2}}",
                data: {
                    requiresAuth: true
                },
                resolve: {
                    companyCode: ['$stateParams', function ($stateParams) {
                        return $stateParams.companyCode;
                    }]
                },
                controller: ["companyCode", 'SessionService', function (companyCode, session) {
                    const userSession = session.get();
                    const spaces = fromSpaces.createSpaces({
                        companies: userSession.Companies,
                        properties: userSession.Properties,
                        history: storedHistory()
                    });

                    const space = spaces.find(s => s.type === SpaceType.Company && s.slug.toLowerCase() === companyCode.toLowerCase()) ||
                        spaces.find((s: MixedSpace) => s.type === SpaceType.Mixed && s.company.slug.toLowerCase() == companyCode.toLowerCase());

                    location.href = !!space ? `${getPathForSpace(space)}` : '/home';
                }],
                template:
                    `
                    <div class="ft-loader-orgswitch">
                        <div class="ft-cube-face-single">
                            <div class="ft-content">
                                <div class="ft-loading-message">
                                    Loading <div class="ft2-spinner"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                            `
            });

    }
}
