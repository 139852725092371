/**
 * Created by roman.kupin on 11/13/2017.
 */

import { injectGeoCharts, injectLoader } from "../googleChartsLoader";

const TEMPLATE_URL = require("./geoChart.html");

export const NAME = "ftGeoChart";

declare let google;


const RESOLUTION_REGIONS = "regions";
const RESOLUTION_PROVINCES = "provinces";
const REGION_WORLD = "world";

const DEFAULT_PROJECTION = "kavrayskiy-vii";
const ZOOMIN_PROJECTION = "mercator";


export class GeoChartController {

    static $inject = ["$element", 'environment'];

    private whenChartsReady;

    data: any;
    options: any;
    chart: any;

    caption: string;

    chartElem: Node;

    visualizedData: any;
    onRegionSelected: any;

    constructor(private $element, private environment) {
        this.chartElem = $element.find("div")[0];
        this.whenChartsReady = injectLoader().then(() => injectGeoCharts(environment.googleChartsMapApiKey));
    }

    $onChanges() {
        Promise.all([
            this.whenChartsReady,
            new Promise((resolve, reject) => {
                if (!!this.data) resolve(this.data);
            })
        ])
            .then(datas => {
                this.createChart(datas[1]);
            });
    }

    createChart(data) {
        this.visualizedData = google.visualization.arrayToDataTable(data);
        this.options = GeoChartController.GetChartOptions(data, REGION_WORLD, RESOLUTION_REGIONS);
        this.chart = new google.visualization.GeoChart(this.chartElem);
        let regionCode, regionName;
        google.visualization.events.addListener(this.chart, "regionClick", args => {
            if (this.options.projection.name === ZOOMIN_PROJECTION) return;
            regionCode = args.region;
        });
        google.visualization.events.addListener(this.chart, "select", () => {
            if (this.options.projection.name === ZOOMIN_PROJECTION) return;
            const selection = this.chart.getSelection()[0];
            regionName = this.data[selection.row + 1][0];
            this.showRegion(regionCode, regionName);
        });
        this.reDraw(this.visualizedData, this.options);

        // Add an iframe as a resize watcher
        let self = this;
        this.addResizeWatcher(this.chartElem.parentElement, () => { self.reDraw(self.visualizedData, self.options); });
    }

    addResizeWatcher(element, handler) {
        let frame = document.createElement('iframe');
        frame.style.cssText = 'position:absolute;left:0;top:-100%;width:100%;height:100%;margin:1px 0 0;border:none;opacity:0;visibility:hidden;pointer-events:none;';
        element.appendChild(frame);
        frame.contentWindow.onresize = handler;
    }

    showRegion(regionCode, regionName) {
        this.onRegionSelected({region: regionName})
            .then((data) => {
                const regionData = google.visualization.arrayToDataTable(data);
                this.options.projection.name = ZOOMIN_PROJECTION;
                this.reDraw(regionData, this.options, regionCode, RESOLUTION_PROVINCES);
            });
    }

    zoomOut() {
        this.options.projection.name = DEFAULT_PROJECTION;
        this.reDraw(this.visualizedData, this.options, REGION_WORLD, RESOLUTION_REGIONS);
    }

    private reDraw(data: any, options: any, region?: string, resolution?: string) {
        if (!!region) options.region = region;
        if (!!resolution) options.resolution = resolution;
        this.chart.draw(data, options);
    }

    static GetChartOptions(sortedData: any[], region: string, resolution: string) {

        if (!sortedData.length) return null;

        const min = sortedData.length > 1 ? sortedData[1][1] : 0;
        const max = sortedData.length > 1 ? sortedData[sortedData.length - 1][1] : 0;
        const TOOLTIP_TEXT_COLOR = "#222222";
        const TOOLTIP_FONT_NAME = "brandon-grotesque";
        const TOOLTIP_FONT_SIZE = "13px";

        return {
            backgroundColor: 'transparent',
            colorAxis: {
                colors: ['#CBCFFF', '#2E358D']
            },
            datalessRegionColor: '#e0e0e0',
            keepAspectRatio: true,
            legend: {
                textStyle: {
                    color: TOOLTIP_TEXT_COLOR,
                    fontName: TOOLTIP_FONT_NAME,
                    fontSize: TOOLTIP_FONT_SIZE,
                    bold: true
                }
            },
            projection: {name: DEFAULT_PROJECTION},
            region: region,
            resolution: resolution,
            sizeAxis: {minValue: min, maxValue: max},
            tooltip: {
                textStyle: {
                    color: TOOLTIP_TEXT_COLOR,
                    fontName: TOOLTIP_FONT_NAME,
                    fontSize: TOOLTIP_FONT_SIZE,
                    bold: true
                }
            },
            width: "100%"
        };
    }

}

export const OPTIONS = {
    templateUrl: TEMPLATE_URL,
    controllerAs: "vm",
    controller: GeoChartController,
    bindings: {
        "caption": "@",
        "data": "<",
        "onRegionSelected": "&"
    }
};
