import { getPathForSpace, getSpaceAttributesFromSpace } from 'account-hybrid/common/components/spaces';
import * as fromSpaces from 'account-hybrid/common/components/spaces/store/reducers/spaces.reducer';
import * as fromStore from 'account-hybrid/store/reducers/index';
import { first, tap } from 'rxjs/operators';

/**
 * Created by roman.kupin on 6/13/2018.
 */


export class StateConfig {

    static $inject = ["$stateProvider"];

    constructor($stateProvider) {

        $stateProvider
            .state({
                name: 'home',
                url: '/home?redirectTo',
                data: {
                    requiresAuth: true
                },
                template: '',
                resolve: {
                    redirectTo: ['$stateParams', function ($stateParams) {
                        return $stateParams.redirectTo;
                    }]
                },
                controller: ['IdentityService', 'redirectTo', '$location', '$state', 'SpacesService', 'ngrxStore', '$timeout',
                    function (identity, redirectTo, $location, $state, SpacesService, ngrxStore, $timeout) {

                        if (identity.isAnonymous()) {
                            $state.go('sign-in', { redirectTo: $location.path() });
                        } else if (!!redirectTo) {
                            $location.url(redirectTo);
                        } else if (!SpacesService.current) {

                            ngrxStore.select(fromStore.selectSpaces)
                                .pipe(
                                    first(),
                                    tap((store: fromSpaces.State) => {
                                        if (store.allSpaces.length === 1 && store.collaborativeSpaces.length === 0) {
                                            document.location.href = getPathForSpace(store.allSpaces[0]);
                                        } else {
                                            ngrxStore.dispatch({ type: "[Spaces] Set isCancelVisible", payload: false });
                                            ngrxStore.dispatch({ type: "[Spaces] Set isVisible", payload: true });
                                        }
                                    })
                                )
                                .subscribe()
                        } else {
                            $state.go('hub', getSpaceAttributesFromSpace(SpacesService.current));
                        }
                    }]
            });

    }
}
