import { Component } from '@angular/core';

@Component({
  selector: 'ft-authentication-wrapper',
  templateUrl: './authentication-wrapper.component.html'
})
export class AuthenticationWrapperComponent {

  constructor() { }
}
