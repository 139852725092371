import {
    advanceToCampaign,
    advanceToCampaignSuccess,
    cancelReservation,
    cancelReservationFailure,
    cancelReservationSuccess,
    createShareImage,
    createShareImageSuccess,
    editProfileReservation,
    editProfileReservationFailure,
    editProfileReservationSuccess,
    loadBroadcastData,
    loadBroadcastDataFailure,
    loadBroadcastDataSuccess,
    loadPhotocontestData,
    loadPhotocontestDataFailure,
    loadPhotocontestDataSuccess,
    loadStoriesIsCurationQuotesSuccess,
    saveCertificateExpirationDate,
    saveCertificateExpirationDateFailure,
    saveCertificateExpirationDateSuccess,
    savePhotoReplacement,
    savePhotoReplacementSuccess,
    sendEmailCertificate,
    sendEmailCertificateFailure,
    sendEmailCertificateSuccess,
    sendRecommandation,
    sendRecommandationFailure,
    sendRecommandationSuccess,
    updatePhotoDetails,
    updatePhotoDetailsFailure,
    updatePhotoDetailsSuccess
} from './../actions/curation.actions';

import { createReducer, on } from '@ngrx/store';
import { Parse } from 'shared';
import {
    loadCurationModalData,
    loadCurationModalDataFailure,
    loadCurationModalDataSuccess,
    loadStoriesIsCurationPhotoSuccess,
    updateRate,
    updateRateFailure,
    updateRateSuccess
} from '../actions/curation.actions';
import { CurationState } from '../curation.state';

const initialState: CurationState = {
    data: null,
    advocateData: null,
    bradcastData: null,
    photocontestData: null,
    loading: false,
    loaded: false,
    error: null,
    certificateLoaded: false,
    advanceToCampaignLoaded: false,
    certificateExpirationLoaded: false,
    recommandationSended: false,
    recommandationSendedError: null,
    storiesIsCurationPhotos: null,
    storiesIsCurationQuotes: null
};

export const curationReducer = createReducer(initialState,
    on(loadCurationModalData, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(loadCurationModalDataSuccess, (state, action) => {
        // Post-processing to make the ai tags more usable
        if (action.data.guest.poststay.photo) {
            var photo = action.data.guest.poststay.photo;
            photo.ai = photo.ai || {};

            // Loop through tag categories
            for (var i = 0; i < photo.ai.aiTagCategories.length; i++) {
                var category = photo.ai.aiTagCategories[i];

                // Save any tags found in a new property named after the category attribute
                if (category.aiTags) {
                    action.data.guest.poststay.photo.ai[category['@category']] = Parse.arrayOrDefault(category.aiTags);
                }
            }
            delete action.data.guest.poststay.photo.ai.aiTagCategories;

            // Also parse the list of faces
            action.data.guest.poststay.photo.ai.faces = Parse.arrayOrDefault(photo.ai.faces);
        }

        return {
            ...state,
            data: action.data,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(loadCurationModalDataFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
    // update rate
    on(updateRate, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(updateRateSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(updateRateFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
    // advance to campaign
    on(advanceToCampaign, state => ({
        ...state,
        loaded: false,
        loading: true,
        advanceToCampaignLoaded: false,
        error: null
    })),
    on(advanceToCampaignSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            advanceToCampaignLoaded: true,
            loaded: true,
            error: null
        };
    }),
    //share
    on(loadBroadcastData, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(loadBroadcastDataSuccess, (state, action) => {
        return {
            ...state,
            bradcastData: action.bradcastData,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(loadBroadcastDataFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
     //photocontest
     on(loadPhotocontestData, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(loadPhotocontestDataSuccess, (state, action) => {
        return {
            ...state,
            photocontestData: action.photocontestData,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(loadPhotocontestDataFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
    // create share image
    on(createShareImage, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(createShareImageSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    // update photo details
    on(updatePhotoDetails, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(updatePhotoDetailsSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(updatePhotoDetailsFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
    // save photo replacement
    on(savePhotoReplacement, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(savePhotoReplacementSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    // cancel reservation
    on(cancelReservation, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(cancelReservationSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(cancelReservationFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
    // edit profile reservation
    on(editProfileReservation, state => ({
        ...state,
        loaded: false,
        loading: true,
        error: null
    })),
    on(editProfileReservationSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            error: null
        };
    }),
    on(editProfileReservationFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: action?.error
    })),
    // send email certificate
    on(sendEmailCertificate, state => ({
        ...state,
        loaded: false,
        certificateLoaded: false,
        loading: true,
        error: null
    })),
    on(sendEmailCertificateSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            certificateLoaded: true,
            error: null
        };
    }),
    on(sendEmailCertificateFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        certificateLoaded: false,
        error: action?.error
    })),

    // save Certificate Expiration Date
    on(saveCertificateExpirationDate, state => ({
        ...state,
        loaded: false,
        loading: true,
        certificateExpirationLoaded: true,
        error: null
    })),
    on(saveCertificateExpirationDateSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            certificateExpirationLoaded: true,
            error: null
        };
    }),
    on(saveCertificateExpirationDateFailure, (state, action) => ({
        ...state,
        data: null,
        loading: false,
        loaded: false,
        certificateExpirationLoaded: false,
        error: action?.error
    })),

    // send recommandation
    on(sendRecommandation, state => ({
        ...state,
        loaded: false,
        recommandationSended: false,
        loading: true,
        error: null
    })),
    on(sendRecommandationSuccess, (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            recommandationSended: true,
            error: null
        };
    }),
    on(sendRecommandationFailure, (state, action) => ({
        ...state,
        recommandationSended: false,
        recommandationSendedError: action?.error
    })),
    // load stories is curation photos
    on(loadStoriesIsCurationPhotoSuccess, (state, action) => {
        return {
            ...state,
            storiesIsCurationPhotos: action.storiesPhotos,
            loading: false,
            loaded: true,
            error: null
        };
    }),
     // load stories is curation quotes
     on(loadStoriesIsCurationQuotesSuccess, (state, action) => {
        return {
            ...state,
            storiesIsCurationQuotes: action.storiesQuotes,
            loading: false,
            loaded: true,
            error: null
        };
    }),
);
