import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Transition } from '@uirouter/core';
import { MultiLanguageComponent } from 'account-hybrid/common/components/multi-linguage/multi-language.component';
import {
    mapLanguageNameToCode
} from 'account-hybrid/common/modules/account-management/utils/language-name-mapping.util';
import { Observable } from 'rxjs';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { DiscoveryComponentType, domInjectionStrategyOptions } from '../../enums';
import { IDiscoveryInjectableComponentMessaging, IDiscoveryInjectableComponentSettings } from '../../interfaces';
import * as fromTouchpoints from '../../store';


@Component({
    selector: 'ft-booking-bar',
    templateUrl: './booking-bar.component.html',
    styleUrls: ['./booking-bar.component.scss']
})
export class BookingBarComponent extends MultiLanguageComponent implements OnInit {
    componentGroupUuid: string;
    domInjectionStrategyOptions = domInjectionStrategyOptions;
    tabsShowed = true;
    selectedBookingBarComponent$: Observable<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>;
    plannerFlowSettings$ = this.touchpointStore$.pipe(select(fromTouchpoints.selectDiscoveryPlannerFlowSettings));
    isLoading$: Observable<boolean>;
    commitSuccess$: Observable<boolean>;
    error$: Observable<any>;
    messaging$: Observable<IDiscoveryInjectableComponentMessaging[]>;
    messaging: IDiscoveryInjectableComponentMessaging[];
    selectedIndex = 0;


    getLabel(value: number) {
        return this.domInjectionStrategyOptions.find((option) => option.value === value).label;
    }

    constructor(
        private touchpointStore$: Store<fromTouchpoints.State>,
        private transition: Transition,
        store: Store) {
        super(store);
    }

    ngOnInit() {
        this.componentGroupUuid = this.transition.params().componentGroupUuid;
        this.selectedBookingBarComponent$ = this.touchpointStore$.pipe(
            select(fromTouchpoints.selectDiscoveryBookingBarComponentSettings),
            tap(selectedComponent => {
                if (!!selectedComponent) {
                    this.messaging$ = this.touchpointStore$.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentMessaging, {
                            componentType: DiscoveryComponentType.BookingBar,
                            componentUuid: selectedComponent.componentUuid
                        }), withLatestFrom(this.languages$),
                        map(([messaging, availableLanguages]) => {
                            this.messaging = mapLanguageNameToCode(messaging, availableLanguages);
                            return this.messaging;
                        }),
                        take(1)
                    );
                    this.messaging$.subscribe();
                }
            })
        );
        this.isLoading$ = this.touchpointStore$.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentIsLoading));
        this.commitSuccess$ = this.touchpointStore$.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentCommitSuccess));
        this.error$ = this.touchpointStore$.pipe(select(fromTouchpoints.selectDiscoveryInjectableComponentError));
    }

    commit(data: Partial<IDiscoveryInjectableComponentSettings<IDiscoveryInjectableComponentMessaging>>) {
        this.messaging$
            .pipe(take(1))
            .subscribe(messaging => {
                const mappedData = { ...data };
                if (data.hasOwnProperty('messaging')) {
                    mappedData.messaging = mappedData.messaging.map((message, i) => ({
                        ...message,
                        languageCode: messaging[i]?.languageCode
                    }));
                }
                this.touchpointStore$.dispatch(fromTouchpoints.commitDiscoveryInjectableComponentSettings({
                    componentGroupUuid: this.componentGroupUuid,
                    settings: mappedData,
                    componentType: DiscoveryComponentType.BookingBar
                }));
            });
    }
}
