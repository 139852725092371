import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'ft-dropzone-uploader',
    templateUrl: './dropzone-uploader.component.html',
    styleUrls: ['./dropzone-uploader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DropzoneUploaderComponent implements OnChanges {
    files: File[] = [];
    @Output() selected = new EventEmitter<File[]>();
    @Input() multiple = true;
    @Input() accept = '*';
    @Input() label;
    @Input() imagePreview = false;
    @Input() uploadedFileUrl: string;
    @Input() transparent = false;
    @Input() loading: boolean;
    defaultLabel = 'Upload your files';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.multiple) {
            this.defaultLabel = 'Upload your file';
        }
    }

    onSelect(event) {
        if (this.multiple) {
            this.files.push(...event.addedFiles);
        } else {
            this.files = event.addedFiles;
        }
        this.selected.next(this.files);
    }

    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
    }

}
