import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { AccountSharedModule } from 'apps/account/src/account-shared';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { InstantSearchComponent } from './instant-search/instant-search.component';

@NgModule({
    declarations: [
        InstantSearchComponent
    ],
    imports: [
        FormsModule,
        MatIconModule,
        CommonModule,
        AccountSharedModule,
        Ng2SearchPipeModule
    ],
    exports: [
        InstantSearchComponent
    ]
})
export class InstantSearchModule {
}
