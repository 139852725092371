
import PropertyApiActions from "../../../actions/propertyApi";
import { ShareType } from "../../broadcast/shareType";
import { StoryDescriptor } from "../../broadcast/storyDescriptor";
import { BroadcastStoryFlowController } from "./broadcastStoryFlowController";

declare const moment;

export class BuildBroadcastStoryRecommendationFlowController extends BroadcastStoryFlowController {

    static $inject = [
        "$injector",

        "renderer",
        "propertyUuid",
        "storyUuid",
        "steps"
    ];

    recommendationExpirationRanges = [
        { name: "7 days", date: (moment().add(7, "days")) },
        { name: "14 days", date: (moment().add(14, "days")) },
        { name: "30 days", date: (moment().add(30, "days")) }
    ];

    recommendationExpirationDate = Object.assign({}, this.recommendationExpirationRanges[2]);
    recommendationMessage;
    isProcessingRequest: boolean;
    daysUntilDate: any;

    constructor(
        public $injector,

        public renderer,
        public propertyUuid,
        public storyUuid,
        public steps
    ) {

        super(
            $injector,

            renderer,
            propertyUuid,
            storyUuid,
            null,
            steps
        );

        this.ftAzureStoredPhoto = this.$filter("ftAzureStoredPhoto");
        this.daysUntilDate = this.$filter("daysUntilDate");
        const state = this.$ngRedux.getState();
        this.property = this.SessionService.getProperty(this.propertyUuid);
        this.fullContext = `/${state.ui.context.organization}/${state.ui.context.singleProperty}`;

        this.$q.all([this.PhotoContest.details(this.propertyUuid, this.storyUuid),
        this.Layers.photocontest({ propertyId: this.propertyUuid })])
            .then(datas => {
                const story = datas[0],
                    photoContestLayer = datas[1];

                this.storyDescriptor = new StoryDescriptor(this.parse, story);
                this.storySharedTo = story.socialNetworksSharing.filter(n => this.parse.intOrDefault(n.totalShareTimes) > 0).map(n => this.parse.intOrDefault(n.socialNetworkType));
                this.contests = this.parse.arrayOrDefault(photoContestLayer.contests);
                this.imageSetupOptions = this.createImageSetupOptionsFromStory(story);

                const hasActiveContests = !!this.contests && !!this.contests.find(c => this.parse.bool(c.isActive) && !this.parse.bool(c.isClosed) && this.daysUntilDate(c.endDate) >= 1);
                if (!hasActiveContests) {
                    this.startLeadShare();
                }

                this.$q.resolve(!!story.guest.poststay.photo ? this.PhotoContest.load({ contestId: story.guest.poststay.photo.photoContest.uuid, propertyUuid: this.propertyUuid }) : null)
                    .then(contest => {
                        this.contest = contest;
                        this.story = story;
                        this.isInitialized = true;

                        this.setShareType(ShareType.Story);
                    });

            })
    }

    close() {
        this.ftModal.destroy("account-v2.BuildBroadcastStoryRecommendation");
        super.close();
    }

    chooseDifferentGoal() {
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_CHOOSE_GOAL);
    }

    startLeadShare() {
        this.setShareType(ShareType.Story);
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_STORY_IMAGE_SETUP);
    }

    startStorytellersShare(contest) {
        this.inviteToContest = contest;
        this.setShareType(ShareType.PhotoContestInvitation);
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_PHOTOCONTEST_INVITATION_IMAGE_SETUP);
        //this.createShareImageTemplate();
    }

    returnToImageSetup() {
        this.moveTo(this.shareType == ShareType.Story ? BroadcastStoryFlowController.STEP_NAME_STORY_IMAGE_SETUP : BroadcastStoryFlowController.STEP_NAME_PHOTOCONTEST_INVITATION_IMAGE_SETUP);
    }

    finishImageSetup() {
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_CHOOSE_NETWORK);
    }


    static ShareTypeToRecommendationTypeMap = {
        [ShareType.Story]: 1,
        [ShareType.PhotoContestInvitation]: 5
    };

    createRecommendation(recommendationForm) {
        recommendationForm.$submitted = true;
        if (recommendationForm.$invalid) return;
        const shareImageUuid = !!this.shareImageUrl ? this.shareImageUrl.substr(this.shareImageUrl.length - 40, 36) : null;
        const customData = {
            imageSetupOptions: this.imageSetupOptions
        };
        customData["guestUuid"] = this.story.guest.uuid;
        customData["selectedPhotoUrl"] = this.imageSetupOptions.isShowGuestPhoto ? this.ftAzureStoredPhoto(this.story.guest.poststay.photo) : this.property.BackgroundImageUrl;
        if (!!this.story.guest.poststay.photo) {
            customData["photoUuid"] = this.story.guest.poststay.photo.uuid;
        }
        if (this.network) {
            customData["socialNetworkType"] = this.network.type;
            customData["shareImageUrl"] = this.shareImageUrl;
        }
        if (this.inviteToContest) {
            customData["contestUuid"] = this.inviteToContest.id;
        }
        this.isProcessingRequest = true;
        this.$ngRedux.dispatch(PropertyApiActions.createRecommendation(this.property.UUID, shareImageUuid, this.recommendationExpirationDate.date, this.recommendationMessage, BuildBroadcastStoryRecommendationFlowController.ShareTypeToRecommendationTypeMap[this.shareType], customData))
            .then(data => {
                this.close();
                this.$rootScope.$broadcast("[Broadcast].CreateRecommendationComplete");
            },
                () => {
                    alert("Unable to create recommendation.");
                })
            .finally(() => {
                this.isProcessingRequest = false;
            })
    }

    finishRecommendation() {
        this.moveTo(BroadcastStoryFlowController.STEP_NAME_CONCLUDE_RECOMMENDATION);
    }

}
