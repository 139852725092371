import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Company, CompanyType, Property } from '../../../../../core/authentication/models/user';
import { SessionService } from '../../../../../core/authentication/services/session.service';
import { getPathForSpace, SpaceBase } from '../../../../components/spaces/models/space';
import { SpaceType } from '../../../../components/spaces/models/space-type';
import { storedHistory } from '../../../../components/spaces/spaces-history.service';
import { SpacesService } from '../../../../components/spaces/spaces.service';
import { createSpaces } from '../../../../components/spaces/store/reducers/spaces.reducer';
import { AccountManagementApiService } from '../../services/account-management-api.service';
import {
    createAccount,
    createAccountFailure,
    createAccountSuccess,
    createProperty, createPropertyFailure, createPropertySuccess
} from './management.actions';


@Injectable()
export class ManagementEffects {
    createAccount$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createAccount),
        mergeMap(
          ({ data }) => this.accountManagerService.createAccount(data)
            .pipe(
              map((createdCompany: Company) => {
                  return createAccountSuccess({ createdCompany });
              }),
              catchError(error => {
                  return of(createAccountFailure(error));
              })
            )
        )
      )
    );

    createAccountSuccess$ = createEffect(() =>
        this.actions$.pipe(
          ofType(createAccountSuccess),
          tap(({ createdCompany }) => {
              this.sessionService.addNewCompany(createdCompany);
              const spaceType = createdCompany.Type === CompanyType.Collaborative ? SpaceType.Collaborative : SpaceType.Company;
              document.location.href = `${getPathForSpace({
                  type: spaceType,
                  slug: createdCompany.Code
              } as SpaceBase)}/collaborative-settings`;
          })
        ),
      { dispatch: false }
    );

    createProperty$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createProperty),
        mergeMap(
          ({ data }) => this.accountManagerService.createProperty(data)
            .pipe(
              map((createdProperty: Property) => {
                  return createPropertySuccess({ createdProperty });
              }),
              catchError(error => {
                  return of(createPropertyFailure(error));
              })
            )
        )
      )
    );

    createPropertySuccess$ = createEffect(() =>
        this.actions$.pipe(
          ofType(createPropertySuccess),
          tap(({ createdProperty }) => {
              const companyUuid = this.spacesService.getCompanyUuid();
              const company = this.sessionService.getCompany(companyUuid);
              this.sessionService.addNewProperty(createdProperty, companyUuid);

              const userSession = this.sessionService.get();
              const spaces = createSpaces({
                  companies: userSession.Companies,
                  properties: userSession.Properties,
                  history: storedHistory()
              });
              const space = spaces.find(s => s.type === SpaceType.Property && s.slug === createdProperty.Slug) ||
                spaces.find(s => s.type === SpaceType.Mixed && s.slug === `${company.Code}-${createdProperty.Slug}`);
              document.location.href = `${getPathForSpace(space)}/mission-control/details`;
          })
        ),
      { dispatch: false }
    );

    constructor(private actions$: Actions,
                private sessionService: SessionService,
                private spacesService: SpacesService,
                private accountManagerService: AccountManagementApiService) {
    }

}
