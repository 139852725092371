import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'apps/account/src/account-shared';
import { SwiperModule } from 'swiper/angular';
import { MonthlyCalendarDayComponent } from './components/monthly-calendar-day/monthly-calendar-day.component';
import { MonthlyCalendarComponent } from './components/monthly-calendar/monthly-calendar.component';

@NgModule({
    declarations: [
        MonthlyCalendarComponent,
        MonthlyCalendarDayComponent
    ],
    imports: [
        CommonModule,
        NgbDatepickerModule,
        SwiperModule,
        PipesModule
    ],
    exports: [
        MonthlyCalendarComponent
    ]
})
export class MonthlyCalendarModule {
}
