import { createAction, props } from '@ngrx/store';
import { StoriesFiltersQuery, StoriesPhotosModel } from 'account-hybrid/features/stories/models/stories-library.model';
import {
    BroadcastData,
    CurationModalData,
    PhotocontestData
} from '../../models/curation.model';

export const loadCurationModalData = createAction(
    '[Curation Modal] Load data',
    props<{
        propertyUUID: string;
        guestUUID: string;
    }>()
);

export const loadCurationModalDataSuccess = createAction(
    '[Curation Modal] Load datas Success',
    props<{
        data: CurationModalData;
    }>()
);

export const loadCurationModalDataFailure = createAction(
    '[Curation Modal] Load data Failure',
    props<{
        error: any;
    }>()
);

// update rate
export const updateRate = createAction(
    '[Curation Modal] update rate',
    props<{
        propertyUUID: string;
        guestUUID: string;
        index: number;
        typeRate: string;
    }>()
);

export const updateRateSuccess = createAction(
    '[Curation Modal] update rate Success'
);

export const updateRateFailure = createAction(
    '[Curation Modal] update rate Failure',
    props<{
        error: any;
    }>()
);

// advance to campaign
export const advanceToCampaign = createAction(
    '[Curation Modal] advance to campaign',
    props<{
        propertyUUID: string;
        photoId: string;
        data: boolean;
    }>()
);

export const advanceToCampaignSuccess = createAction(
    '[Curation Modal] advance to campaign Success'
);

export const advanceToCampaignFailure = createAction(
    '[Curation Modal] advance to campaign Failure',
    props<{
        error: any;
    }>()
);

// share 
export const loadBroadcastData = createAction(
    '[Curation Modal] Load broadcast data',
    props<{
        propertyUUID: string;
        guestUUID: string;
    }>()
);

export const loadBroadcastDataSuccess = createAction(
    '[Curation Modal] Load broadcast data Success',
    props<{
        bradcastData: BroadcastData;
    }>()
);

export const loadBroadcastDataFailure = createAction(
    '[Curation Modal] Load broadcast data Failure',
    props<{
        error: any;
    }>()
);

// photocontest 
export const loadPhotocontestData = createAction(
    '[Curation Modal] Load photocontest data',
    props<{
        propertyUUID: string;
    }>()
);

export const loadPhotocontestDataSuccess = createAction(
    '[Curation Modal] Load photocontest data Success',
    props<{
        photocontestData: PhotocontestData;
    }>()
);

export const loadPhotocontestDataFailure = createAction(
    '[Curation Modal] Load photocontest data Failure',
    props<{
        error: any;
    }>()
);

// create share image
export const createShareImage = createAction(
    '[Curation Modal] create share image',
    props<{
        propertyUUID: string;
        model: any;
    }>()
);

export const createShareImageSuccess = createAction(
    '[Curation Modal] create share image Success'
);

export const createShareImageFailure = createAction(
    '[Curation Modal] create share image Failure',
    props<{
        error: any;
    }>()
);

// photo details
export const updatePhotoDetails = createAction(
    '[Curation Modal] update photo details',
    props<{
        propertyUUID: string;
        guestUUID: string;
        data: {caption: string; quote: string; rotation: number;};
    }>()
);

export const updatePhotoDetailsSuccess = createAction(
    '[Curation Modal] update photo details Success'
);

export const updatePhotoDetailsFailure = createAction(
    '[Curation Modal] update photo details Failure',
    props<{
        error: any;
    }>()
);

// save photo replacement
export const savePhotoReplacement = createAction(
    '[Curation Modal] save photo replacemente',
    props<{
        propertyUUID: string;
        guestUUID: string;
        data: any;
    }>()
);

export const savePhotoReplacementSuccess = createAction(
    '[Curation Modal] save photo replacement Success'
);

export const savePhotoReplacementFailure = createAction(
    '[Curation Modal] save photo replacement Failure',
    props<{
        error: any;
    }>()
);

// cancel reservation
export const cancelReservation = createAction(
    '[Curation Modal] cancel reservation',
    props<{
        propertyUUID: string;
        guestUUID: string;
        data: any;
    }>()
);

export const cancelReservationSuccess = createAction(
    '[Curation Modal] cancel reservation Success'
);

export const cancelReservationFailure = createAction(
    '[Curation Modal] cancel reservation Failure',
    props<{
        error: any;
    }>()
);

// edit profile reservation
export const editProfileReservation = createAction(
    '[Curation Modal] edit profile reservation',
    props<{
        propertyUUID: string;
        guestUUID: string;
        data: any;
    }>()
);

export const editProfileReservationSuccess = createAction(
    '[Curation Modal] edit profile reservation Success'
);

export const editProfileReservationFailure = createAction(
    '[Curation Modal] edit profile reservation Failure',
    props<{
        error: any;
    }>()
);

// send email certificate
export const sendEmailCertificate = createAction(
    '[Curation Modal] send email certificate',
    props<{
        propertyUUID: string;
        guestUUID: string;
        id: string;
        data: any;
    }>()
);

export const sendEmailCertificateSuccess = createAction(
    '[Curation Modal] send email certificate Success'
);

export const sendEmailCertificateFailure = createAction(
    '[Curation Modal] send email certificate Failure',
    props<{
        error: any;
    }>()
);

// save Certificate Expiration Date
export const saveCertificateExpirationDate = createAction(
    '[Curation Modal] save Certificate Expiration Date',
    props<{
        propertyUUID: string;
        guestUUID: string;
        id: string;
        data: any;
    }>()
);

export const saveCertificateExpirationDateSuccess = createAction(
    '[Curation Modal] save Certificate Expiration Date Success'
);

export const saveCertificateExpirationDateFailure = createAction(
    '[Curation Modal] save Certificate Expiration Date Failure',
    props<{
        error: any;
    }>()
);

// send recommandation share setup
export const sendRecommandation = createAction(
    '[Curation Modal] send recommandation',
    props<{
        propertyUUID: string;
        data: any;
    }>()
);

export const sendRecommandationSuccess = createAction(
    '[Curation Modal] send recommandation Success'
);

export const sendRecommandationFailure = createAction(
    '[Curation Modal] send recommandation Failure',
    props<{
        error: any;
    }>()
);

// load stories is curation photo
export const loadStoriesIsCurationPhoto = createAction(
    '[Stories Library] Load stories isCuration photo',
    props<{
        propertyUuid: string;
        tab: string;
        query: StoriesFiltersQuery;
    }>()
);

export const loadStoriesIsCurationPhotoSuccess = createAction(
    '[Stories Library] Load stories isCuration photo Success',
    props<{ storiesPhotos: StoriesPhotosModel }>()
);

export const loadStoriesIsCurationPhotoFailure = createAction(
    '[Stories Library] Load stories isCuration photo Failure',
    props<{ error: any }>()
);

// load stories is curation quotes
export const loadStoriesIsCurationQuotes = createAction(
    '[Stories Library] Load stories isCuration quotes',
    props<{
        propertyUuid: string;
        tab: string;
        query: StoriesFiltersQuery;
    }>()
);

export const loadStoriesIsCurationQuotesSuccess = createAction(
    '[Stories Library] Load stories isCuration quotes Success',
    props<{ storiesQuotes: StoriesPhotosModel }>()
);

export const loadStoriesIsCurationQuotesFailure = createAction(
    '[Stories Library] Load stories isCuration quotes Failure',
    props<{ error: any }>()
);
