import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ComponentGroup } from "../models/component-group.model";
import { ComponentGroupsService } from "../services/component-groups.service";
import {
    createComponentGroup, createComponentGroupFailure, createComponentGroupSuccess,
    loadComponentGroupsFailure,
    loadComponentGroupsSuccess,
    loadComponentsGroups
} from "../store/component-groups.actions";
import * as fromComponentGroups from "../store/index";


@Injectable()
export class ComponentGroupsEffects {
    loadComponentGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadComponentsGroups),
            mergeMap(
                () => this.componentGroupsService.getComponentsGroups()
                    .pipe(
                        map((componentGroups: ComponentGroup[]) => {
                            return loadComponentGroupsSuccess({componentGroups});
                        }),
                        catchError(error => {
                            return of(loadComponentGroupsFailure(error));
                        })
                    )
            )
        )
    );

    createComponentGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createComponentGroup),
            mergeMap(
                ({name, componentGroupType}) => this.componentGroupsService.addComponentGroup(name, componentGroupType)
                    .pipe(
                        map((componentGroup: ComponentGroup) => {
                            return createComponentGroupSuccess({componentGroup});
                        }),
                        catchError(error => {
                            return of(createComponentGroupFailure(error));
                        })
                    )
            )
        )
    );

    constructor(private actions$: Actions,
                private store: Store<fromComponentGroups.ComponentGroupsState>,
                private componentGroupsService: ComponentGroupsService) {
    }
}

