import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '../../../../core/environment.service';
import { SpacesService } from '../../../components/spaces/spaces.service';
import { CreateAccountDto } from '../models/create-account.dto';
import { CreatePropertyDto } from '../models/create-property.dto';


@Injectable({
    providedIn: 'root'
})
export class AccountManagementApiService {
    constructor(private http: HttpClient,
                private spacesService: SpacesService,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }

    createAccount(data: CreateAccountDto) {
        const url = `${this.environment.apiV1BaseUrl}api/accounts/create`;
        return this.http.post(url, data);
    }

    createProperty(data: CreatePropertyDto) {
        const companyUuid = this.spacesService.getCompanyUuid();
        const url = `${this.environment.apiBaseUrl}/${companyUuid}/companies/${companyUuid}/properties`;
        return this.http.post(url, data);
    }
}
