import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { getCurrentSpaceFromPath, MixedSpace, SpaceBase } from './models/space';
import { SpaceType } from './models/space-type';
import { SpacesHistory } from './spaces-history.service';

@Injectable({
    providedIn: 'root'
})
export class SpacesService {

    history: SpacesHistory = new SpacesHistory();
    spaceFromPath;
    locationPath;
    current: SpaceBase | null;

    constructor(location: Location) {
        // todo: remove this hack when we get rid of angularjs changesTracker
        Object.defineProperty(this, "current", {
            get: () => {
                if (this.locationPath !== location.path()) {
                    this.spaceFromPath = getCurrentSpaceFromPath();
                    this.locationPath = location.path();
                }
                return this.spaceFromPath;
            }
        });
    }

    // get current(): SpaceBase | null {
    //     // cache space as its expensive to create all spaces every time
    //     if (this.locationPath !== this.location.path()) {
    //         this.spaceFromPath = getCurrentSpaceFromPath();
    //         this.locationPath = this.location.path();
    //     }
    //     return this.spaceFromPath;
    // }

    getSpaceTitle() {
        if (!this.current) {
            return null;
        }
        return this.current.title + (this.current.type === SpaceType.Collaborative ? ' Collabration' : '');
    }

    getCompanyUuid(): string | null {
        if (!this.current) {
            return null;
        }
        switch (this.current.type) {
            case SpaceType.Property:
                return null;
            case SpaceType.Collaborative:
            case SpaceType.Company:
                return this.current.id;
            case SpaceType.Mixed:
                return (this.current as MixedSpace).company.id;
        }
    }

    getPropertyUuid(): string | null {
        if (!this.current) {
            return null;
        }
        switch (this.current.type) {
            case SpaceType.Property:
                return this.current.id;
            case SpaceType.Company:
                return null;
            case SpaceType.Mixed:
                return (this.current as MixedSpace).property.id;
        }
    }

    go(space?: SpaceBase) {
        if (!space) {
            localStorage.removeItem('CurrentSpace');
        } else {
            localStorage.setItem('CurrentSpace', JSON.stringify(space));
        }
    }

    hasPropertyContext() {
        if (!this.current) return false;
        const type = this.current.type;
        return type === SpaceType.Property || type === SpaceType.Mixed;
    }

    hasCompanyContext() {
        if (!this.current) return false;
        const type = this.current.type;
        return type === SpaceType.Company || type === SpaceType.Mixed || type === SpaceType.Collaborative;
    }

    isPropertySpace() {
        if (!this.current) return false;
        const type = this.current.type;
        return type === SpaceType.Property;
    }


    isCompanySpace() {
        if (!this.current) return false;
        const type = this.current.type;
        return type === SpaceType.Company;
    }


    isMixedSpace() {
        if (!this.current) return false;
        const type = this.current.type;
        return type === SpaceType.Mixed;
    }

    isCollaborativeSpace() {
        if (!this.current) return false;
        const type = this.current.type;
        return type === SpaceType.Collaborative;
    }
}
