/**
 * Created by roman.kupin on 9/28/2017.
 */

import * as PropertyApiActions from "../../actions/propertyApi";
import {
    HomepageTouchpointsNames,
    PhotoAdvocacyTouchpointsNames,
    PoststayTouchpointsNames,
    PrestayTouchpointsNames,
    QuoteListTouchpointsNames
} from "../../components/v1-adapters/touchpoint-container/touchpoints";

function getPrestayTouchpointActiveFlag(touchpointType) {
    if (touchpointType === PrestayTouchpointsNames.Confirmation) {
        return "isActive";
    }
    if (touchpointType === PrestayTouchpointsNames.Followup) {
        return "isFollowupEmailActive";
    }
    if (touchpointType === PrestayTouchpointsNames.ConfirmationEmailImage) {
        return "isConfirmationEmailActive";
    }
    if (touchpointType === PrestayTouchpointsNames.Mobile) {
        return "isMobileActive";
    }
}

function getPoststayTouchpointActiveFlag(touchpointType) {
    if (touchpointType === PoststayTouchpointsNames.PoststayProcess) {
        return "isActive";
    }
    if (touchpointType === PoststayTouchpointsNames.InvitationEmail) {
        return "isEmailActive";
    }
    if (touchpointType === PoststayTouchpointsNames.ReminderEmails) {
        return "isReminderEmailActive";
    }
    if (touchpointType === PoststayTouchpointsNames.ReengagementEmails) {
        return "isReengagementEmailActive";
    }
}

function getPhotoAdvocacyTouchpointActiveFlag(touchpointType) {
    if (touchpointType === PhotoAdvocacyTouchpointsNames.PhotoExplorer) {
        return "isActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.FinalistsEmail) {
        return "isFinalistEmailActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.ReminderEmail) {
        return "isReminderEmailActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.ActivityEmail) {
        return "isActivityEmailActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.WinnerEmail) {
        return "isWinnerEmailActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.BannerForPrearrivalEmail) {
        return "isPrearrivalEmailImageActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.BannerForPoststayEmail) {
        return "isPoststayEmailImageActive";
    }
    if (touchpointType === PhotoAdvocacyTouchpointsNames.PhotoRiver) {
        return "isPhotoRiverActive";
    }
}

function getHomepageTouchpointActiveFlag(touchpointType) {
    if (touchpointType === HomepageTouchpointsNames.Homepage) {
        return "isActive";
    }
    if (touchpointType === HomepageTouchpointsNames.HomepageEmail) {
        return "isEmailActive";
    }
}

function getQuoteListTouchpointActiveFlag(touchpointType) {
    if (touchpointType === QuoteListTouchpointsNames.List) {
        return "isActive";
    }
}

export default function propertyGuestsJourneysReducer(state = {}, {type, payload}) {
    switch (type) {
        case PropertyApiActions.ADVOCACY_GUEST_JOURNEY_FETCH_SUCCESS: {
            return Object.assign({}, payload);
        }
        case PropertyApiActions.UPDATE_TOUCHPOINT_STATUS: {
            const {layer, touchpoint, touchpointType, isActive} = payload;
            const newState = <any>Object.assign({}, state);
            let touchpointActiveKey;

            if (layer === "prestay" && (touchpointActiveKey = getPrestayTouchpointActiveFlag(touchpointType), !!touchpointActiveKey)) {
                newState.prestayAdvocacy[touchpointActiveKey] = isActive;
                return newState;
            }
            if (layer === "poststay" && (touchpointActiveKey = getPoststayTouchpointActiveFlag(touchpointType), !!touchpointActiveKey)) {
                newState.poststayAdvocacy[touchpointActiveKey] = isActive;
                return newState;
            }
            if (layer === "photoadvocacy" && (touchpointActiveKey = getPhotoAdvocacyTouchpointActiveFlag(touchpointType), !!touchpointActiveKey)) {
                newState.photoAdvocacy[touchpointActiveKey] = isActive;
                return newState;
            }
            if (layer === "homepage" && (touchpointActiveKey = getHomepageTouchpointActiveFlag(touchpointType), !!touchpointActiveKey)) {
                newState.homepageAdvocacy[touchpointActiveKey] = isActive;
                return newState;
            }
            if (layer === "quotelist" && (touchpointActiveKey = getQuoteListTouchpointActiveFlag(touchpointType), !!touchpointActiveKey)) {
                newState.quoteListAdvocacy[touchpointActiveKey] = isActive;
                return newState;
            }
            if (layer === "warn-lead-nurturing") {
                newState.warmLeadNurturing.isCertificateReminderEnabled = isActive;
                return newState;
            }

        }
        default: {
            return state;
        }
    }
}