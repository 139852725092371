import { Component, forwardRef, Input, OnInit, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { BitFlags } from '@flipto/shared/src/lib/utils/common/bitflags';
import { filter, take } from 'rxjs/operators';
import { MODAL_EDITOR_TOKEN, ModalEditorBase } from '../../../classes/modal-editor-base';

export enum StayLengthEditorWeekDays {
    Sun = 0,
    Mon = 1,
    Tue = 2,
    Wed = 3,
    Thu = 4,
    Fri = 5,
    Sat = 6
}

@Component({
    selector: 'ft-stay-length-editor',
    templateUrl: './stay-length-editor.component.html',
    styleUrls: ['./stay-length-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => StayLengthEditorComponent)
        },
        {
            provide: MODAL_EDITOR_TOKEN,
            useExisting: StayLengthEditorComponent
        }
    ]
})
export class StayLengthEditorComponent extends ModalEditorBase implements OnInit {
    @Input() tooltip: TemplateRef<any>;
    customArriveDaysSelectOptions = [
        { id: 0, name: 'Any' },
        { id: 1, name: 'Restricted to specific days' }
    ];
    customDepartDaysSelectOptions = [
        ...this.customArriveDaysSelectOptions,
        { id: 2, name: 'A specific increment of nights' }
    ];
    selectedSpecificArriveDayOption: number = this.customArriveDaysSelectOptions[0].id;
    selectedSpecificDepartDayOption: number = this.customDepartDaysSelectOptions[0].id;
    weekDays = [...Object.values(StayLengthEditorWeekDays).filter(val => typeof val === 'string')];

    ngOnInit() {
        this.value$
            .pipe(
                filter(value => !!value),
                take(1),
            )
            .subscribe((value) => {
                this.updateInitialValue();
                this.setCustomDaysOptions();
            });
    }

    setCustomDaysOptions() {
        this.selectedSpecificArriveDayOption = this.value.arrivalDayRestriction ? 1 : 0;
        this.selectedSpecificDepartDayOption = this.value.departureDayRestriction ? 1 : this.value.stayLengthIncrementDays ? 2 : 0;
    }


    isArriveDayOptionChecked(index: number) {
        return BitFlags.getBit(this.value.arrivalDayRestriction, index);
    }

    isDepartDayOptionChecked(index: number) {
        return BitFlags.getBit(this.value.departureDayRestriction, index);
    }

    // @todo merge with discovery
    byteString(n: number): string {
        // Check upper bound, lower bound and valid integer
        if (n < 0 || n > 127 || (n && n % 1 !== 0)) {
            throw new Error(n + ' does not fit in a byte');
        }
        return (n?.toString(2) || '').padStart(7, "0");
    }

    onRestrictedDepartureDayChange(data: number) {
        const newValue = {
            ...this.value
        };

        if (data !== 1){ // Specific Days
            newValue.departureDayRestriction = 0;
        }

        if (data === 2){
            newValue.stayLengthIncrementDays = newValue.stayLengthIncrementDays || 1; // default
        } else {
            newValue.stayLengthIncrementDays = 0;
        }
        this.value = newValue;
    }
    onRestrictedArrivalDayChange(data: number) {
        const newValue = {
            ...this.value
        };

        if (data !== 1){ // Specific days
            newValue.arrivalDayRestriction = 0;
        }
        this.value = newValue;
    }

    onDayOptionChanged(checked, index, arrive = true) {
        const newValue = {
            ...this.value
        };
        if(arrive) {
            newValue.arrivalDayRestriction = BitFlags.updateBit(newValue.arrivalDayRestriction, index, checked ? 1 : 0);
        } else {
            newValue.departureDayRestriction = BitFlags.updateBit(newValue.departureDayRestriction, index, checked ? 1 : 0);
        }
        this.value = newValue;
    }

    isInvalid() {
        if (this.controlsModelRef?.length) {
            return this.controlsModelRef.some(control => control.invalid) ||
                this.checkMinNightsOverflow(this.value) ||
                this.checkDefaultStayLengthIsBetween(this.value) ||
                this.checkAtLeastOneDayArrivalRestriction(this.value) ||
                this.checkAtLeastOneDayDepartRestriction(this.value);
        }
    }

    validate(value): ValidationErrors {
        const errors = {
            minNightsOverflow: this.checkMinNightsOverflow(value),
            checkDefaultStayLengthIsBetween: this.checkDefaultStayLengthIsBetween(value),
            atLeastOneArrivalDay: this.checkAtLeastOneDayArrivalRestriction(value),
            atLeastOneDepartureDay: this.checkAtLeastOneDayDepartRestriction(value)
        };
        return Object.values(errors).some(value => value) ? errors : null;
    }

    checkMinNightsOverflow(value) {
        return this.isOver(value?.minNights, value?.maxNights);
    }

    checkDefaultStayLengthIsBetween(value) {
        return (value?.defaultStayLength < value?.minNights) || this.isOver(value?.defaultStayLength, value?.maxNights);
    }

    checkAtLeastOneDayArrivalRestriction(value) {
        return (this.selectedSpecificArriveDayOption === this.customArriveDaysSelectOptions[1].id) && value?.arrivalDayRestriction <= 0;
    }

    checkAtLeastOneDayDepartRestriction(value) {
        return (this.selectedSpecificDepartDayOption === this.customDepartDaysSelectOptions[1].id) && value?.departureDayRestriction <= 0;
    }

    onCancelChanges() {
        super.onCancelChanges();
        this.setCustomDaysOptions();
    }

    isOver(x: number, y: number) {
        return y > 0 && x > y;
    }
}
