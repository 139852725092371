import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval, Observable, of, share } from 'rxjs';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    private invalidatePeriod = 3600;
    private cache: Map<string, HttpResponse<any>> = new Map();

    constructor() {
        interval(this.invalidatePeriod * 1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => this.invalidateCache());
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== 'GET' || !req.headers.get('cached')) {
            return next.handle(req);
        }
        if (req.headers.get('invalidate')) {
            this.cache.delete(req.urlWithParams);
        }
        const cachedResponse: HttpResponse<any> = this.cache.get(req.urlWithParams);
        if (cachedResponse) {
            return of(cachedResponse.clone());
        } else {
            return next.handle(req.clone({
                headers: req.headers.delete('cached')
            })).pipe(
                tap(stateEvent => {
                    if (stateEvent instanceof HttpResponse) {
                        this.cache.set(req.urlWithParams, stateEvent.clone());
                    }
                }),
                share()
            );
        }
    }

    invalidateCache() {
        this.cache.clear();
    }
}
