import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from 'account-hybrid/core/environment.service';
import { Observable } from "rxjs";
import { AppNotification } from "../models/notification.model";

@Injectable()
export class NotificationsService {
    constructor(private http: HttpClient,
                @Inject(ENVIRONMENT_TOKEN) private environment: Environment) {
    }


    getNotifications(): Observable<AppNotification[]> {
        return this.http.get<AppNotification[]>(`${this.environment.apiV1BaseUrl}/api/notifications `);
    }

    updateNotificationByUuid(uuid: string, data: Partial<AppNotification>): Observable<AppNotification> {
        return this.http.patch<AppNotification>(`${this.environment.apiV1BaseUrl}/api/notifications/${uuid}`, data);
    }
}

