import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccountLanguagesService } from '../../services/account-languages.service';
import {
    createAccountLanguage,
    createAccountLanguageFailure,
    createAccountLanguageSuccess,
    loadAccountLanguages,
    loadAccountLanguagesFailure,
    loadAccountLanguagesSuccess,
    loadAvailableAccountLanguages,
    loadAvailableAccountLanguagesFailure,
    loadAvailableAccountLanguagesSuccess,
    removeAccountLanguage,
    removeAccountLanguageFailure,
    removeAccountLanguageSuccess,
    updateAccountLanguages,
    updateAccountLanguagesFailure,
    updateAccountLanguagesSuccess
} from './account-languages.actions';

@Injectable({
    providedIn: 'root'
})
export class AccountLanguagesEffects {
    loadAccountLanguages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAccountLanguages),
            switchMap(() =>
                this.accountLanguagesService.getAccountLanguages()
                    .pipe(
                        map(languages => loadAccountLanguagesSuccess({ languages })),
                        catchError(error => of(loadAccountLanguagesFailure({ error })))
                    )
            )
        )
    );

    loadAvailableAccountLanguages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadAvailableAccountLanguages),
            switchMap(() =>
                this.accountLanguagesService.getAvailableLanguages()
                    .pipe(
                        map(languages => loadAvailableAccountLanguagesSuccess({ languages })),
                        catchError(error => of(loadAvailableAccountLanguagesFailure({ error })))
                    )
            )
        )
    );

    createAccountLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createAccountLanguage),
            map(action => action.language),
            switchMap(language =>
                this.accountLanguagesService.createLanguage(language)
                    .pipe(
                        map(() => createAccountLanguageSuccess()),
                        catchError(error => of(createAccountLanguageFailure({ error })))
                    )
            )
        )
    );

    updateAccountLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAccountLanguages),
            map(action => action.languages),
            switchMap(languages =>
                this.accountLanguagesService.updateLanguages(languages)
                    .pipe(
                        map(() => updateAccountLanguagesSuccess()),
                        catchError(error => of(updateAccountLanguagesFailure({ error })))
                    )
            )
        )
    );

    removeAccountLanguage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeAccountLanguage),
            map(action => action.languageCode),
            switchMap(languageCode =>
                this.accountLanguagesService.removeLanguage(languageCode)
                    .pipe(
                        map(() => removeAccountLanguageSuccess()),
                        catchError(error => of(removeAccountLanguageFailure({ error })))
                    )
            )
        )
    );

    onAccountLanguagesCreate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createAccountLanguageSuccess, removeAccountLanguageSuccess),
            map(() => loadAccountLanguages())
        )
    );

    constructor(private actions$: Actions,
                private accountLanguagesService: AccountLanguagesService) {
    }
}
